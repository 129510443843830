import React from 'react';
import queryString from 'query-string';
import { makeStyles, Paper, Typography } from '@material-ui/core';
import BasePage from '../BasePage';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  chatHeader: {
    background: theme.palette.primary.main,
    padding: '8px',
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
    '& > p': {
      color: '#fff'
    }
  },
  chatFrame: {
    border: 'none',
    width: '100%',
    height: '640px',
    [theme.breakpoints.down('xs')]: {
      height: '400px'
    }
  }
}));

const LiveChatSupport = () => {
  const classes = useStyles();
  const history = useHistory();
  const params = queryString.parse(history.location.search);
  const chatType = history.location?.state?.chatType;
  const phone = history.location?.state?.phone;
  const memberId = history.location?.state?.memberID;

  React.useEffect(() => {
    return () => {
           document.cookie = '_genesys.widgets.webchat.state.purecloud-v2-sockets.ConversationID	=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    };
  }, []);

  const customHtml = `<html>
  <script src="https://apps.mypurecloud.com/widgets/9.0/cxbus.min.js" onload="javascript:CXBus.configure({debug:false,pluginsPath:'https://apps.mypurecloud.com/widgets/9.0/plugins/'}); CXBus.loadPlugin('widgets-core');"></script>
  <style>
  
  .cx-widget.cx-theme-light .cx-titlebar .cx-title {
    color: #ffffff !important;
  }
  
  .cx-common-container .cx-titlebar{
    background-color: #1E69D2 !important;
  }
  
  .cx-widget.cx-theme-light .cx-titlebar .cx-svg-icon-tone1{
    fill: #ffffff !important;
  }
  .cx-widget.cx-theme-light .cx-buttons-window-control .cx-svg-icon-tone1{
    fill: #ffffff !important;
  }
  .cx-widget.cx-theme-light .cx-send .cx-svg-icon-tone1 {
    fill: #1E69D2;
  }
  .customChatButton{
    border: 1px solid rgba(30, 105, 210, 0.5);
    align-items: center;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    -moz-appearance: none;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    color: #1E69D2;
    padding: 5px 15px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    text-transform: uppercase;
    cursor: pointer;
  }
  
  </style>
  <script>
    window._genesys = {
      "widgets": {
        "main" : {
          "theme":"light"
        },
        "webchat": {
          "transport": {
            "type": "purecloud-v2-sockets",
            "dataURL": "https://api.mypurecloud.com",
            "deploymentKey": "2fec35a0-b230-438d-95a0-afcde70e0b6e",
            "orgGuid": "6c2ed0e0-499f-4f35-87d8-9102263447c0",
            "interactionData": {
              "routing": {
                "targetType": "QUEUE",
                "targetAddress": "",
                "priority": ""
              }
            }
          },
          "userData": {}
        }
      }
    };
  
    function getAdvancedConfig() {
      return {
        "form": {
          "autoSubmit": true,
          "firstname": "${history.location?.state?.firstName}",
          "lastname": "${history.location?.state?.lastName}",
          "email": "${history.location?.state?.email}",
          "phone": "",
          "memberid":"",
          "chatType": "${history.location?.state?.chatType}"
        },
        "formJSON": {
          "wrapper": "<table></table>",
          "inputs": [
            {
              "id": "cx_webchat_form_firstname",
              "name": "firstname",
              "maxlength": "100",
              "placeholder": "Required",
              "label": "First Name"
            },
            {
              "id": "cx_webchat_form_lastname",
              "name": "lastname",
              "maxlength": "100",
              "placeholder": "Required",
              "label": "Last Name"
            },
            {
              "id": "cx_webchat_form_email",
              "name": "email",
              "maxlength": "100",
              "placeholder": "Optional",
              "label": "Email"
            },
        {
              "id": "cx_webchat_form_phone",
              "name": "phone",
              "maxlength": "100",
              "placeholder": "Optional",
              "label": "Callback Number",
              "value":"${phone}"
            },
            {
              "id": "cx_webchat_form_memberid",
              "name": "memberid",
              "maxlength": "100",
              "placeholder": "Optional",
              "label": "Member ID",
              "value":"${memberId}"
            },
        {
              id: "cx_webchat_form_chattype",
              name: "chattype",
              placeholder: "Required",
              label: "How Can We Help?",
              type: "select",
              options: [
                  {
                      text: "Find Care",
                      value: "helpfindingadoctor",
                      selected: "${chatType}" === "helpfindingadoctor"
                  },
                  {
                      text: "Medical Benefits",
                      value: "planoptionsandbenefits",
                      selected: "${chatType}" === "planoptionsandbenefits"
                  },
                  {
                      text: "Claims/Payments",
                      value: "claims",
                      selected: "${chatType}" === "claims"
                  },
                  {
                      text: "Technical Support",
                      value: "websitequestions",
                      selected: "${chatType}" === "websitequestions"
                  },
                  {
                      text: "Scheduling",
                      value: "scheduling",
                      selected: "${chatType}" === "scheduling"
                  },
                  {
                      text: "Other",
                      value: "other",
                      selected: "${chatType}" === "other"
                  }
              ],
              wrapper: "<tr><th>{label}</th><td>{input}</td></tr>"
        }
          ]
        }
      };
    }
    const customPlugin = CXBus.registerPlugin('Custom');
    customPlugin.subscribe('WebChat.closed', function(e){ 
        // localStorage.removeItem('_genesys.widgets.inFocus');
       window.history.back();
      });

      const loadChat = ()=>{
        customPlugin.command('WebChat.open', getAdvancedConfig());
      }

  </script>
  

  <body onload="loadChat()">

  </body>
 

</html>`;

const CustomIframe = () => {
  return  (<iframe
  id="chatFrame"
  title="Chat"
  className={classes.chatFrame}
  srcDoc={customHtml}
></iframe>);
};

  return (
    <BasePage>
      <Typography variant="h1">Live Chat Support</Typography>
      <Paper style={{ margin: '16px 0px' }}>
        <div className={classes.chatHeader}>
          <Typography>
            Live Chat Support {'> '} {params.topic}
          </Typography>
        </div>
        <CustomIframe />
      </Paper>
    </BasePage>
  );
};

export default LiveChatSupport;
