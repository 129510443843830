import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import BasePage from '../BasePage';
import DataTable from '../common/DataTable/DataTable';
import { HEALTH_TILES, SAVINGS_TABLE_DATA, SAVINGS_TABLE_HEADERS } from './constant';
import TruHearingBanner from './TruHearingBanner';
import TruHearingFooter from './TruHearingFooter';

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(6, 18),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 3)
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4, 2)
    }
  },
  title: {
    fontSize: '34px',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '29px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
    }
  },
  subContent: {
    margin: theme.spacing(5, 0),
    '& ul': {
      margin: 0,
      paddingInlineStart: '14px',
      '& li': {
        margin: theme.spacing(3, 0),
        color: 'rgba(0,0,0,0.6);',
      }
    }
  },
  tile: {
    paddingRight: theme.spacing(3),
  },
  tileTitle: {
    fontSize: '24px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    }
  },
  subTitle: {
    fontSize: '24px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    }
  },
  helperText: {
    color: 'rgba(0,0,0,0.6);'
  }
}));

const TruHearing = () => {
  const classes = useStyles();

  return (
    <BasePage fullWidth>
      <TruHearingBanner />
      <Grid className={classes.content} container>
        <Grid item xs={12} lg={8}>
          <Typography className={classes.title}>Good Hearing is Important to Your Health </Typography>
          <Typography>
            That’s why you have access to TruHearing®, a comprehensive hearing care solution. Hearing aids can be expensive—an average of $2,400 per aid— but the TruHearing program saves you 30-60% off hearing aids. Details of the program include:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.subContent}>
            {Object.keys(HEALTH_TILES).map((tileKey) => {
              const sectionList = HEALTH_TILES[tileKey];
              return (
                <Grid key={tileKey} item xs={12} sm={4} className={classes.tile}>
                  <Typography className={classes.tileTitle}>{tileKey}</Typography>
                  <ul>
                    {sectionList.map((section) => (
                      <li key={section}>{section}</li>
                    ))}
                  </ul>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.subTitle}>Example Savings (per aid):</Typography>
          <Typography variant="subtitle1">
            Prices and products subject to change. For more information, visit TruHearing.com.
          </Typography>
          <DataTable
            headers={SAVINGS_TABLE_HEADERS}
            values={SAVINGS_TABLE_DATA}
          />
          <Typography className={classes.helperText}>
            To learn more or set up an appointment with a provider near you, contact a TruHearing hearing consultant at 855-695-7577.
          </Typography>
        </Grid>
      </Grid>
      <TruHearingFooter />
    </BasePage>
  );
};

export default TruHearing;
