import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { setupInterceptors } from '../../../config/axios-config';

const AxiosInterceptors = () => {
  const history = useHistory();

  useEffect(() => {
    setupInterceptors(history);
  }, [history]);

  return null;
};

export default AxiosInterceptors;
