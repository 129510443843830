import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  Paper,
  Typography
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ClaimsMessage from '../../../ClaimsPage/cards/ClaimsMessage';
import ClaimDetails from '../../../ClaimsPage/cards/ClaimDetails';
import routes from '../../../../constants/routes';
import { analyticsTrackClickEvent } from '../../../../utils/analytics/analytics-utils';

const useStyles = makeStyles(theme => ({
  customList: {
    width: '100%',
    position: 'relative',
    overflow: 'auto'
  },
  claimsHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px'
  },
  claimsBoxBottomText: {
    [theme.breakpoints.down('md')]: {
      fontSize: '14px'
    }
  }
}));

const RecentClaims = ({ claimsData }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Paper className={classes.customPaper}>
      <Box className={classes.claimsHeading}>
        <Typography variant="h5" style={{ fontWeight: 600 }}>
          Recent Claims
        </Typography>
        <IconButton size="small" onClick={() => {
          analyticsTrackClickEvent('home_claims_carrot_clicked', {eventCategory: 'Home Page Pathing'});
          history.push(routes.CLAIMS);
        }}>
          <NavigateNextIcon color="secondary" />
        </IconButton>
      </Box>
      <List className={classes.customList}>
        {claimsData && claimsData.length === 0 ? (
          <Grid
            item
            xs={12}
            style={{ marginTop: '24px', marginBottom: '100px' }}
          >
            <ClaimsMessage
              title="No claims yet"
              message="When you have medical claims, this is where you can find them and
              your explanation of benefits."
            />
          </Grid>
        ) : (
          claimsData.map((claim, index) => (
            (index < 3) && (<div key={claim.claimNumber}>
           <ClaimDetails claim={claim} />
           {index !== claimsData.length - 1 && <Divider variant="inset" />}
         </div>)
          ))
        )}
      </List>
    </Paper>
  );
};

RecentClaims.propTypes = {
  claimsData: PropTypes.array
};

export default RecentClaims;
