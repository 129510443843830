import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { Typography, Grid, useTheme, useMediaQuery, Box } from '@material-ui/core';
// import BasePage from '../../BasePage';
import PaymentNav from '../PaymentNav/PaymentNav';
import Premium from '../Premium/Premium';
import ManageAutoPay from '../Settings/ManageAutopay/ManageAutopay';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(() => ({
  pageWrapper: {
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    padding: '25px 60px 90px'
  },
  pageWrapperMobile: {
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    padding: '25px 25px 90px'
  },
  pageContainer: {
    maxWidth: 1200
  },
  pageTitle: {
    marginBottom: '36px', fontSize: '45px', fontFamily: 'Chronicle Text G1'
  },
  saveHelperText: {
    color: 'rgba(0, 0, 0, 0.56)',
    fontStyle: 'italic',
    margin: '20px 20px 40px 20px'
  },
}));



const PaymentLanding = () => {
  const history = useHistory();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyles();

  return (


    <>

      {/* <BasePage>
        <Typography variant="h1">Payments</Typography>
        <Grid container>
          <Grid item md={3} sm={12} xs={12}>
            <PaymentNav history={history} isDesktop={isDesktop} />
          </Grid>
          <Grid item md={9} sm={12} xs={12}>
            <Route exact path="/payment" component={Premium} />
            <Route exact path="/payment/settings" component={ManageAutoPay} />
          </Grid>
        </Grid>
      </BasePage> */}


      <Box className={isDesktop ? classes.pageWrapper : classes.pageWrapperMobile}>

        <Box className={classes.pageContainer}>
          <Grid container>
            <Grid item md={3} sm={12} xs={12}>
              <Box style={{marginRight: 38}}>
              <Typography className={classes.pageTitle} variant="h2">Payments</Typography>
              <PaymentNav history={history} isDesktop={isDesktop} />

              </Box>
            </Grid>
            <Grid item md={9} sm={12} xs={12}>
              <Box mt={10}>
              <Route exact path="/payment" component={Premium} />
              <Route exact path="/payment/settings" component={ManageAutoPay} />
              </Box>
              
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default PaymentLanding;
