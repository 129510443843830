import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box, Button } from '@material-ui/core';
import { Switch } from '@material-ui/core';
import { fetchAutopayConfiguration, updateAutopaySetupOff } from '../../../redux/autopay/actions';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AutopayAgreementPop from '../Settings/AutopayAgreementPopup';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { memberApi } from '../../../config/axios-config';
import genericServiceReducer from '../../../redux/common/genericServiceReducer';
import { fetchAccountData } from '../../../redux/settings/actions';
import { analyticsTrackClickEvent, analyticsTrackEvent } from '../../../utils/analytics/analytics-utils';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  autoPaySection: {
    width: '332px',
    backgroundColor: 'white',
    borderRadius: 12,
    paddingTop: 16,
    paddingLeft: 16,
    paddingBottom: 25
  },
  autoPaySectionOnOff: {
    width: 332,
    height: 216,
    backgroundColor: 'white',
    borderRadius: 12,
    marginBottom: 24
  },
  autoPaySectionOnOffLoading: {
    width: 332,
    height: 141,
    backgroundColor: '#ebebeb',
    borderRadius: 12,
    marginBottom: 24
  },
  topSection: {
    height: 70,
    borderBottom: '1px solid #E6E6E6',
    display: 'flex',
    flexDirection: 'row',
  },
  bottomSection: {
    height: 70,
    display: 'flex',
    flexDirection: 'row'
  },
  noteAutopay: {
    color: '#000000',
    opacity: 0.5,
    margin: 0,
    marginTop: 5
  },
  buttonSetupAutopay: {
    width: '300px',
    marginTop: 26
  },
  numberOfDays: {
    fontWeight: 700,
    fontSize: 36,
    margin: 0,
    marginTop: 20
  },
  atag: {
    color: '#1E69D2',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '24px',
    paddingLeft: '106px', 
    cursor: 'pointer'
  },
}));

const AutoPaySwitch = withStyles({
  root: {
    width: 83,
    height: 44,
    padding: 8,
    '& > span': {
      color: '#424242',
      '&.Mui-checked': {
        transform: 'translateX(36px)',
        color: '#1976D2'
      },
      '&.Mui-checked + span': {
        opacity: '1 !important',
        backgroundColor: 'rgba(30, 105, 210, 0.38) !important'
      }
    },
    '& > span:last-child': {
      borderRadius: 20,
      fontFamily: 'Hco Whitney SSm, arial',
      fontSize: 14,
      fontWeight: 600,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '48%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16
      },
      '&:before': {
        left: 17,
        content: '"On"',
        color: 'rgba(0, 46, 111, 1)'
      },
      '&:after': {
        content: '"Off"',
        right: 24,
        color: '#FFFFFF'
      },
    },
    '& span > span > span': {
      boxShadow: 'none',
      width: 36,
      height: 36,
      margin: -5,
      padding: 0
    },
   
  }
})(Switch);


const SetupAutopay = ({ bill }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [checkCardsAdded, setCheckCardsAdded] = React.useState(false);


  React.useEffect(() => {
    dispatch(fetchAutopayConfiguration());
  }, []);

  React.useEffect(() => {
    dispatch(fetchAccountData());
  }, []);



  const [paymentSourceReducerState, paymentSourceReducerDispatch] = React.useReducer(genericServiceReducer, {
    status: 'idle',
    data: null,
    error: null
  });


  const paymentSourceIsLoading = paymentSourceReducerState?.status === 'pending';

  React.useEffect(() => {
    paymentSourceReducerDispatch({ type: 'started' });
    memberApi
      .get('/v2/my/payments/methods?type=CARD')
      .then(res => {
        paymentSourceReducerDispatch({ type: 'success' });
        setCheckCardsAdded(res?.data?.paymentMethods?.length > 0);
      })
      .catch(err => paymentSourceReducerDispatch({ type: 'error', error: err }));
  }, []);

  const isAutoPay = useSelector(state => state?.autopay?.autopayConfig?.isAutoPay);
  const isLoading = useSelector(state => state?.autopay?.loading);

  return (
    <Grid container>
      <Grid item md={6} sm={12}>
        <Box style={{ paddingLeft: 12 }}>
          <p style={{ fontSize: 28, margin: 0, marginTop: 15 }}>Your premium is due on</p>
          <p className={classes.numberOfDays}>{bill?.currentDueDate ? moment(bill.currentDueDate).format('MM/DD/YYYY') : '-'}</p>
        </Box>
      </Grid>
      <Grid item md={6} sm={12}>
        <Box style={{ display: 'flex', justifyContent: 'right' }}>
          {(isLoading == true) || paymentSourceIsLoading ? (<Box className={classes.autoPaySectionOnOffLoading}></Box>) : ((isAutoPay == null && !checkCardsAdded) ? <SetupAutoPay /> :
            <SetupAutoPayWithOnAndOff isAutoPayEnabled={isAutoPay} bill={bill} />)}
        </Box>
      </Grid>
    </Grid>
  );
};

const SetupAutoPay = () => {
  const classes = useStyles();
  const [openAgreementPopup, setOpenAgreementPopup] = React.useState(false);
  const history = useHistory();
//  to-do fix outstandingamount inhibitor to more updated field when ready
//  const { billingSummary } = useSelector(state => state.payments);
//  const hasPriorOutstandingAmount = billingSummary?.priorOutstandingAmount > 0;
  const subSuffixCheck = useSelector(state => state?.settings?.accountData?.subscriberSuffix) == 0;
  const autopayError = useSelector(state=>state?.autopay?.error?.response?.status);
  const [autopayErrorMessage, setAutopayErrorMessage] = React.useState('');

  React.useEffect(() => {
    if (autopayError >= 500) {
      setAutopayErrorMessage('An error has occurred, please wait a few minutes and refresh the page to try again.');
    } else {
      setAutopayErrorMessage('');
    }
}, [autopayError]);

  const handleCloseAgreementPopup = () => {
    setOpenAgreementPopup(false);
  };
  const handleAcceptTermsAndCondition = () => {
    const autopaySetupNavObj = {
      pathname: '/autopay_setup',
      state: { isCustomerAcceptsAutopayTermsAndConditions: true}
    };
    history.push(autopaySetupNavObj);
  };
  return subSuffixCheck ? (
    <Grid>
      <Box style={{ marginBottom: 24 }}>
        <Box className={classes.autoPaySection}>
          <p style={{ margin: 0, fontWeight: 700 }}>Set up automatic payments</p>
          <p className={classes.noteAutopay}>
            Make sure your premium is paid on time.
            </p>
          {autopayError >= 500 ?             
            <Alert style={{ fontSize: 12, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '300px', marginTop: '26px'}} severity='error'>{autopayErrorMessage}</Alert> : 
            <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.buttonSetupAutopay}
            onClick={() => {
              analyticsTrackClickEvent('payments_setupautopay_clicked', {eventCategory: 'Payments Pathing'});
              setOpenAgreementPopup(true);
            }}
//            disabled={hasPriorOutstandingAmount}
          >
            SET UP AUTOPAY
        </Button>
          }
        {/* {hasPriorOutstandingAmount ? <Box className={classes.bottomSection}>
          <Box style={{ paddingRight: 10 }}>
            <p style={{ paddingTop: 0, marginBottom: 0, paddingBottom: 0, fontSize: 14, textAlign: 'center' }}> You must resolve your existing balance before turning on Autopay.</p>
          </Box>
        </Box> : <Box></Box>} */}
          <AutopayAgreementPop handleClose={handleCloseAgreementPopup} open={openAgreementPopup} handleAutopayChange={handleAcceptTermsAndCondition} />
        </Box>
      </Box>
    </Grid>
  ) : (<Grid></Grid>);
};

const SetupAutoPayWithOnAndOff = ({ isAutoPayEnabled, bill }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [checked, setChecked] = isAutoPayEnabled ? React.useState(isAutoPayEnabled) : React.useState(false);
  const history = useHistory();
  const isAutoPay = useSelector(state => state?.autopay?.autopayConfig?.isAutoPay);
//  const [outstandingAmountAllowSwitch, setOutstandingAmountAllowSwitch] = React.useState(false);
//  const isOutstandingAmount = bill?.priorOutstandingAmount > 0;
  const [openAgreementPopup, setOpenAgreementPopup] = React.useState(false);
  const [currentDateFormatted, setCurrentDateFormatted] = React.useState('');
  const currentDate = new Date();
  const dayAutopayment = useSelector(state => state?.autopay?.autopayConfig?.date);
  const subSuffixCheck = useSelector(state => state?.settings?.accountData?.subscriberSuffix) == 0;
  const [autopayDueAmount, setAutopayDueAmount] = React.useState('');
  const autopayError = useSelector(state=>state?.autopay?.error?.response?.status);
  const [autopayErrorMessage, setAutopayErrorMessage] = React.useState('');

  React.useEffect(() => {
    if (autopayError >= 500) {
      setAutopayErrorMessage('An error has occurred, please wait a few minutes and refresh the page to try again.');
    } else {
      setAutopayErrorMessage('');
    }
}, [autopayError]);

  const [paymentAmountReducerState, paymentAmountReducerDispatch] = React.useReducer(genericServiceReducer, {
    status: 'idle',
    data: null,
    error: null
});

const paymentAmountIsLoading = paymentAmountReducerState?.status === 'pending';
const paymentAmountError = paymentAmountReducerState?.error;

React.useEffect(() => {
    paymentAmountReducerDispatch({ type: 'started' });
    memberApi
        .get('/v2/my/billing/summary-invoices')
        .then(res => {
            paymentAmountReducerDispatch({ type: 'success' });
            setAutopayDueAmount(parseFloat(res?.data?.billingSummary.netDueAmount).toFixed(2));
        })
        .catch(err => {
          paymentAmountReducerDispatch({ type: 'error', error: err });
        });
}, []);



  // React.useEffect(() => {
  //   switch (isAutoPayEnabled) {
  //     case true:
  //       setOutstandingAmountAllowSwitch(true);
  //       break;
  //     case false || undefined:
  //       if (isOutstandingAmount == true) {
  //         setOutstandingAmountAllowSwitch(false);
  //       } else {
  //         setOutstandingAmountAllowSwitch(true);
  //       }
  //       break;
  //   }
  // }, [isOutstandingAmount, outstandingAmountAllowSwitch, isAutoPay]);


  React.useEffect(() => {

    switch (dayAutopayment == null || dayAutopayment == undefined) {
      case true:
        setCurrentDateFormatted('-/-/-');
        break;
      case false:
        if (currentDate.getDate() <= dayAutopayment) {
          currentDate.setDate(dayAutopayment);
          currentDate.setMonth(currentDate.getMonth());
          setCurrentDateFormatted(currentDate.toLocaleDateString());
        } else {
          currentDate.setDate(dayAutopayment);
          currentDate.setMonth(currentDate.getMonth() + 1);
          setCurrentDateFormatted(currentDate.toLocaleDateString());
        }
        break;
    }
  }, []);

  const handleAutopaySwitch = (event) => {
    analyticsTrackEvent('payments_setupautopay_clicked', {eventCategory: 'Payments Pathing'});
    setChecked(event.target.checked);
    if (event.target.checked) {
      setOpenAgreementPopup(true);
    } else {
      dispatch(updateAutopaySetupOff());
    }
  };

  const handleCloseAgreementPopup = () => {
    setChecked(false);
    setOpenAgreementPopup(false);
  };

  const handleAcceptTermsAndCondition = () => {
    const autopaySetupNavObj = {
      pathname: '/autopay_setup',
      state: { isCustomerAcceptsAutopayTermsAndConditions: true}
    };
    history.push(autopaySetupNavObj);
  };

  return subSuffixCheck ? (
    <Grid >
      <Box className={classes.autoPaySectionOnOff}>
        {autopayError >= 500 ? 
            <Alert style={{ fontSize: 13, borderBottomLeftRadius: 0, borderTopRightRadius: 12, borderTopLeftRadius: 12, borderBottomRightRadius: 0, height: 70, display: 'flex', justifyContent: 'center', alignItems: 'center'}} severity='error'>{autopayErrorMessage}</Alert> : 
        <Box className={classes.topSection}>
          <Box style={{ paddingLeft: 20, paddingTop: 16 }}>
            <p style={{ fontSize: 16, fontWeight: 600, margin: 0, display: 'inline' }}> Autopay</p>
            <AutoPaySwitch color='primary' onChange={handleAutopaySwitch} checked={checked} />
          </Box>
          <Box style={{ paddingTop: 16, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <a onClick={() => history.push('/payment/settings')} href="" className={classes.atag}>Edit</a>
          </Box>
        </Box>}
        <Box className={classes.topSection}>
          <Box style={{ paddingLeft: 20 }}>
            <p style={{ paddingTop: 0, marginBottom: 0, paddingBottom: 0, fontSize: 14 }}>Autopay amount</p>
            {bill?.currentMonthPremiumAmount && <p style={{ fontWeight: 700, marginTop: 0, fontSize: 16 }}>{isAutoPay & !paymentAmountIsLoading & !paymentAmountError ? `$${autopayDueAmount}` : '-'}</p>}
          </Box>
          <Box style={{ paddingLeft: 60 }}>
            <p style={{ paddingTop: 0, marginBottom: 0, paddingBottom: 0, fontSize: 14 }}>Autopay date</p>
            {bill?.currentDueDate && <p style={{ fontWeight: 700, marginTop: 0, fontSize: 16 }}>{isAutoPay ? `${currentDateFormatted ? currentDateFormatted : '-'}` : '-'}</p>}
          </Box>
        </Box>

        {/* {isOutstandingAmount ? <Box className={classes.bottomSection}>
          <Box style={{ paddingLeft: 10, paddingRight: 10 }}>
            <p style={{ paddingTop: 0, marginBottom: 0, paddingBottom: 0, fontSize: 14, textAlign: 'center' }}> You must resolve your existing balance before turning on Autopay.</p>
          </Box>
        </Box> : <Box></Box>} */}
        <AutopayAgreementPop handleClose={handleCloseAgreementPopup} open={openAgreementPopup} handleAutopayChange={handleAcceptTermsAndCondition} />
      </Box>
    </Grid>
  ) : (<Grid></Grid>);
};

SetupAutoPayWithOnAndOff.propTypes = {
  isAutoPayEnabled: PropTypes.bool,
  bill: PropTypes.object.isRequired
};
SetupAutopay.propTypes = {
  bill: PropTypes.object.isRequired
};


export default SetupAutopay;
