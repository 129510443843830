import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import BasePage from '../../BasePage';
import { Typography, Grid, CircularProgress, Button } from '@material-ui/core/';
import { AlertTitle } from '@material-ui/lab';
import { AlertBox } from '@ascension/ui-library';
import DownloadIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import PropTypes from 'prop-types';
import {
  fetchMedicalCard,
  fetchPharmacyCard
} from '../../../redux/idCards/actions';

const useStyles = makeStyles(() => ({
  cardSecion: {
    marginBottom: '1rem'
  },
  img: {
    maxWidth: '100%'
  },
  alertBox: {
    width: '100%'
  }
}));

const IdCardPage = () => {
  const isPharmCardEnabled = false;
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    medicalCard,
    medicalCardError,
    medicalCardLoading,
    pharmacyCard,
    pharmacyCardError,
    pharmacyCardLoading
  } = useSelector(store => store.idCards);

  useEffect(() => {
    dispatch(fetchMedicalCard());
    isPharmCardEnabled && dispatch(fetchPharmacyCard());
  }, [dispatch]);

  const [hasRetriedMedical, setHasRetriedMedical] = useState(false);
  const [hasRetriedPharmacy, setHasRetriedPharmacy] = useState(false);

  const ErrorAlert = ({ onRetry, hasRetried }) => {
    return !hasRetried ? (
      <AlertBox
        className={classes.alertBox}
        leftBorderAccent
        severity="error"
        variant="outlined"
        action={
          <Button
            variant="outlined"
            color="primary"
            startIcon={<RefreshIcon />}
            onClick={onRetry}
          >
            Retry
          </Button>
        }
      >
        An error occurred. It&apos;s not you, it&apos;s us. Do you mind trying
        again, please?
      </AlertBox>
    ) : (
      <AlertBox
        className={classes.alertBox}
        leftBorderAccent
        severity="error"
        variant="outlined"
      >
        <AlertTitle>
          We&apos;re so sorry for the inconvenience. We&apos;re still having
          trouble getting these. Please try again later
        </AlertTitle>
        If you need immediate assistance please call us at:{' '}
        <a href="tel:8336001311">833-600-1311</a>
      </AlertBox>
    );
  };

  ErrorAlert.propTypes = {
    onRetry: PropTypes.func.isRequired,
    hasRetried: PropTypes.bool.isRequired
  };

  return (
    <>
      <BasePage>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h1">My ID Cards</Typography>
          </Grid>
          {/* Medical card */}
          <Grid
            className={classes.cardSecion}
            container
            spacing={2}
            item
            xs={12}
          >
            <Grid item xs={12}>
              <Typography variant="h4" component="h2">
                Medical ID Card
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Present this card to your medical care provider when receiving
                care for any member covered by your medical plan.
              </Typography>
            </Grid>
            {medicalCardLoading ? (
              <CircularProgress />
            ) : medicalCardError ? (
              <ErrorAlert
                onRetry={() => {
                  dispatch(fetchMedicalCard());
                  setHasRetriedMedical(true);
                }}
                hasRetried={hasRetriedMedical}
              />
            ) : (
              <>
                <Grid item xs={12} md={8}>
                  <img
                    className={classes.img}
                    src={`data:image/png;base64,${medicalCard}`}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    size="large"
                    variant="outlined"
                    color="primary"
                    href={`data:image/png;base64,${medicalCard}`}
                    download="Ascension-Personalized-Care-Medical-ID-Card.png"
                    startIcon={<DownloadIcon />}
                  >
                    Download Card
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
          {/* Pharmacy card */}

          {isPharmCardEnabled && (
            <Grid
              className={classes.cardSecion}
              container
              spacing={2}
              item
              xs={12}
            >
              <Grid item xs={12}>
                <Typography variant="h4" component="h2">
                  Pharmacy ID Card
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Present this card to your pharmacist when filling a
                  prescription for any member covered by your pharmacy plan.
                </Typography>
              </Grid>
              {pharmacyCardLoading ? (
                <CircularProgress />
              ) : pharmacyCardError ? (
                <ErrorAlert
                  onRetry={() => {
                    dispatch(fetchPharmacyCard());
                    setHasRetriedPharmacy(true);
                  }}
                  hasRetried={hasRetriedPharmacy}
                />
              ) : (
                <>
                  <Grid item xs={12} md={8}>
                    <img
                      className={classes.img}
                      src={`data:image/png;base64,${pharmacyCard}`}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Button
                      size="large"
                      variant="outlined"
                      color="primary"
                      href={`data:image/png;base64,${pharmacyCard}`}
                      download="Ascension-Personalized-Care-Pharmacy-ID-Card.png"
                      startIcon={<DownloadIcon />}
                    >
                      Download Card
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </Grid>
      </BasePage>
    </>
  );
};

export default IdCardPage;
