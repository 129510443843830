import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import usePlanTypeCheck from '../../../hooks/usePlanTypeCheck';
import routes from '../../../constants/routes';

const PlanTypeCheck = ({ requireVerification, isVerified, path, children }) => {
  const history = useHistory();
  const isPlanAllowed = usePlanTypeCheck();

  useEffect(() => {
    if (isVerified && path === routes.UNVERIFIED_ACCOUNT && isPlanAllowed) {
      history.push(routes.DASHBOARD);
    } else if (requireVerification && !isPlanAllowed) {
      history.push(routes.UNVERIFIED_ACCOUNT);
    }
  }, [path, isPlanAllowed, isVerified, requireVerification]);

  return <>{children}</>;
};

PlanTypeCheck.propTypes = {
  requireVerification: PropTypes.bool.isRequired,
  isVerified: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.element])
};

export default PlanTypeCheck;
