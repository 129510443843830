import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Cancel';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 24
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.6)',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontSize: 20, fontWeight: '600' }}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(() => ({
  root: {
    padding: 24,
  },
}))(MuiDialogContent);

const DialogActions = withStyles(() => ({
  root: {
    margin: 0,
    padding: 24,
  },
}))(MuiDialogActions);

const DeletePaymentSourceErrorModal = ({ open, handleClose }) => {
  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="Delete-payment-source-error-title" open={open} maxWidth='xs' fullWidth={false} >
        <DialogTitle id="Delete-payment-source-error-title" onClose={handleClose}>
          Delete payment source error
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            You cannot delete your <span style={{ fontWeight: 600 }}>default payment method.</span>
          </Typography>
          <br />
          <Typography gutterBottom>
            Please select a new default payment method in order to delete this payment method.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary" variant='contained'>
            Go Back
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DeletePaymentSourceErrorModal.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool
};

export default DeletePaymentSourceErrorModal;