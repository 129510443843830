import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPlanStatus } from '../redux/planDetails/actions';

const usePlanTypeCheck = () => {
  const dispatch = useDispatch();

  const {
    plan: { planType }
  } = useSelector(state => state.planDetails);

  useEffect(() => {
    if (!planType) {
      dispatch(fetchPlanStatus());
    }
  }, []);

  const isPlanAllowed = planType === 'ACA' || !planType;
  return isPlanAllowed;
};

export default usePlanTypeCheck;
