import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  showLink: {
    padding: 0,
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline'
    },
    '&:focus-visible': {
      outline: '-webkit-focus-ring-color auto 1px'
    }
  }
}));

const TrunkText = ({ ariaTitle, text, charLimit }) => {
  const classes = useStyles();
  const [showAll, setShowAll] = useState(false);

  const truncated = `${text.substring(0, charLimit - 20)}...`;
  const shouldTruncate = text.length > charLimit;
  const label = showAll ? 'Show Less' : 'Show More';

  return shouldTruncate ? (
    <span>
      {showAll ? text : truncated}{' '}
      <Button
        className={classes.showLink}
        aria-label={`${label} ${ariaTitle}`}
        onClick={() => setShowAll(!showAll)}
        disableRipple
      >
        {label}
      </Button>
    </span>
  ) : (
    text
  );
};

TrunkText.propTypes = {
  ariaTitle: PropTypes.string,
  text: PropTypes.string.isRequired,
  charLimit: PropTypes.number
};

TrunkText.defaultProps = {
  charLimit: 200
};

export default TrunkText;
