import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Link, withRouter } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Toolbar,
  Typography,
  Button,
  IconButton,
  useMediaQuery
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import HearingIcon from '@material-ui/icons/Hearing';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ascensionLogo from '../../assets/asce_personalized_care_graphic_vt_fc_rgb_150.png';
import { loginRequest } from '../../auth/config';
import usePlanTypeCheck from '../../hooks/usePlanTypeCheck';
import AccountMenu from './AccountMenu';
import SubMenu from './SubMenu';
import NavMenu from './NavMenu/NavMenu';
import routes from '../../constants/routes';
import { getIsVerified } from '../../auth/auth-utils';
import { useSelector } from 'react-redux';
import { analyticsTrackRouteClickEvent } from '../../utils/analytics/analytics-utils';

const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
    padding: 0
  },
  topOptions: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    padding: '8px 0px'
  },
  bottomOptions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '16px 32px',
    borderTop: '1px solid #9e9e9e',
    borderBottom: '1px solid #9e9e9e',
    [theme.breakpoints.down('md')]: {
      padding: '16px 8px'
    }
  },
  mainMenu: {
    display: 'flex'
  },
  mainMenuLink: {
    display: 'flex',
    alignItems: 'center',
    margin: '0px 32px',
    color: theme.palette.text.primary,
    textDecoration: 'none',
    [theme.breakpoints.down('md')]: {
      margin: '0px 16px'
    }
  },
  mainMenuLabel: {
    fontWeight: 600,
    textTransform: 'uppercase'
  }
}));

const navOptions = [
  { label: 'Dashboard', path: routes.DASHBOARD, Icon: HomeIcon },
  {
    label: 'BENEFITS',
    path: 'BENEFITS',
    nestedMenu: [
      { path: routes.medical.BASE, Icon: LocalHospitalIcon, label: 'Medical' },
      {
        path: routes.pharmacy.BASE,
        Icon: LocalPharmacyIcon,
        label: 'Pharmacy'
      },
      {
        path: routes.ACTIVE_DIRECT,
        Icon: DirectionsRunIcon,
        label: 'Active&Fit'
      },
      { path: routes.TRU_HEARING, Icon: HearingIcon, label: 'TruHearing' }
    ]
  },
  {
    label: 'Find Care',
    path: 'https://www.ascensionpersonalizedcare.com/find-a-doctor',
    external: true,
    Icon: SearchIcon
  },
  { label: 'Payments', path: routes.payment.BASE, Icon: MonetizationOnIcon },
  { label: 'Claims', path: routes.CLAIMS, Icon: ReceiptIcon }
];

export const Banner = ({ history }) => {
  const classes = useStyles();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const isPlanAllowed = usePlanTypeCheck();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const subSuffixCheck = useSelector(state => state?.settings?.accountData?.subscriberSuffix) == 0;
  const [accountMenuAnchor, setAccountMenuAnchor] = useState(null);
  const [navMenuAnchor, setNavMenuAnchor] = useState(null);
  const [subMenuAnchor, setSubMenuAnchor] = useState(null);
  const [subMenuOptions, setSubMenuOptions] = useState(null);

  const handleLogin = () => instance.loginRedirect(loginRequest);
  const handleLogout = () => instance.logout();
  const account = instance.getActiveAccount();
  const accountDetailsData = useSelector(state => state?.settings?.accountData);
  const isBillingEligible = accountDetailsData?.isEligible && accountDetailsData?.isPlanEligible;

  console.log(subSuffixCheck, 'subscriber');

  const isVerified =
    getIsVerified(account) &&
    isPlanAllowed &&
    window.location.pathname !== routes.UNVERIFIED_ACCOUNT;

  const getDisplayName = acc => {
    if (acc && acc.idTokenClaims && acc.idTokenClaims.firstName) {
      return acc.idTokenClaims.firstName;
    } else {
      return 'Account';
    }
  };

  const openAccountMenu = e => setAccountMenuAnchor(e.currentTarget);
  const openNavMenu = e => setNavMenuAnchor(e.currentTarget);
  const closeAccountMenu = () => setAccountMenuAnchor(null);
  const closeNavMenu = () => setNavMenuAnchor(null);

  const openSubMenu = (anchorEl, options) => {
    setSubMenuOptions(options);
    setSubMenuAnchor(anchorEl);
  };

  const closeSubMenu = () => {
    setSubMenuOptions(null);
    setSubMenuAnchor(null);
  };

  return (
    <Toolbar className={classes.toolbar}>
      <div className={classes.bottomOptions}>
        {!isDesktop && isVerified && (
          <>
            <IconButton
              aria-controls="account-menu"
              aria-haspopup="true"
              onClick={openNavMenu}
            >
              <MenuIcon />
            </IconButton>
            <NavMenu
              anchor={navMenuAnchor}
              onClose={closeNavMenu}
              options={navOptions}
            />
          </>
        )}
        <a href="/">
          <img src={ascensionLogo} height="44px" alt="brand" />
        </a>

        {isAuthenticated ? (
          <div className={classes.mainMenu}>
            {isDesktop ? (
              <>
                {isVerified &&

                  isBillingEligible && subSuffixCheck ?
                  navOptions.map(opt =>
                    !opt.nestedMenu ? (
                      <Link
                        key={opt.path}
                        to={opt.external ? { pathname: opt.path } : opt.path}
                        className={classes.mainMenuLink}
                        target={opt.external && '_blank'}
                        onClick={() => analyticsTrackRouteClickEvent(opt.path, {eventCategory: 'Home Page Pathing'})}
                      >
                        <Typography className={classes.mainMenuLabel}>
                          {opt.label}
                        </Typography>
                      </Link>
                    ) : (
                      <Button
                        key={opt.path}
                        onClick={e =>{
                          analyticsTrackRouteClickEvent(opt.path, {eventCategory: 'Home Page Pathing'});
                          openSubMenu(e.currentTarget, opt.nestedMenu);
                        }
                        }
                        endIcon={<KeyboardArrowDownIcon />}
                      >
                        <Typography className={classes.mainMenuLabel}>
                          {opt.label}
                        </Typography>
                      </Button>
                    )
                  ) : (
                    navOptions
                      .filter(opt => opt.label !== 'Payments')
                      .map(opt =>
                        !opt.nestedMenu ? (
                          <Link
                            key={opt.path}
                            to={opt.external ? { pathname: opt.path } : opt.path}
                            className={classes.mainMenuLink}
                            target={opt.external && '_blank'}
                            onClick={() =>{
                              analyticsTrackRouteClickEvent(opt.path, {eventCategory: 'Home Page Pathing'});
                              }}
                          >
                            <Typography className={classes.mainMenuLabel}>
                              {opt.label}
                            </Typography>
                          </Link>
                        ) : (
                          <Button
                            key={opt.path}
                            onClick={e =>
                              openSubMenu(e.currentTarget, opt.nestedMenu)
                            }
                            endIcon={<KeyboardArrowDownIcon />}
                          >
                            <Typography className={classes.mainMenuLabel}>
                              {opt.label}
                            </Typography>
                          </Button>
                        )
                      )
                  )


                }
                <Button
                  onClick={openAccountMenu}
                  startIcon={<AccountCircleIcon />}
                >
                  <Typography style={{ fontWeight: 600 }}>
                    {getDisplayName(account)}
                  </Typography>
                </Button>
              </>
            ) : (
              <IconButton onClick={openAccountMenu}>
                <AccountCircleIcon />
              </IconButton>
            )}
            <AccountMenu
              anchor={accountMenuAnchor}
              onClose={closeAccountMenu}
              history={history}
              logout={handleLogout}
              isVerified={isVerified}
            />
            <SubMenu
              anchor={subMenuAnchor}
              onClose={closeSubMenu}
              history={history}
              options={subMenuOptions}
            />
          </div>
        ) : isDesktop ? (
          <Button onClick={handleLogin} startIcon={<ExitToAppIcon />}>
            <Typography style={{ fontWeight: 600 }}>LOG IN</Typography>
          </Button>
        ) : (
          <IconButton onClick={handleLogin}>
            <ExitToAppIcon />
          </IconButton>
        )}
      </div>
    </Toolbar>
  );
};

Banner.propTypes = {
  history: PropTypes.object.isRequired
};

export default withRouter(Banner);
