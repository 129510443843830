import { createMuiTheme } from '@material-ui/core';

const breakpointValues = {
  xs: 0,
  sm: 600,
  md: 904,
  lg: 1240,
  xl: 1440
};

const theme = createMuiTheme({
  breakpoints: {
    values: {
      ...breakpointValues
    }
  }
});

const appTheme = {
  palette: {
    primary: {
      dark: '#0F3C97',
      main: '#1E69D2',
      light: '#75B3F1'
    },
    secondary: {
      dark: '#810778',
      main: '#B40F87',
      light: '#E868AC'
    },
    error: {
      contrastText: '#fff',
      dark: '#9c0011',
      main: '#E00019',
      light: '#e63347'
    },
    success: {
      contrastText: '#fff',
      dark: '#0c4d25',
      main: '#126E36',
      light: '#418b5e'
    },
    warning: {
      contrastText: '#000',
      dark: '#b25800',
      main: '#FF7F00',
      light: '#ff9833'
    }
  },
  overrides: {
    MuiTab: {
      textColorSecondary: {
        color: '#000',
        fontSize: '12px'
      },
      root: {
        minWidth: 0,
        '@media (min-width: 0px)': {
          minWidth: 0
        }
      }
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#fff',
        color: '#000'
      }
    },
    MuiButton: {
      contained: {
        backgroundColor: '#fff',
        '&:hover': {
          backgroundColor: '#ebebeb'
        }
      }
    }
  },
  breakpoints: {
    values: {
      ...breakpointValues
    }
  },
  typography: {
    fontFamily: 'HCo Whitney SSm',
    fontWeightRegular: 500,
    h1: {
      fontSize: 48,
      fontFamily: 'HCo Chronicle SSm',
      [theme.breakpoints.down('md')]: {
        fontSize: 45
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 34
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 25
      }
    },
    h2: {
      fontSize: 41,
      [theme.breakpoints.down('md')]: {
        fontSize: 34
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 29
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 24
      }
    },
    h3: {
      fontSize: 32,
      [theme.breakpoints.down('md')]: {
        fontSize: 29
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 24
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 21
      }
    },
    h4: {
      fontSize: 25,
      [theme.breakpoints.down('md')]: {
        fontSize: 24
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 20
      }
    },
    h5: {
      fontSize: 20,
      [theme.breakpoints.down('md')]: {
        fontSize: 18
      }
    },
    h6: {
      fontSize: 16,
      fontWeight: 500
    }
  }
};

export default appTheme;
