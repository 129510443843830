import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Avatar, useTheme, useMediaQuery } from '@material-ui/core';
import MemberListButton from './MemberListButton';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    whiteSpace: 'nowrap'
  }
}));

const MemberList = ({
  handleDependentCode,
  dependentCode,
  member,
  dependents
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const formatName = name =>
    `${name.charAt(0).toUpperCase()}${name.slice(1).toLowerCase()}`;

  return (
    <div className={classes.root}>
      <MemberListButton
        isSelected={dependentCode === ''}
        text='All Members'
        startIcon={!isMobile && <Avatar />}
        onClick={() => handleDependentCode('', '', true,false)}
      />
      {member.firstName && (
        <MemberListButton
          isSelected={dependentCode === member.relationCode}
          text={formatName(member.firstName)}
          onClick={() =>
            handleDependentCode(member.relationCode, member.firstName, true, member.isPrimaryLoggedIn)
          }
        />
      )}
      {dependents.map(dependent => (
        <MemberListButton
          key={dependent.relationCode}
          isSelected={dependentCode === dependent.relationCode}
          text={formatName(dependent.firstName)}
          onClick={() =>
            handleDependentCode(
              dependent.relationCode,
              dependent.firstName,
              dependent.isDependentOverEighteen,
              dependent.isThisDependentLoggedIn
            )
          }
        />
      ))}
    </div>
  );
};

MemberList.propTypes = {
  handleDependentCode: PropTypes.func.isRequired,
  dependentCode: PropTypes.string,
  member: PropTypes.object,
  dependents: PropTypes.array
};

export default MemberList;
