import { trackPromise } from 'react-promise-tracker';
import { memberApi } from '../../config/axios-config';
import constants from './constants';


// action creators
export const setAutopayConfiguration = payload => ({
  type: constants.AUTOPAY_CONFIGURATION_SUCCESS,
  payload
});

export const setAutopayConfigurationError = payload => ({
  type: constants.AUTOPAY_CONFIGURATION_ERROR,
  payload
});

export const setAutopayConfigurationLoading = payload => ({
  type: constants.AUTOPAY_CONFIGURATION_BEGIN,
  payload
});

export const setAutopayConfigurationSetup = payload => {
  return {
    type: constants.AUTOPAY_CONFIGURATION_SETUP,
    payload
  };
};

export const setAutopaySetupOn = payload => {
  return {
    type: constants.AUTOPAY_SETUP_ON_SUCCESS,
    payload
  };
};

export const setAutopaySetupOnError = payload => {
  return {
    type: constants.AUTOPAY_SETUP_ON_ERROR,
    payload
  };
};

export const setAutopaySetupOnLoading = payload => {
  return {
    type: constants.AUTOPAY_SETUP_ON_BEGIN,
    payload
  };
};

export const autopaySetupOff = payload => {
  return {
    type: constants.AUTOPAY_SETUP_OFF,
    payload
  };
};


export const fetchAutopayConfiguration = () => dispatch => {
  dispatch(setAutopayConfigurationLoading());
  return trackPromise(
    memberApi
      .get(
        'v3/my/billing/auto-payment'
      )
      .then(res => {
        if (res.status == 200)
          dispatch(setAutopayConfiguration(res.data));
        else
          dispatch(setAutopayConfigurationSetup({ status: res.status }));
      })
      .catch(err => dispatch(setAutopayConfigurationError(err)))
  );
};

export const updateAutopaySetupOn = (stripePaymentMethodId, emailSelection) => dispatch => {
  dispatch(setAutopaySetupOnLoading());
  return trackPromise(
    memberApi
      .put(
        'v3/my/billing/auto-payment', {
        'stripePaymentMethodId': stripePaymentMethodId,
        'agreeReceiveNotification': emailSelection
      }
      )
      .then(res => {
        dispatch(setAutopaySetupOn(res.data));
      })
      .catch(err => dispatch(setAutopaySetupOnError(err)))
  );
};

export const updateAutopaySetupOff = () => dispatch => {
  return trackPromise(
    memberApi
      .delete(
        'v3/my/billing/auto-payment'
      )
      .then(res => {
        dispatch(setAutopaySetupOn(res.data));
      })
      .catch(err => dispatch(setAutopayConfigurationError(err)))
  );
};