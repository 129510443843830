import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `16px solid ${theme.palette.primary.light}`,
    borderRadius: '999px'
  },
  labelContainer: {
    color: '#fff'
  }
}));

const AmountMetDisplay = ({ color, size, label }) => {
  const borderWidth = size / 12.5;
  const classes = useStyles(borderWidth);
  return (
    <div
      className={classes.root}
      style={{ backgroundColor: color, height: size, width: size, borderWidth }}
    >
      <div className={classes.labelContainer}>
        <CheckCircleIcon />
        <Typography>{label}</Typography>
        <Typography>is met</Typography>
      </div>
    </div>
  );
};

AmountMetDisplay.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired
};

export default AmountMetDisplay;
