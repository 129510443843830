import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { ascensionTheme } from '@ascension/ui-library';
import { createMuiTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { store } from '../redux/store';
import appTheme from './App.theme';
import { getEnvVars } from '../utils/env-utils';
import Dashboard from '../components/Dashboard/Dashboard';
import Banner from '../components/Banner/Banner';
import Footer from '../components/Footer/Footer';
import SecureRoute from '../components/common/SecureRoute/SecureRoute';
import IdCardPage from '../components/IdCard/Page/IdCardPage';
import PaymentLanding from '../components/Payment/PaymentLanding/PaymentLanding';
import Settings from '../components/Settings/Settings';
import ClaimsPage from '../components/ClaimsPage/ClaimsPage';
import MedicalPlan from '../components/MedicalPlan/MedicalPlan';
import PharmacyPlan from '../components/PharmacyPlan/PharmacyPlan';
import UnverifiedAccountLanding from '../components/Dashboard/UnverifiedAccountLanding';
import routes from '../constants/routes';
import Support from '../components/Support/Support';
import LiveChatSupport from '../components/LiveChatSupport/LiveChatSupport';
import UserAgreement from '../components/UserAgreement/UserAgreement';
import PrivacyPolicy from '../components/UserAgreement/PrivacyPolicy';
import TruHearing from '../components/TruHearing';
import ActiveAndFitDirect from '../components/ActiveAndFitDirect';
import AutoPaySetup from '../components/AutoPay/Setup/AutoPaySetup';
import '@ascension/ui-library/dist/fonts/WhitneySSm.css';
import '@ascension/ui-library/dist/fonts/ChronicleSSm.css';
import AxiosInterceptors from '../components/common/AxiosInterceptors/AxiosInterceptors';
import AutopayConfirmation from '../components/AutoPay/AutopayConfirmation';

const createdAscensionTheme = createMuiTheme(ascensionTheme);
const createdAppTheme = createMuiTheme(appTheme);
const { stripePubKey } = getEnvVars();
const stripePromise = loadStripe(stripePubKey);

const App = ({ pca }) => {
  return (
    <MsalProvider instance={pca}>
      <Provider store={store}>
        <ThemeProvider theme={createdAscensionTheme}>
          <ThemeProvider theme={createdAppTheme}>
            <CssBaseline />
            <Elements stripe={stripePromise}>
              <BrowserRouter>
                <AxiosInterceptors />
                  <div>
                    <Banner />
                    <Switch>
                      <SecureRoute
                        exact
                        path={routes.DASHBOARD}
                        component={Dashboard}
                      />
                      <SecureRoute
                        path={routes.USER_AGREEMENT}
                        component={UserAgreement}
                        requirePlanCheck={false}
                        requireUserAgreement={false}
                        requireVerification={false}
                      />
                      <SecureRoute
                        path={routes.UNVERIFIED_ACCOUNT}
                        component={UnverifiedAccountLanding}
                        requireVerification={false}
                        requirePlanCheck={false}
                      />
                      <SecureRoute
                        path={routes.ID_CARD}
                        component={IdCardPage}
                      />
                      <SecureRoute
                        path={routes.medical.BASE}
                        component={MedicalPlan}
                      />
                      <SecureRoute
                        path={routes.pharmacy.BASE}
                        component={PharmacyPlan}
                      />
                      <SecureRoute
                        path={routes.payment.BASE}
                        component={PaymentLanding}
                      />
                      <SecureRoute
                        path={routes.SUPPORT}
                        component={Support}
                        requireVerification={false}
                      />
                      <SecureRoute
                        path={routes.LIVE_CHAT}
                        component={LiveChatSupport}
                        requireVerification={false}
                      />
                      <SecureRoute
                        path={routes.SETTINGS}
                        component={Settings}
                      />
                      <SecureRoute
                        path={routes.CLAIMS}
                        component={ClaimsPage}
                      />
                      <SecureRoute
                        path={routes.PRIVACY_POLICY}
                        component={PrivacyPolicy}
                      />
                      <SecureRoute
                        path={routes.TRU_HEARING}
                        component={TruHearing}
                      />
                      <SecureRoute
                        path={routes.ACTIVE_DIRECT}
                        component={ActiveAndFitDirect}
                      />
                      <SecureRoute
                        path={routes.AUTOPAY_SETUP}
                        component={AutoPaySetup}
                      />
                      <SecureRoute  path={routes.AUTOPAY_CONFIRMATION}
                        component={AutopayConfirmation}/>

                      
                    </Switch>
                  </div>
                  <Footer />
              </BrowserRouter>
            </Elements>
          </ThemeProvider>
        </ThemeProvider>
      </Provider>
    </MsalProvider>
  );
};

App.propTypes = {
  pca: PropTypes.object.isRequired
};

export default App;
