import React, { useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import BasePage from '../BasePage';
import SupportItem from './SupportItem';
import items from './support_items.json';
import CallDialog from './Dialogs/CallDialog/CallDialog';
import ChatDialog from './Dialogs/ChatDialog/ChatDialog';
import { useSelector } from 'react-redux';
import { fetchAccountData } from '../../redux/settings/actions';
import { analyticsTrackClickEvent } from '../../utils/analytics/analytics-utils';

const useStyles = makeStyles(() => ({
  list: {
    backgroundColor: '#ffffff',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    width: '100%',
    borderRadius: 8
  }
}));

const Support = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { accountData } = useSelector(state => state.settings);
  const [selectedItem, setSelectedItem] = useState({});
  const [isCallDialogOpen, setIsCallDialogOpen] = useState(false);
  const [isChatDialogOpen, setIsChatDialogOpen] = useState(false);

  useEffect(() => {
    fetchAccountData();
  });

  const handleOpenCallDialog = item => {
    setSelectedItem({ ...item });
    setIsCallDialogOpen(true);
  };

  const handleOpenChatDialog = item => {
    setSelectedItem({ ...item });
    setIsChatDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setSelectedItem({});
    setIsCallDialogOpen(false);
    setIsChatDialogOpen(false);
  };

  return (
    <>
      <BasePage style={{ marginBottom: '32px' }}>
        <Typography variant="h1" style={{ marginBottom: '30px' }}>
          Support
        </Typography>
        <Box className={classes.list}>
          {items.map((item, index) => (
            <SupportItem
              key={index}
              title={item.title}
              phoneNumber={item.phoneNumber}
              isDesktop={isDesktop}
              chatType={item.chatType}
              onOpenCallDialog={() => {
                analyticsTrackClickEvent(item.analyticsEventName, {eventCategory: 'Support Pathing'});
                handleOpenCallDialog({ ...item });
              }}
              onOpenChatDialog={() => {
                analyticsTrackClickEvent(item.analyticsEventName, {eventCategory: 'Support Pathing'});
                handleOpenChatDialog({ ...item });
              }}
            />
          ))}
        </Box>
      </BasePage>
      <CallDialog
        open={isCallDialogOpen}
        onClose={handleCloseDialog}
        phoneNumber={selectedItem.phoneNumber}
        header={selectedItem.dialogHeader}
        memberId={accountData.memberID}
      />
      <ChatDialog
        open={isChatDialogOpen}
        onClose={handleCloseDialog}
        topic={selectedItem.title}
        type={selectedItem.chatType}
        accountData={accountData}
      />
    </>
  );
};

export default Support;
