import { PersonalVideo, Forum, Smartphone, PlayCircleFilled, FitnessCenter, Watch } from '@material-ui/icons';

export const ACTIVE_TILES = [
  {
    title: '200 Free Workout Videos',
    subTitle: 'Available to All Eligible Members',
    icon: FitnessCenter
  },
  {
    title: 'Live Lifestyle Coaching:',
    subTitle: 'Exercise, Nutrition, and more',
    icon: Forum
  },
  {
    title: 'Curated Member Experience',
    icon: PersonalVideo,
  },
  {
    title: 'Digital Resources, Education & Classes',
    icon: Smartphone,
  },
  {
    title: '8,000+ Workout Videos',
    subTitle: 'Available to All Standard and Premium Members',
    icon: PlayCircleFilled,
  },
  {
    title: 'Connected!',
    subTitle: '250+ Trackers & Apps',
    icon: Watch,
  }
];

export const MARK_CONTENTS = [
  'NO Long-Term Member Contracts',
  'NO Cost to Employer or Health Plan',
  'NO Eligibility Reports, Payroll Deductions, or Subsidization Requirements'
];

export const STANDARD_FITNESS_CONTENTS = [
  {
    label: 'Member Price',
    title: '$25 enrollment fee, $25 recurring monthly fee, plus applicable state taxes',
  },
  {
    label: 'Fitness Studios',
    title: 'Smaller location, limited services, typically only group fitness classes',
  },
  {
    label: 'Gender Only',
    title: 'Women’s only Fitness Center',
  },
  {
    label: 'Coed',
    title: 'Traditional type of Fitness Center with cardio and strength equipment',
  },
  {
    label: 'ActiveOptions',
    title: 'Instructor led group fitness classes in traditional settings (Park, Senior Centers, Churches)',
  },
];

export const PREMIUM_FITNESS_CONTENTS = [
  {
    label: 'Member Price',
    title: '$30-$200/mo. (substantial discounts on most memberships)',
  },
  {
    label: 'Fitness Studios',
    title: 'Higher priced studios',
  },
  {
    label: 'Coed',
    title: 'Higher priced fitness centers',
  },
  {
    label: 'Member Access',
    title: 'Single fitness center',
  },
  {
    label: 'Active-Based',
    title: 'Boot Camps, Martial Arts, Indoor Rock Climbing',
  },
];
