import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, useTheme, useMediaQuery } from '@material-ui/core';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import routes from '../../../constants/routes';
import MedicationOutlinedIcon from '@mui/icons-material/MedicationOutlined';
import { getEnvVars } from '../../../utils/env-utils';

const useStyles = makeStyles(theme => ({
  list: {
    marginRight: '12px'
  },
  tabs: {
    margin: '16px 0px'
  },
  tab: {
    color: theme.palette.text.secondary
  },
  listItem: {
    borderRadius: '3px',
    '&$selected': {
      backgroundColor: theme.palette.secondary.main,
      color: '#fff',
      '& > div > svg': {
        color: '#fff'
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.main
      }
    }
  },
  selected: {}
}));

const PharmacyPlanTabs = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { pharmacyBenefitsFeatureFlag } = getEnvVars();
  const items = [
    {
      text: isMobile ? 'STATUS' : 'PLAN STATUS',
      path: routes.pharmacy.BASE,
      icon: <DonutLargeIcon />
    },

    {
      text: isMobile ? 'COSTS' : 'OUT-OF-POCKET COSTS',
      path: routes.pharmacy.COSTS,
      icon: <MonetizationOnIcon />
    },
    {
      text: isMobile ? 'BENEFITS' : 'PHARMACY BENEFITS',
      path: routes.pharmacy.BENEFITS,
      icon: <MedicationOutlinedIcon />,
      disableViaFeatureFlag: !pharmacyBenefitsFeatureFlag
    }
  ];
  const history = useHistory();

  return (
    <Tabs
      className={classes.tabs}
      variant="fullWidth"
      indicatorColor="secondary"
      textColor="secondary"
      aria-label="nav tabs"
      value={history.location.pathname}
      onChange={(_, value) => history.push(value)}
    >
      {items.map(i => {
        if(i?.disableViaFeatureFlag) {
          return;
        }
        return (
          <Tab
            key={i.path}
            icon={i.icon}
            className={classes.tab}
            component="button"
            label={i.text}
            value={i.path}
          />
        );
      })}
    </Tabs>
  );
};

export default PharmacyPlanTabs;
