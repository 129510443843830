import { combineReducers } from 'redux';
import claimsReducer from './claims/reducer';
import planDetailsReducer from './planDetails/reducer';
import paymentsReducer from './payments/reducer';
import settingsReducer from './settings/reducer';
import idCardsReducer from './idCards/reducer';
import autopatReducer from './autopay/reducer';

const appReducer = combineReducers({
  claims: claimsReducer,
  planDetails: planDetailsReducer,
  payments: paymentsReducer,
  settings: settingsReducer,
  idCards: idCardsReducer,
  autopay: autopatReducer,
});

export default (state, action) => appReducer({ ...state }, action);
