export const removeStringCommas = (str) => {
  if (typeof str === 'string') {
    str = str?.split(',').join('');
  }
  return str;
};

export const monitaryFormat = (value, options) => {
  const opt = {
    includeDecimal: true,
    includeCommas: true,
    includeDollarSign: true,
    ...options
  };

  if (!value && value !== 0) return '';

  let res = parseFloat(value);

  if (opt.includeDecimal) {
    res = res.toFixed(2);
  } else {
    res = Math.ceil(res);
  }

  if (opt.includeCommas) {
    res = res.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  if (opt.includeDollarSign) {
    res = `$${res}`;
  }

  return `${res}`;
};

export const capitalizeString = text => {
  if (!text) return '';

  return text.charAt(0).toUpperCase() + text.slice(1);
};
export const camelCaseString = text => {
  if (!text) return '';

  text = text.toLowerCase();
  return text.charAt(0).toUpperCase() + text.slice(1);
};