import { Table, TableBody, TableCell, TableHead, TableRow, makeStyles, useTheme, useMediaQuery, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '& td': {
      padding: theme.spacing(1.5),
      fontSize: '16px',
      color: 'rgba(0, 0, 0, 0.6);',
      border: 'none',
    },
  },
  tableHead: {
    '& th': {
      fontWeight: 'bold',
      padding: theme.spacing(2, 1),
      fontSize: '16px',
      border: 'none',
    },
  },
  table: {
    width: '100%',
    margin: theme.spacing(2, 0),
  },
  divRow: {
    padding: theme.spacing(2),
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    }
  },
  fieldWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    color: 'rgba(0,0,0,0.6)'
  },
  primaryField: {
    fontSize: '18px',
    fontWeight: 600
  },
  highlightValue: {
    color: '#1E69D2',
    fontWeight: 600
  },
  mobileContainer: {
    margin: theme.spacing(2, 0)
  }
}));

function DataTable({ headers, values }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  if (isMobile) {
    return (
      <div className={classes.mobileContainer}>
        {values.map((row, index) => (
          <div key={index} className={classes.divRow}>
            {headers.map(header => {
              if (header.hideOnMobile) return undefined;
              if (header.primary) {
                return (
                  <Typography key={`row-${index}-${header.field}`} className={classes.primaryField}>
                    {row[header.field]}
                  </Typography>
                );
              }
              return (
                <div key={`row-${index}-${header.field}`} className={classes.fieldWrapper}>
                  <Typography>{header.value}</Typography>
                  <Typography className={header.highlight && classes.highlightValue}>{row[header.field]}</Typography>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    );
  }

  return (
    <Table className={classes.table}>
      <TableHead className={classes.tableHead}>
        <TableRow>
          {headers.map(header => (
            <TableCell key={header.field}>{header.value}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {values.map((row, index) => (
          <TableRow key={index} className={classes.tableRow}>
            {headers.map(header => (
              <TableCell key={`row-${index}-${header.field}`}>
                {row[header.field]}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

DataTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.string,
    primary: PropTypes.bool,
    highlight: PropTypes.bool,
    hideOnMobile: PropTypes.bool,
  })).isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default DataTable;
