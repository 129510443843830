import { trackPromise } from 'react-promise-tracker';
import moment from 'moment';
import { memberApi } from '../../config/axios-config';
import constants from './constants';

// action creators
export const setPlanStatus = payload => ({
  type: constants.SET_PLAN_STATUS,
  payload
});

export const setPlanDetails = payload => ({
  type: constants.SET_PLAN_DETAILS,
  payload
});

export const setPharmacyPlan = payload => ({
  type: constants.SET_PHARMACY_PLAN,
  payload
});

export const setMedicalPlanCoverage = payload => ({
  type: constants.SET_MEDICAL_PLAN_COVERAGE,
  payload
});

export const setCoverages = payload => ({
  type: constants.SET_COVERAGES,
  payload
});

export const setPlanError = payload => ({
  type: constants.SET_PLAN_ERROR,
  payload
});

export const setPharmacyPlanOutOfPocketCost = payload => ({
  type: constants.SET_PHARMACY_PLAN_OUT_OF_POCKET_COST,
  payload
});

export const fetchPlanStatus = () => dispatch => {
  trackPromise(
    memberApi
      .post('/v2/my/planstatus', {
        asOfDate: moment(new Date()).format('YYYY-MM-DDThh:mm:ss'),
        planCategory: 'M'
      })
      .then(res => dispatch(setPlanStatus(res.data)))
      .catch(err => dispatch(setPlanError(err.message)))
  );
};

export const fetchPlanDetails = () => dispatch =>
  trackPromise(
    memberApi
      .post('/v2/my/plandetails', {
        asOfDate: moment(new Date()).format('YYYY-MM-DDThh:mm:ss'),
        accountType: 'this-is-not-actually-required',
        planCategory: 'M'
      })
      .then(res => dispatch(setPlanDetails(res.data)))
      .catch(err => dispatch(setPlanError(err.message)))
  );

export const fetchPharmacyPlanStatus = () => dispatch =>
  trackPromise(
    memberApi
      .get('/v2/my/pharmacy/planstatus')
      .then(res => dispatch(setPharmacyPlan(res.data)))
      .catch(err => dispatch(setPlanError(err.message)))
  );

export const fetchMedicalCoverage = () => dispatch =>
  trackPromise(
    memberApi
      .get('/v2/my/scheduleOfBenefitsForAca', {
        params: {
          productType: 'M'
        }
      })
      .then(res => dispatch(setMedicalPlanCoverage(res.data)))
      .catch(err => dispatch(setPlanError(err.message)))
  );

export const fetchPharmacyPlanOutOfPocketCost = () => dispatch =>
  trackPromise(
    memberApi
      .get('/v2/my/scheduleOfBenefitsForAca', {
        params: {
          productType: 'R'
        }
      })
      .then(res => dispatch(setPharmacyPlanOutOfPocketCost(res.data)))
      .catch(err => dispatch(setPlanError(err.message)))
  );

export const fetchCoverages = () => dispatch =>
  trackPromise(
    memberApi
      .post('/v2/my/coverages', {
        fromDate: moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'),
        toDate: moment(new Date(new Date().getFullYear(), 11, 31)).format('YYYY-MM-DD'),
        eligibilityIndicator: true
      })
      .then(res => dispatch(setCoverages(res.data)))
      .catch(err => dispatch(setPlanError(err.message)))
  );
