import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import { Alert } from '@mui/material';
import BasePage from '../BasePage';
import WelcomeOverview from './modules/WelcomeOverview/WelcomeOverview';
import QuickLinks from './modules/QuickLinks/QuickLinks';
import RecentClaims from './modules/RecentClaims/RecentClaims';
import PlanStatus from './modules/PlanStatus/PlanStatus';
import Payments from './modules/Payments/Payments';
import { capitalizeString } from '../../utils/format-utils';
import { fetchClaims } from '../../redux/claims/actions';
import { fetchInvoices } from '../../redux/payments/actions';
import {
  fetchCoverages,
  fetchPlanDetails
} from '../../redux/planDetails/actions';
import {
  fetchAccountData,
  fetchCoveredMembers
} from '../../redux/settings/actions';

const useStyles = makeStyles(theme => ({
  content: {
    padding: '0px 64px',
    [theme.breakpoints.down('md')]: {
      padding: '0px 32px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px'
    }
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const claimsData = useSelector(store => store.claims.claims);
  const { plan, coverage } = useSelector(state => state.planDetails);
  const { accountData } = useSelector(state => state.settings);
  const { coveredMembers } = useSelector(state => state.settings);
  const { billingSummary } = useSelector(state => state.payments);
  const subSuffixCheck = useSelector(state => state?.settings?.accountData?.subscriberSuffix) == 0;
  const bannerCheck = useSelector(state => state?.settings?.accountData?.isEligible) == false;
  const accountDetailsData = useSelector(state => state?.settings?.accountData);
  
  useEffect(() => {
    dispatch(fetchAccountData());
    dispatch(fetchCoverages());
    dispatch(fetchPlanDetails());
    dispatch(fetchCoveredMembers());
    dispatch(fetchClaims({ orderByDate: 'DESC' }, 1, 3));
    dispatch(fetchInvoices());
  }, [dispatch]);

  return (
    <BasePage maxWidth={false}>
      <Box mb={2} className={classes.content}>
      {bannerCheck ? <Grid container spacing={2}>
          <Grid xs={12} item>
            <Alert severity='warning' style={{ marginBottom: '20px', display: 'flex', alignItems:'center', textAlign: 'center' }}>
            If you have recently enrolled, changed your plan or made your premium payment, please allow 5 to 7 business days for those changes to reflect in your portal.  Some members may receive an error message until those changes are reflected on your account.  If you have not yet made your first payment, your plan details will not appear in this portal. Please visit the website you enrolled through to make that payment.
            </Alert>
          </Grid>
        </Grid>
        :
        <Grid></Grid>}
        <Grid container spacing={2}>
          <Grid xs={12} item>
            <Typography variant="h1" style={{ marginBottom: '20px' }}>
              Welcome, {capitalizeString(accountData.firstName)}!
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid xl={4} xs={12} item>
            <WelcomeOverview
              accountData={accountData}
              coverageDate={coverage.effectiveDate}
              coveredMembers={coveredMembers}
            />
          </Grid>
          <Grid xl={4} md={6} xs={12} item>
            <PlanStatus plan={plan} />
          </Grid>

          {subSuffixCheck ? 
          
            <Grid xl={4} md={6} xs={12} item>
              <Payments billingSummary={billingSummary} accountDetailsData={accountDetailsData}/>
            </Grid> :

            <Grid></Grid>
        
          }


          <Grid xl={8} xs={12} item>
            <RecentClaims claimsData={claimsData} />
          </Grid>
          <Grid xl={4} md={8} xs={12} item>
            <QuickLinks />
          </Grid>
        </Grid>
      </Box>
    </BasePage>
  );
};

export default Dashboard;
