import React from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Grid, Box } from '@material-ui/core';
import { memberApi } from '../../../config/axios-config';
import CheckoutForm from './CheckoutForm';
import genericServiceReducer from '../../../redux/common/genericServiceReducer';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getEnvVars } from '../../../utils/env-utils';

const { stripePubKey } = getEnvVars();


const appearance = {
    theme: 'stripe',

    variables: {
        colorBackground: '#ffffff',
        fontFamily: 'HCo Whitney SSm, Ideal Sans, system-ui, sans-serif',
        spacingUnit: '7px',
        borderRadius: '6px',
    },

    loader: 'always',

    rules: {
        '.Input': {
            paddingTop: '17px',
            paddingBottom: '17px',
            border: '1px solid rgba(0, 0, 0, 0.6)'
        },
        '.Label': {
            color: 'rgba(0, 0, 0, 0.6)',
            fontSize: '16px',
            fontWeight: '600'
        },
    },
};

const stripePromise = loadStripe(stripePubKey);


const AddCreditCardSection = ({ setAddNewCardError, setAddCardActive }) => {
    const [clientSecret, setClientSecret] = React.useState('');

    const [addCardReducerState, addCardReducerDispatch] = React.useReducer(genericServiceReducer, {
        status: 'idle',
        data: null,
        error: null
    });

    const addCardIsLoading = addCardReducerState?.status === 'pending';

    React.useEffect(() => {
        addCardReducerDispatch({ type: 'started' });
        memberApi
            .get('/v3/my/billing/payment-method-intent')
            .then(res => {
                addCardReducerDispatch({ type: 'success' });
                setClientSecret(res.data);
                setAddNewCardError(false);
            })
            .catch(err => {
                addCardReducerDispatch({ type: 'error', error: err });
                setAddNewCardError(true);
                setAddCardActive(false);
            });
    }, []);

    if (addCardIsLoading) {
        return (
            <Box>
                <Grid container>
                    <Box mt={2} display="flex" justifyContent="center">
                        <CircularProgress style={{ color: '#c7c7c7' }} />
                    </Box>
                </Grid>
            </Box>
        );
    }

    return (
        <Box>
            <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
                {stripePromise && clientSecret && <Elements stripe={stripePromise} options={{ clientSecret: clientSecret.intentClientSecret, appearance }}>
                    <Box>
                        <CheckoutForm setAddCardActive={setAddCardActive} />
                    </Box>
                </Elements>}
            </Grid>
        </Box>
    );
};

AddCreditCardSection.propTypes = {
    setAddCardActive: PropTypes.func,
    setAddNewCardError: PropTypes.func
};

export default AddCreditCardSection;