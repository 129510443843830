import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  List,
  ListItem,
  Paper,
  Divider
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import { fetchMedicalCoverage } from '../../../../redux/planDetails/actions';
import TrunkText from '../../../common/TrunkText/TrunkText';
import { analyticsTrackClickEvent } from '../../../../utils/analytics/analytics-utils';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '32px 0px'
  },
  title: {
    margin: '8px 0px'
  },
  listContainer: {
    width: '100%'
  },
  coveredData: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    minHeight: '64px',
    paddingLeft: 0,
    paddingRight: 0,
    '& > div': {
      padding: '8px 16px',
      [theme.breakpoints.down('xs')]: {
        padding: '8px'
      }
    }
  },
  coveredDataTitleContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  coveredItemDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '16px',
    '& > div': {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  content: {
    display: 'flex',
    margin: '10px 0'
  },
  userLink: {
    color: '#1E69D2',
    textDecoration: 'none'
  }
}));

const WhatsCovered = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { medicalCoverage } = useSelector(state => state.planDetails);
  const [filteredBenifitList, setFilteredBenifitList] = useState([]);

  useEffect(() => {
    dispatch(fetchMedicalCoverage());
  }, [dispatch]);

  useEffect(() => {
    setFilteredBenifitList([...medicalCoverage]);
  }, [medicalCoverage]);

  const handleSearch = (e, value) => {
    analyticsTrackClickEvent('medicalbenefits_whatscovered_search_clicked', {eventCategory: 'Medical Benefits Pathing'});
    if (value) {
      if(medicalCoverage.filter(i =>
        i.name.toLowerCase().includes(value.toLowerCase())).length > 0){
          analyticsTrackClickEvent('medicalbenefits_whatscovered_search_results_viewed', {eventCategory: 'Medical Benefits Pathing'});
        }
      setFilteredBenifitList(
        medicalCoverage.filter(i =>
          i.name.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      if(medicalCoverage.filter(i =>
        i.name.toLowerCase().includes(value.toLowerCase())).length > 0){
          analyticsTrackClickEvent('medicalbenefits_whatscovered_search_results_viewed', {eventCategory: 'Medical Benefits Pathing'});
        }
      setFilteredBenifitList([...medicalCoverage]);
    }
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.title}>
        <Typography variant="h3">What&apos;s Covered</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.description}
        data-testid="whats-covered-desc"
      >
        <Typography>
          These services are covered when we visit an
          <b> Ascension Network Clinician</b>. You can use the{' '}
          <a
            className={classes.userLink}
            href="https://www.ascensionpersonalizedcare.com/find-a-doctor"
            target="__blank"
          >
            Find Care
          </a>{' '}
          to see what Clinicians or locations are a part of the Ascension
          Network.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <div style={{ marginBottom: '10px' }}>
          <Autocomplete
            freeSolo
            id="size-small-outlined"
            options={
              medicalCoverage ? medicalCoverage.map(opt => opt.name) : []
            }
            onChange={handleSearch}
            renderInput={params => (
              <TextField
                {...params}
                id="outlined-basic"
                label="Search covered services"
                variant="outlined"
                margin="normal"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="primary" />
                    </InputAdornment>
                  )
                }}
              />
            )}
          />
        </div>
      </Grid>

      <Grid item xs={12} sm={8}>
        <div className={classes.content}>
          <Typography variant="body2">
            Displaying {filteredBenifitList.length} of {medicalCoverage.length}{' '}
            covered services
          </Typography>
        </div>
      </Grid>

      <Paper className={classes.listContainer}>
        <List>
          {filteredBenifitList.map((benefit, index) => (
            <>
              <ListItem key={benefit.name} className={classes.coveredData}>
                <Grid item xs={12} sm={6}>
                  <div className={classes.coveredDataTitleContent}>
                    <Typography variant="h5">{benefit.name}</Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.coveredItemDetails}>
                    <TrunkText
                      ariaTitle={benefit.name}
                      text={benefit.covered == 'N' ? 'NOT COVERED' : benefit.benefitSummary}
                    />
                  </div>
                </Grid>
              </ListItem>
              {index < filteredBenifitList.length - 1 && <Divider />}
            </>
          ))}
        </List>
      </Paper>
    </Grid>
  );
};

export default WhatsCovered;
