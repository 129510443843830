import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  Chip,
  IconButton,
  makeStyles
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import CancelIcon from '@material-ui/icons/Cancel';
import ClaimsFilterPopover from './ClaimsFilterPopover';
import { analyticsTrackClickEvent } from '../../../utils/analytics/analytics-utils';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginTop: '24px'
  }
}));

const ClaimsListHeader = ({
  displayClaimNumber,
  claimsData,
  dependentCode,
  selectedMember,
  removeClaimNumberFilter,
  handleFilter
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openFilter = event => {
    setAnchorEl(event.currentTarget);
  };

  React.useEffect(() => {
    selectedMember && analyticsTrackClickEvent('claims_filter_members_modified'), {eventCategory: 'Claims Pathing'};
  }, [selectedMember]);

  React.useEffect(() => {
    displayClaimNumber && analyticsTrackClickEvent('claims_filter_claimnumber_modified'), {eventCategory: 'Claims Pathing'};
  }, [displayClaimNumber]);

  return (
    <Grid item xs={12} className={classes.root}>
      {displayClaimNumber === '' ? (
        <Typography variant="caption">
          Showing {claimsData.length} Claims for{' '}
          {dependentCode === ''
            ? 'all members'
            : selectedMember.charAt(0).toUpperCase() +
              selectedMember.slice(1).toLowerCase()}
        </Typography>
      ) : (
        <Typography variant="caption">
          Showing claim number{' '}
          <Chip
            label={<span>#{displayClaimNumber}</span>}
            onDelete={removeClaimNumberFilter}
            deleteIcon={<CancelIcon style={{ color: 'rgba(0, 0, 0, 0.6)' }} />}
          />
        </Typography>
      )}

      <IconButton
        aria-label="open filter"
        size="small"
        onClick={openFilter}
        data-testid="claims-popover"
      >
        <FilterListIcon color="primary" />
      </IconButton>

      <ClaimsFilterPopover
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleFilter={handleFilter}
      />
    </Grid>
  );
};

ClaimsListHeader.propTypes = {
  displayClaimNumber: PropTypes.string.isRequired,
  claimsData: PropTypes.array,
  dependentCode: PropTypes.string,
  selectedMember: PropTypes.string,
  removeClaimNumberFilter: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired
};

export default ClaimsListHeader;
