import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import {
  fetchPlanStatus,
  fetchPlanDetails
} from '../../redux/planDetails/actions';
import { fetchAccountData } from '../../redux/settings/actions';
import PharmacyPlanBanner from './PharmacyPlanBanner/PharmacyPlanBanner';
import PharmacyPlanTabs from './PharmacyPlanTabs/PharmacyPlanTabs';
import PlanStatus from '../MedicalPlan/views/PlanStatus/PlanStatus';
import PharmacyOutofPocketCosts from './views/PharmacyOutofPocketCosts/PharmacyOutofPocketCosts';
import routes from '../../constants/routes';
import PharmacyBenefits from './views/PharmacyBenefits/PharmacyBenefits';
import { getEnvVars } from '../../utils/env-utils';

const useStyles = makeStyles(theme => ({
  content: {
    padding: '0px 128px',
    [theme.breakpoints.down('md')]: {
      padding: '0px 64px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 32px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px 16px'
    }
  }
}));

const PharmacyPlan = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { plan } = useSelector(state => state.planDetails);
  const { accountData } = useSelector(state => state.settings);
  const { pharmacyBenefitsFeatureFlag } = getEnvVars();

  useEffect(() => {
    dispatch(fetchPlanStatus());
    dispatch(fetchPlanDetails());
    dispatch(fetchAccountData());
  }, [dispatch]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <PharmacyPlanBanner planTitle={accountData.planDescription} />
      </Grid>
      <Grid container item xs={12} className={classes.content}>
        <Grid item xs={12}>
          <PharmacyPlanTabs />
        </Grid>
        <Grid item lg={1}></Grid>
        <Grid item lg={10}>
          <Route
            exact
            path={routes.pharmacy.BASE}
            component={() => <PlanStatus plan={plan} isMobile={isMobile} />}
          />
          <Route
            path={routes.pharmacy.COVERED_DRUG}
            component={() => <div>COVERED DRUG</div>}
          />
          <Route
            path={routes.pharmacy.COSTS}
            component={PharmacyOutofPocketCosts}
          />
           {pharmacyBenefitsFeatureFlag && <Route
            path={routes.pharmacy.BENEFITS}
            component={PharmacyBenefits}
          />}
        </Grid>
        <Grid item lg={1}></Grid>
      </Grid>
    </Grid>
  );
};

export default PharmacyPlan;
