import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { ReactComponent as ClaimsMessageIcon } from '../../../assets/claims_message_icon.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '24px',
    marginBottom: '100px'
  },
  icon: {
    width: '160px',
    height: '160px',
    [theme.breakpoints.down('md')]: {
      width: '128px',
      height: '128px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '112px',
      height: '112px'
    }
  },
  title: {
    marginTop: '32px',
    fontSize: '29px',
    [theme.breakpoints.down('md')]: {
      fontSize: '24px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '21px'
    }
  },
  message: {
    fontSize: '16px',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px'
    }
  }
}));

const ClaimsMessage = ({ title, message }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.root}>
      <ClaimsMessageIcon className={classes.icon} />
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.message}>{message}</Typography>
    </Grid>
  );
};

ClaimsMessage.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};

export default ClaimsMessage;
