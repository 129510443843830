import React from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import backgroundImage from '../../assets/active_hero.png';
import assetImage from '../../assets/active_asset.png';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    [theme.breakpoints.down('xs')]: {
      background: 'none'
    }
  },
  content: {
    padding: theme.spacing(4, 6, 2, 16),
    backgroundColor: '#FAFAFA',
    maxWidth: '550px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '311px',
      padding: theme.spacing(3, 3, 0.5, 3)
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      padding: theme.spacing(2, 2, 0, 2)
    }
  },
  asset: {
    width: '176px',
    [theme.breakpoints.down('sm')]: {
      width: '128px'
    }
  },
  title: {
    color: 'rgba(0,0,0,0.6)',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(3)
    }
  },
  button: {
    fontSize: '18px',
    color: 'white',
    background: '#702F8A',
    textTransform: 'initial',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  }
}));

const ActiveBanner = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <img
          src={assetImage}
          className={classes.asset}
          alt="Active Asset Banner"
        />
        <Typography className={classes.title}>
          Get substantial discounts on 16,000+ standard and premium fitness
          memberships and access 4,000+ workout videos on demand.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          className={classes.button}
          href="https://www.activeandfitdirect.com/fitness/AF793077AS"
          target="__blank"
          rel="noopener"
        >
          Get Started!
        </Button>
      </div>
    </div>
  );
};

export default ActiveBanner;
