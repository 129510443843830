export default {
  SET_PLAN_DETAILS: 'SET_PLAN_DETAILS',
  SET_PLAN_STATUS: 'SET_PLAN_STATUS',
  SET_MEDICAL_PLAN_COVERAGE: 'SET_MEDICAL_PLAN_COVERAGE',
  SET_COVERAGES: 'COVERAGES',
  SET_PLAN_DETAILS_ERROR: 'SET_PLAN_DETAILS_ERROR',
  SET_PHARMACY_PLAN: 'SET_PHARMACY_PLAN',
  SET_PHARMACY_PLAN_OUT_OF_POCKET_COST: 'SET_PHARMACY_PLAN_OUT_OF_POCKET_COST'

};
