import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import genericServiceReducer from '../../../../redux/common/genericServiceReducer';
import Alert from '@material-ui/lab/Alert';
import { memberApi } from '../../../../config/axios-config';


const useStyles = makeStyles(() => ({
    sectionWrapper: {
        backgroundColor: '#ffffff',
        borderRadius: 4,
        padding: 30
    },
    sectionWrapperActive: {
        backgroundColor: '#ffffff',
        border: '1px solid #E2DEDE',
        borderRadius: 4,
        padding: 30
    },
    sectionTitle: {
        fontFamily: 'HCo Whitney SSm',
        fontSize: 24,
        fontWeight: '600',
        margin: '10px 10px 10px 0px'
    }
}));


const PaymentAmountSection = ({ autopaySetupEnable }) => {
    const classes = useStyles();

    const [paymentAmountReducerState, paymentAmountReducerDispatch] = React.useReducer(genericServiceReducer, {
        status: 'idle',
        data: null,
        error: null
    });

    const paymentAmountIsLoading = paymentAmountReducerState?.status === 'pending';
    const paymentAmountError = paymentAmountReducerState?.error;
    const paymentAmountData = parseFloat(paymentAmountReducerState?.data).toFixed(2);


    React.useEffect(() => {
        paymentAmountReducerDispatch({ type: 'started' });
        memberApi
            .get('/v2/my/billing/summary-invoices')
            .then(res => {
                paymentAmountReducerDispatch({ type: 'success', data: res?.data?.billingSummary.netDueAmount });
            })
            .catch(err => {
                paymentAmountReducerDispatch({ type: 'error', error: err });
            });
    }, []);

    if (paymentAmountIsLoading) {
        return (
            <Box>
                <Box className={autopaySetupEnable ? classes.sectionWrapperActive : classes.sectionWrapper}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start">
                        <Typography className={classes.sectionTitle} variant='h5'>Payment Amount: </Typography>
                    </Box>
                    <Box mt={2}>
                        <Box mt={2} display="flex" justifyContent="center">
                            <CircularProgress style={{ color: '#c7c7c7' }} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    }

    if (paymentAmountError) {
        return (
            <Box>
                <Box className={autopaySetupEnable ? classes.sectionWrapperActive : classes.sectionWrapper}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start">
                        <Typography className={classes.sectionTitle} variant='h5'>Payment Amount: </Typography>
                    </Box>
                    <Box mt={2}>
                        <Alert severity="error">
                            We were unable to fetch the information. Please try again.
                        </Alert>
                    </Box>
                </Box>
            </Box>
        );
    }

    return (
        <Box>
            <Box className={autopaySetupEnable ? classes.sectionWrapperActive : classes.sectionWrapper}>
                <Box display="flex" alignItems="center" justifyContent="flex-start">
                    <Typography className={classes.sectionTitle} variant='h5'>Payment Amount: </Typography>
                    <Box>
                        <Typography variant='body1'>Full monthly premium {paymentAmountData ? `(${paymentAmountData})` : '-'}</Typography>
                    </Box>
                </Box>
                <Typography variant='body2' style={{ color: 'rgba(0, 0, 0, 0.56)' }}>Once enrolled in autopay, we’ll automatically debit/charge the full monthly premium amount on the due date. Any changes to your premium will stop autopay until you confirm the updated premium amount and turn autopay back on.</Typography>
            </Box>
        </Box>
    );
};

PaymentAmountSection.propTypes = {
    autopaySetupEnable: PropTypes.bool
};

export default PaymentAmountSection;
