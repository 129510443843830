import Amex from '../../../assets/cards/amex-stripe-svg.svg';
import Diners from '../../../assets/cards/diners-stripe-svg.svg';
import Discover from '../../../assets/cards/discover-stripe-svg.svg';
import Elo from '../../../assets/cards/elo-stripe-svg.svg';
import Jcb from '../../../assets/cards/jcb-stripe-svg.svg';
import Mastercard from '../../../assets/cards/mastercard-stripe-svg.svg';
import Unionpay from '../../../assets/cards/unionpay-stripe-svg.svg';
import Visa from '../../../assets/cards/visa-stripe-svg.svg';
import CreditCardIcon from '@material-ui/icons/CreditCard';

export const getCardIcon = (cardName) => {
    switch(cardName?.toLowerCase()) {
        case 'amex': return Amex;
        case 'diners': return Diners;
        case 'discover': return Discover;
        case 'elo': return Elo;
        case 'jcb': return Jcb;
        case 'mastercard': return Mastercard;
        case 'unionpay': return Unionpay;
        case 'visa': return Visa;
        default: return CreditCardIcon;
    }
};