import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Avatar } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {
    padding: '32px 0px 64px'
  },
  title: {
    margin: '8px 0px'
  },
  description: {
    margin: '8px 0px 24px 0px'
  },
  userData: {
    margin: '8px 16px 8px 16px',
    backgroundColor: '#fff',
    boxShadow:
      '0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.10)',
    padding: '10px'
  },
  userDataView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  avatar: {
    height: '40px',
    width: '40px',
    margin: '0 16px 0 8px',
    backgroundColor: '#1551B4',
    color: '#FFFFFF'
  }
}));

const CoveredMembers = ({ plan }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.title}>
        <Typography variant="h3">Covered Members</Typography>
      </Grid>
      <Grid item xs={12} className={classes.description}>
        <Typography>
          {plan.individuals.length} members of your family are covered by your
          medical plan.
        </Typography>
      </Grid>
      {plan.individuals.map((member, index) => (
        <Grid item xs={12} sm={4} className={classes.userData} key={index}>
          <div className={classes.userDataView}>
            <Avatar className={classes.avatar}>
              {member.firstName.charAt(0)}
            </Avatar>
            <div>
              <Typography>
                {member.firstName + ' ' + member.lastName}
              </Typography>
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

CoveredMembers.propTypes = {
  plan: PropTypes.object.isRequired
};

export default CoveredMembers;
