import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography, Button } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import { getEnvVars } from '../../../../utils/env-utils';



const useStyles = makeStyles(theme => ({
    root: {
        padding: '32px 0px 64px',
        [theme.breakpoints.down('xs')]: {
            paddingTop: '0px'
        }
    },
    blockTitle: {
        fontSize: '34px',
        lineHeight: '32px',
        letterSpacing: '0.25px'
    }
}));

const PharmacyBenefits = () => {
    const classes = useStyles();
    const { maxorLink } = getEnvVars();
    const EXTERNAL_URL = maxorLink;

    return (
        <Grid container className={classes.root} justify="space-between">
            <Grid item xs={12} md={9}>
                <Box sx={{ marginRight: '21px' }}>
                    <Box >
                        <Typography
                            variant="h3"
                            sx={{ fontSize: '34px', lineHeight: '36px', letterSpacing: '0.25px' }}>
                            Pharmacy benefits
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{ fontSize: '15px', lineHeight: '20px', letterSpacing: '-0.24px', marginTop: '8px', marginBottom: '48px' }}>
                            Visit our pharmacy benefit partner, MaxorPlus, to search for covered medications, find in-network pharmacies and understand the cost of your medications before you purchase.
                        </Typography>
                    </Box>

                    <Box>
                        <Typography
                            variant="h3"
                            sx={{ fontSize: '34px', lineHeight: '36px', letterSpacing: '0.25px' }}>
                            Compare Costs
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{ fontSize: '15px', lineHeight: '20px', letterSpacing: '-0.24px', marginTop: '8px', marginBottom: '48px' }}>
                            The MaxorPlus’ Price Drug tool allows you to search your medications to view prices and see how it’s covered under your plan.
                        </Typography>
                    </Box>

                    <Box>
                        <Typography
                            variant="h3"
                            sx={{ fontSize: '34px', lineHeight: '36px', letterSpacing: '0.25px' }}>
                            Find Nearby Pharmacies
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{ fontSize: '15px', lineHeight: '20px', letterSpacing: '-0.24px', marginTop: '8px', marginBottom: '48px' }}>
                            MaxorPlus’ participating pharmacy network includes more than 67,000 retail pharmacies, including regional and national chains, as well as independently owned pharmacies.  Visit the MaxorPlus Find Pharmacy tool to search available pharmacies online or simply call <a style={{color: 'rgba(15, 60, 151, 1)'}} href="tel:8888394448">888-839-4448</a>.
                        </Typography>
                    </Box>

                    <Box>
                        <Typography
                            variant="h3"
                            sx={{ fontSize: '34px', lineHeight: '36px', letterSpacing: '0.25px' }}>
                            Ascension Rx
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{ fontSize: '15px', lineHeight: '20px', letterSpacing: '-0.24px', marginTop: '8px', marginBottom: '48px' }}>
                            Ascension Rx Home Delivery service is the preferred choice for your maintenance and specialty medications. Learn more and find links to Ascension Rx resources in your MaxorPlus portal.
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} md={3}>
                <Box sx={{ padding: '43px' }}>
                    <Button variant="contained" endIcon={<LaunchIcon />}
                        href={EXTERNAL_URL}
                        target="_blank"
                        disableElevation
                        sx={{ padding: '15px', width: '296px', backgroundColor: 'rgba(30, 105, 210, 1)', color: '#ffffff' }}>
                        Visit MaxorPlus
                        </Button>
                </Box>
            </Grid>
        </Grid >
    );
};

export default PharmacyBenefits;
