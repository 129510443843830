export const getDateObj = (initDate, options = {}) => {
  if (!initDate) return {};

  const localDate = new Date(initDate);
  const utcDate = new Date(
    localDate.getTime() + localDate.getTimezoneOffset() * 60000
  );

  if (options.addMonths && options.addMonths > 0) {
    utcDate.setMonth(utcDate.getMonth() + options.addMonths);
  }

  const day = utcDate.getDate();
  const longMonth = utcDate.toLocaleString('default', { month: 'long' });
  const shortMonth = utcDate.toLocaleString('default', { month: 'short' });
  const monthIndex = utcDate.getMonth();
  const year = utcDate.getFullYear();

  const displayDate = `${longMonth} ${day}, ${year}`;

  return { day, longMonth, shortMonth, monthIndex, year, displayDate };
};
