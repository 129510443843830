import constants from './constants';

export const initialState = {
    autopayConfig: {},
    updateAutopay: {}

};


export default (state = initialState, { type, payload }) => {

    switch (type) {
        case constants.AUTOPAY_CONFIGURATION_BEGIN: {
            return {
                ...state,
                loading: true,
                error: null
            };
        }
        case constants.AUTOPAY_CONFIGURATION_SETUP: {
            return {
                ...state,
                status: { ...payload },
                loading: false,
            };
        }
        case constants.AUTOPAY_CONFIGURATION_SUCCESS: {
            return {
                ...state,
                autopayConfig: { ...payload },
                loading: false,
            };
        }
        case constants.AUTOPAY_SETUP_ON_BEGIN: {
            return {
                ...state,
                updateAutopay: {
                    loading: true,
                    error: null
                }
            };
        }

        case constants.AUTOPAY_SETUP_ON_ERROR: {
            return {
                ...state,
                updateAutopay: {
                    loading: false,
                    error: { ...payload },
                    success: null,
                }

            };
        }

        case constants.AUTOPAY_SETUP_ON_SUCCESS: {
            return {
                ...state,
                updateAutopay: {
                    success: { ...payload },
                    loading: false,
                    error: null
                }
            };
        }
        case constants.AUTOPAY_SETUP_OFF: {
            return {
                ...state,

            };
        }
        case constants.AUTOPAY_CONFIGURATION_ERROR: {
            return {
                ...state,
                autopayConfig: null,
                error: { ...payload },
                loading: false
            };
        }
        default:
            return state;
    }
};