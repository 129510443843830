import React from 'react';
import { Paper, Typography, IconButton, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import LaunchIcon from '@material-ui/icons/Launch';
import routes from '../../../constants/routes';
import { analyticsTrackClickEvent, analyticsTrackEvent } from '../../../utils/analytics/analytics-utils';

const useStyles = makeStyles(() => ({
  paper: {
    padding: '16px',
    marginBottom: '20px'
  }
}));

const PrivacyCard = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Grid container>
        <Grid container item xs={2} sm={1} justify="center">
          <VerifiedUserIcon fontSize="medium" />
        </Grid>
        <Grid item xs={10} sm={11}>
          <Typography variant="h5">Privacy</Typography>
        </Grid>
        <Grid item sm={1}></Grid>
        <Grid
          container
          item
          sm={11}
          xs={12}
          justify="space-between"
          alignItems="center"
          style={{ margin: '16px 0px' }}
        >
          <Typography variant="body2">
            Ascension Personalized Care Privacy Policy
          </Typography>
          <IconButton
            aria-label="privacy policy"
            size="small"
            onClick={() => {
              analyticsTrackClickEvent('settings_privacypolicy_dnt_clicked', {eventCategory: 'Account Settings Pathing'});
              analyticsTrackEvent('settings_privacy_viewed', {eventCategory: 'Account Settings Pathing'});
              window.open(routes.PRIVACY_POLICY, '_blank');
          }}
          >
            <LaunchIcon color="primary" />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PrivacyCard;
