import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  Box,
  Paper,
  Typography,
  IconButton,
  useTheme
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import routes from '../../../../constants/routes';
import PlanStatusChart from '../../../common/PlanStatusChart/PlanStatusChart';
import { analyticsTrackClickEvent } from '../../../../utils/analytics/analytics-utils';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px'
  },
  content: {
    padding: '0px 16px'
  },
  chartContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-start'
  },
  chartItem: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column-reverse'
  }
}));

const PlanStatus = ({ plan }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  return (
    <Paper className={classes.root}>
      <Box className={classes.heading}>
        <Typography variant="h5" style={{ fontWeight: 600 }}>
          Plan Status
        </Typography>
        <IconButton
          size="small"
          onClick={() => {
            analyticsTrackClickEvent('home_planstatus_carrot_clicked', {eventCategory: 'Home Page Pathing'});
            history.push(routes.medical.BASE);
          }}
        >
          <NavigateNextIcon color="secondary" />
        </IconButton>
      </Box>
      <Box className={classes.content}>
        <Box className={classes.chartContainer}>
          {plan.deductableMaxAmount > 0 && (
            <PlanStatusChart
              className={classes.chartItem}
              title="Deductible"
              metLabel="Deductible"
              color={theme.palette.primary.main}
              amountMet={plan.deductableAmountMet}
              maxAmount={plan.deductableMaxAmount}
              hasFamilyInPlan={plan.hasFamilyInPlan}
              inlineTitle={true}
            />
          )}
          {plan.outOfPocketMaxAmount > 0 && (
            <PlanStatusChart
              className={classes.chartItem}
              title="Out-of-Pocket Max"
              metLabel="OOP Max"
              color={theme.palette.primary.dark}
              amountMet={plan.outOfPocketAmountMet}
              maxAmount={plan.outOfPocketMaxAmount}
              hasFamilyInPlan={plan.hasFamilyInPlan}
              inlineTitle={true}
            />
          )}
        </Box>
        <Typography style={{ margin: '32px 0px' }}>{plan.status}</Typography>
      </Box>
    </Paper>
  );
};

PlanStatus.propTypes = {
  plan: PropTypes.object
};

export default PlanStatus;
