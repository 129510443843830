import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  Box,
  Paper,
  Typography,
  IconButton
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import routes from '../../../../constants/routes';
import BillActionArea from '../../../Payment/Premium/Bill/BillActionArea';
import NextBillReadyAlert from '../../../Payment/Premium/Alerts/NextBillReadyAlert';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { analyticsTrackClickEvent } from '../../../../utils/analytics/analytics-utils';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px'
  },
  paymentIconBg: {
    backgroundColor: 'rgba(232, 245, 254, 1)',
    height: 112,
    width: 112,
    borderRadius: '50% 50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 40
  },
  inactiveWrapper: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20
  },
  inactiveTitle: {
    marginTop: '32px',
    fontSize: '24px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '21px'
    }
  },
  inactiveMessage: {
    fontSize: '14px',
    margin: '14px auto',
    maxWidth: '80%'
  }
}));

const Payments = ({ billingSummary, accountDetailsData }) => {
  const classes = useStyles();
  const history = useHistory();
  const isBillingEligible = accountDetailsData?.isEligible && accountDetailsData?.isPlanEligible;

  return (
    <Paper className={classes.root}>
      <Box className={classes.heading}>
        <Typography variant="h5" style={{ fontWeight: 600 }}>
          Payments
        </Typography>
        {isBillingEligible && <IconButton
          size="small"
          onClick={() => {
            analyticsTrackClickEvent('home_payments_carrot_clicked', {eventCategory: 'Home Page Pathing'});
            history.push(routes.payment.BASE);
          }}
        >
          <NavigateNextIcon color="secondary" />
        </IconButton>}
      </Box>
      {!isBillingEligible ?
        <Box className={classes.inactiveWrapper}>
          <Box className={classes.paymentIconBg}>
            <AttachMoneyIcon style={{ fontSize: '46px', color: 'rgba(30, 105, 210, 1)' }} />
          </Box>
          <Box>
            <Typography className={classes.inactiveTitle}>No payment due</Typography>
            <Typography className={classes.inactiveMessage}>
              When you have a premium payment due,
              the amount you owe and date it’s due will appear here.
        </Typography>
          </Box>
        </Box> :
        <Box>
          <NextBillReadyAlert isModule={true} />
          <BillActionArea
            bill={billingSummary}
            onPaymentClick={() => {
              analyticsTrackClickEvent('payments_makepaymentbutton_clicked', {eventCategory: 'Payments Pathing'});
              history.push(routes.payment.BASE);
            }}
            isModule={true}
          />
        </Box>}
    </Paper>
  );
};

Payments.propTypes = {
  billingSummary: PropTypes.object,
  accountDetailsData: PropTypes.object
};

export default Payments;
