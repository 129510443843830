import { resolve } from '../../utils/async-utils';
import { memberApi } from '../../config/axios-config';

export const createCheckoutSession = (id, amount, successUrl, cancelUrl) =>
  resolve(
    memberApi
      .post('/v2/my/payments/checkouts', {
        invoiceId: id,
        paymentAmount: Number(amount) * 100,
        successUrl,
        cancelUrl
      })
      .then(res => res.data)
  );
