import paymentHistoryStatuses from '../../constants/paymentHistoryStatuses';
import constants from './constants';

export const initialState = {
  currentInvoice: {},
  billingSummary: {},
  receipts: [],
  history: [],
  methods: [],
  autopaySettings: {},
  isPaymentModalOpen: false,
  error: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.SET_INVOICES: {
      // Selects needed satuses and converts dates from utc to local
      const filteredHistory = payload.paymentDetails
        .filter(
          i =>
            i.paymentStatus === paymentHistoryStatuses.PENDING ||
            i.paymentStatus === paymentHistoryStatuses.SUCCESS
        )
        .map(x => ({
          ...x,
          paymentCreateDate: new Date(`${x.paymentCreateDate}Z`),
          paymentUpdateDate: new Date(`${x.paymentUpdateDate}Z`)
        }));

      return {
        billingSummary: {
          ...payload.billingSummary,
          ...payload.currentInvoiceDetails,
          paymentHistory: [...filteredHistory]
        }
      };
    }

    case constants.SET_RECEIPTS: {
      return {
        ...state,
        receipts: [...payload]
      };
    }

    case constants.SET_PAYMENT_HISTORY: {
      const dataKeyedByYear = payload.reduce((acc, current) => {
        const year = current.date.getFullYear();

        return {
          ...acc,
          [year]: acc[year] ? [...acc[year], { ...current }] : [{ ...current }]
        };
      }, {});

      const dataGroupedByYear = Object.keys(dataKeyedByYear)
        .map(y => ({
          year: y,
          data: [...dataKeyedByYear[y]]
        }))
        .reverse();

      return {
        ...state,
        history: [...dataGroupedByYear]
      };
    }

    case constants.SET_PAYMENT_METHODS:
      return {
        ...state,
        methods: [...payload]
      };

    case constants.SET_AUTOPAY_SETTINGS:
      return {
        ...state,
        autopaySettings: { ...payload }
      };

    case constants.SET_IS_PAYMENT_MODAL_OPEN:
      return {
        ...state,
        isPaymentModalOpen: payload
      };

    case constants.SET_PAYMENT_ERROR:
      return {
        ...state,
        error: payload
      };

    default:
      return state;
  }
};
