import { trackPromise } from 'react-promise-tracker';
import { memberApi } from '../../config/axios-config';
import constants from './constants';
import {
  mockPaymentMethods,
  mockAutopaySettings,
  mockPaymentHistory
} from '../../mockData/payments';

// action creators
export const setInvoices = payload => ({
  type: constants.SET_INVOICES,
  payload
});

export const setReceipts = payload => ({
  type: constants.SET_RECEIPTS,
  payload
});

export const setPaymentHistory = payload => ({
  type: constants.SET_PAYMENT_HISTORY,
  payload
});

export const setPaymentMethods = payload => ({
  type: constants.SET_PAYMENT_METHODS,
  payload
});

export const setAutopaySettings = payload => ({
  type: constants.SET_AUTOPAY_SETTINGS,
  payload
});

export const setPaymentError = payload => ({
  type: constants.SET_PAYMENT_ERROR,
  payload
});

export const setIsPaymentModalOpen = payload => ({
  type: constants.SET_IS_PAYMENT_MODAL_OPEN,
  payload
});

export const fetchInvoices = mockBillState => dispatch =>
  trackPromise(
    memberApi
      .get('/v2/my/billing/summary-invoices')
      .then(res => dispatch(setInvoices({ ...res.data, mockBillState })))
      .catch(err => dispatch(setPaymentError(err.message)))
  );

export const fetchReceipts = () => dispatch =>
  trackPromise(
    memberApi
      .post('/v2/my/receipts', {
        billingStartDate: '2021-12-09'
      })
      .then(res => dispatch(setReceipts({ ...res.data })))
      .catch(err => dispatch(setPaymentError(err.message)))
  );

export const fetchPaymentHistory = () => dispatch =>
  dispatch(setPaymentHistory(mockPaymentHistory));

export const fetchPaymentMethods = () => dispatch => {
  return dispatch(setPaymentMethods(mockPaymentMethods));
};

export const fetchAutopaySettings = () => dispatch => {
  return dispatch(setAutopaySettings(mockAutopaySettings));
};
