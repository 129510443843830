import React from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography
} from '@material-ui/core';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import SearchIcon from '@material-ui/icons/Search';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import HelpIcon from '@material-ui/icons/Help';
import { ReactComponent as IdCardIcon } from '../../../../assets/id-card-icon.svg';
import routes from '../../../../constants/routes';
import { useHistory } from 'react-router-dom';
import { analyticsTrackClickEvent } from '../../../../utils/analytics/analytics-utils';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import LaunchIcon from '@material-ui/icons/Launch';
import { getEnvVars } from '../../../../utils/env-utils';
const { priceTransparencyLink } = getEnvVars();

const links = [
  {
    label: 'Locate in-network doctors near you',
    link: 'https://www.ascensionpersonalizedcare.com/find-a-doctor',
    Icon: SearchIcon,
    internal: false,
    analyticsEventName: 'home_findcare_quicklink_clicked'
  },
  {
    label: 'Review what your medical plan covers',
    link: routes.medical.BASE,
    Icon: LocalHospitalIcon,
    internal: true,
    analyticsEventName: 'home_medicalbenefits_quicklink_clicked'
  },
  {
    label: 'See your ID cards',
    link: routes.ID_CARD,
    Icon: IdCardIcon,
    internal: true,
    analyticsEventName: 'home_idcards_quicklink_clicked'
  }
  ,
  {
    label: 'Get support',
    link: routes.SUPPORT,
    Icon: HelpIcon,
    internal: true,
    analyticsEventName: 'home_support_quicklink_clicked'
  },
  {
    label: 'View cost estimates on medical services',
    link: priceTransparencyLink,
    Icon: MonetizationOnIcon,
    internal: false,
    priceTransparencyLink: true
  }
];

const QuickLinks = () => {
  const history = useHistory();

  return (
    <Paper style={{ height: '100%' }}>
      <Box p={2}>
        <Typography style={{ fontSize: '18px', fontWeight: 600 }}>
          Quick Links
        </Typography>
      </Box>

      <List component="nav" aria-labelledby="quick links">
        {links.map(l => (
          <ListItem button key={l.label}>
            <ListItemIcon>
              <l.Icon color="secondary" />
            </ListItemIcon>
            <ListItemText
              primary={l.label}
              onClick={() => {
                analyticsTrackClickEvent(l.analyticsEventName, {eventCategory: 'Home Page Pathing'});
                l.internal
                  ? history.push(l.link)
                  : window.open(l.link, '_blank');
              }}
            />
            {l?.priceTransparencyLink ? <LaunchIcon color="secondary" /> : <NavigateNextIcon color="secondary" />}
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default QuickLinks;
