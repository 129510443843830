import React from 'react';
import { analyticsTrackEvent, getMappedEventCategory, getMappedPathName } from '../analytics-utils';
import { useLocation } from 'react-router-dom';

export const useAnalyticsPageViewsEvent = () => {
    let location = useLocation();
    React.useEffect(() => {
        const eventName = `${getMappedPathName(location?.pathname)}_viewed`;
        const eventProperties = {
            path: location?.pathname,
            eventName: eventName,
            eventCategory: getMappedEventCategory(eventName)
        };
        analyticsTrackEvent(eventName, eventProperties);
    }, [location]);
};