import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, Button, Avatar, useTheme } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: '#fff',
    padding: '14px 16px',
    marginRight: '16px',
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      height: '50px'
    }
  },
  selectedButton: {
    borderColor: theme.palette.primary.main
  }
}));

const MemberListButton = ({ isSelected, text, ...rest }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      className={clsx(classes.button, isSelected && classes.selectedButton)}
      startIcon={
        <Avatar
          style={{
            color: 'white',
            backgroundColor: theme.palette.primary.dark
          }}
        >
          {text.charAt(0)}
        </Avatar>
      }
      {...rest}
    >
      {text}
    </Button>
  );
};

MemberListButton.propTypes = {
  text: PropTypes.string,
  isSelected: PropTypes.bool.isRequired
};

export default MemberListButton;
