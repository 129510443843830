import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Cancel';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import { useDispatch } from 'react-redux';
import { updateAutopaySetupOff } from '../../../../../redux/autopay/actions';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: 24,
        maxWidth: 300
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: 'rgba(0, 0, 0, 0.6)',
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" style={{ fontSize: 20, fontWeight: '600' }}>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(() => ({
    root: {
        padding: '8px 24px',
        color: 'rgba(0, 0, 0, 0.6)'
    },
}))(MuiDialogContent);

const DialogActions = withStyles(() => ({
    root: {
        margin: 0,
        padding: 24,
    },
}))(MuiDialogActions);

const AutopayOffConfirmationModal = ({ open, handleClose, setAutopaySetupEnable, autopaySetupEnable }) => {
    const [showModal, setShowModal] = React.useState(false);
    const dispatch = useDispatch();
    const handleModalVisibilityCheck = () => {
        setShowModal(!showModal);
    };

    const handleAUtopayOffButton = () => {
        if (showModal === true) {
            localStorage.setItem('autopaySetupModalVisibilityCheck', 'true');
        }
        setAutopaySetupEnable(!autopaySetupEnable);
        handleClose();
    };

    React.useEffect(() => {
        if(autopaySetupEnable === false) dispatch(updateAutopaySetupOff());
    }, [autopaySetupEnable]);

    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="autopay-off-modal-title" open={open} maxWidth='xs' fullWidth={false}>
                <DialogTitle id="autopay-off-modal-title" onClose={handleClose}>
                    Are you sure you want to turn autopay off?
                </DialogTitle>
                <DialogContent>
                    <Typography gutterBottom>
                        Please confirm that you want to turn autopay off. You can turn it back on at any time.
                     </Typography>
                    <br />
                    <FormGroup>
                        <FormControlLabel style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                            control={
                                <Checkbox
                                    checked={showModal}
                                    onChange={handleModalVisibilityCheck}
                                    name="HideAutopayOffConfirmationModalCheck"
                                    color="primary"
                                />
                            }
                            label="Don’t show me this message again"
                        />
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleAUtopayOffButton} color="primary" variant='contained'>
                        Turn autopay off
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

AutopayOffConfirmationModal.propTypes = {
    autopaySetupEnable: PropTypes.bool,
    setAutopaySetupEnable: PropTypes.func,
    handleClose: PropTypes.func,
    open: PropTypes.bool
};

export default AutopayOffConfirmationModal;