import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const AsyncTypography = ({ children, skeletonProps, ...rest }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (Array.isArray(children)) {
      setLoading(children.includes(undefined) || children.includes(null));
    } else {
      setLoading(!children);
    }
  }, [children]);

  return (
    <Typography {...rest}>
      {loading ? (
        <Skeleton variant="text" style={{ width: '80px' }} {...skeletonProps} />
      ) : (
        children
      )}
    </Typography>
  );
};

AsyncTypography.propTypes = {
  children: PropTypes.node,
  skeletonProps: PropTypes.object
};

export default AsyncTypography;
