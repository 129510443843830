import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import check_circle from '../../../assets/check_circle.png';
import { memberApi } from '../../../config/axios-config';
import { useState } from 'react';
import genericServiceReducer from '../../../redux/common/genericServiceReducer';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles(() => ({
    sectionWrapper: {
        backgroundColor: '#ffffff',
        border: '1px solid #E8EAEC',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: 4,
        padding: 30
    },
    sectionTitle: {
        fontFamily: 'HCo Whitney SSm',
        fontSize: 22,
        fontWeight: '600',
        marginBottom: 10
    },
    radioHelperText: {
        marginLeft: 30,
        marginTop: -7,
        fontSize: '1rem',
        fontFamily: 'HCo Whitney SSm',
        fontWeight: 500,
        lineHeight: 1.5
    },
    buttonWrapper: {
        marginTop: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right',
        '& button': {
            padding: '16px 41.5px'
        }
    },
    previewWrapper: {
        padding: '20px 10px 20px 30px',
        backgroundColor: '#ffffff',
        border: '1px solid #E8EAEC',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& img': {
            marginRight: 12
        }
    },
    titleBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    infoBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& p': {
            fontSize: 15,
            color: 'rgba(0, 0, 0, 0.87)'
        },
        '& button': {
            color: 'rgba(30, 105, 210, 1)',
            textTransform: 'none',
            textDecoration: 'underline',
            fontSize: 16,
            marginLeft: 5
        },
    },
    previewTitle: {
        fontFamily: 'HCo Whitney SSm',
        fontSize: 16,
        fontWeight: '600'
    },
}));

const PaymentAmountSection = ({ expand, setExpand, setSaveStatus, saveStatus, setPaymentAmountDue }) => {
    const classes = useStyles();
    const [radioValue, setRadioValue] = React.useState('');
    const [currentDueAmount, setCurrentDueAmount] = useState('-');
    const paymentDue = parseFloat(currentDueAmount).toFixed(2);

    const [paymentAmountReducerState, paymentAmountReducerDispatch] = React.useReducer(genericServiceReducer, {
        status: 'idle',
        data: null,
        error: null
    });

    const paymentAmountIsLoading = paymentAmountReducerState?.status === 'pending';
    const paymentAmountError = paymentAmountReducerState?.error;

    React.useEffect(() => {
        paymentAmountReducerDispatch({ type: 'started' });
        memberApi
            .get('/v2/my/billing/summary-invoices')
            .then(res => {
                paymentAmountReducerDispatch({ type: 'success' });
                setCurrentDueAmount(res?.data?.billingSummary.netDueAmount);
            })
            .catch(err => {
                paymentAmountReducerDispatch({ type: 'error', error: err });
            });
    }, []);

    
    const handleEdit = () => {
        setExpand(true);
        setSaveStatus(false);
    };

    const handleRadioChange = (event) => {
        setRadioValue(event.target.value);
    };

    const handleSave = () => {
        setSaveStatus(true);
        setExpand(false);
        setPaymentAmountDue(paymentDue);
    };

    if (!expand) {
        return (
            <Box className={classes.previewWrapper}>
                <Box className={classes.titleBox} display="flex" alignItems="center" justifyContent="center">
                    {saveStatus && <img src={check_circle} />}
                    <Typography className={classes.previewTitle} variant='h5'>1. Payment amount</Typography>

                </Box>
                {saveStatus && <Box className={classes.infoBox} display="flex" alignItems="center" justifyContent="center">
                    <p>Full monthly premium (${paymentDue})</p>
                    <Button size="small" onClick={handleEdit}>Edit</Button>
                </Box>}
            </Box>
        );
    } else {

        if (paymentAmountIsLoading) {
            return (
                <Box>
                    <Box className={classes.sectionWrapper}>
                        <Typography className={classes.sectionTitle} variant='h5'>1. Payment amount</Typography>
                        <Box mt={2} display="flex" justifyContent="center">
                            <CircularProgress style={{ color: '#c7c7c7' }} />
                        </Box>

                    </Box>
                </Box>
            );
        }

        if (paymentAmountError) {
            return (
                <Box>
                    <Box className={classes.sectionWrapper}>
                        <Typography className={classes.sectionTitle} variant='h5'>1. Payment amount</Typography>
                        <Box mt={2}>
                            <Alert severity="error">
                                We were unable to fetch the information. Please try again.
                            </Alert>
                        </Box>

                    </Box>
                </Box>
            );
        }

        return (
            <Box>
                <Box className={classes.sectionWrapper}>
                    <Typography className={classes.sectionTitle} variant='h5'>1. Payment amount</Typography>
                    <Typography variant='p' style={{ color: 'rgba(0, 0, 0, 0.56)' }}>
                        Once enrolled in autopay, we’ll automatically debit/charge the full monthly premium amount on the due date. Any changes to your premium will stop autopay until you confirm the updated premium amount and turn autopay back on. You must have a $0 balance before setting up autopay.
                </Typography>

                    <Box mt={4}>
                        <FormControl>
                            <RadioGroup aria-label="payment amount" name="paymentAmount" value={radioValue} onChange={handleRadioChange}>
                                <FormControlLabel value="Full monthly premium" control={<Radio color="primary" />} label={'Full Monthly Premium' + ' ' + '(' + '$' + paymentDue + ')'} />
                            </RadioGroup>
                        </FormControl>
                        <p className={classes.radioHelperText}>Your total balance due is your upcoming premium.</p>
                    </Box>

                    <Box className={classes.buttonWrapper}>
                        <Button variant="contained" color="primary" disabled={!radioValue} onClick={handleSave}>
                            Next
                    </Button>
                    </Box>
                </Box>
            </Box>
        );
    }
};

PaymentAmountSection.propTypes = {
    expand: PropTypes.bool,
    setExpand: PropTypes.func,
    setSaveStatus: PropTypes.func,
    saveStatus: PropTypes.bool,
    setPaymentAmountDue: PropTypes.func
  };

export default PaymentAmountSection;
