import { trackPromise } from 'react-promise-tracker';
import constants from './constants';
import { memberApi } from '../../config/axios-config';

// action creators
export const setAccountData = payload => ({
  type: constants.SET_ACCOUNT_DATA,
  payload
});

export const setAccountDataError = payload => ({
  type: constants.SET_ACCOUNT_DATA_ERROR,
  payload
});

export const setCoveredMembers = payload => ({
  type: constants.SET_COVERED_MEMBERS,
  payload
});
export const setCoveredMembersError = payload => ({
  type: constants.SET_COVERED_MEMBERS_ERROR,
  payload
});

export const fetchAccountData = () => dispatch =>
  trackPromise(
    memberApi
      .get('/v3/my/enrollments')
      .then(res => {
        dispatch(setAccountData(res.data));
      })
      .catch(err => dispatch(setAccountDataError(err.message)))
  );

export const fetchCoveredMembers = () => dispatch =>
  trackPromise(
    memberApi
      .get('/v2/my/coveredMembers')
      .then(res => {
        dispatch(setCoveredMembers(res.data));
      })
      .catch(err => dispatch(setCoveredMembersError(err.message)))
  );
