import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import BasePage from '../BasePage';
import ActiveBanner from './ActiveBanner';
import ActiveFooter from './ActiveFooter';
import activeMarkImage from '../../assets/active_mark.png';
import directionsRunIcon from '../../assets/directions_run.svg';
import selfImprovementIcon from '../../assets/self_improvement.svg';
import {
  ACTIVE_TILES,
  MARK_CONTENTS,
  PREMIUM_FITNESS_CONTENTS,
  STANDARD_FITNESS_CONTENTS
} from './constant';

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(6, 18),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 3)
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4, 2)
    }
  },
  subContainer: {
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1)
    }
  },
  activeTile: {
    display: 'flex'
  },
  title: {
    fontSize: '34px',
    marginBottom: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      fontSize: '29px',
      marginBottom: theme.spacing(4)
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px'
    }
  },
  fitnessTitle: {
    fontSize: '24px',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      fontSize: '21px',
      marginBottom: theme.spacing(4)
    }
  },
  subTitle: {
    fontSize: '14px',
    color: 'rgba(0,0,0,0.6);'
  },
  leftContainer: {
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(3)
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0
    }
  },
  rightContainer: {
    paddingLeft: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3)
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0
    }
  },
  fitnessImageWrapper: {
    height: '96px',
    width: '100%',
    textAlign: 'center',
    marginBottom: theme.spacing(3)
  },
  memberShipTitle: {
    fontSize: '24px',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px'
    }
  },
  memberShipContent: {
    display: 'flex',
    margin: theme.spacing(3, 0)
  },
  memberShipLabel: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      float: 'right'
    }
  },
  memberShipSubTitle: {
    fontSize: '14px',
    color: 'rgba(0,0,0,0.6);',
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1)
    }
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54);',
    width: '40px',
    height: '40px',
    marginRight: theme.spacing(2)
  },
  markContainer: {
    textAlign: 'center',
    marginBottom: theme.spacing(7),
    '& ul': {
      marginTop: theme.spacing(2),
      textAlign: 'left',
      '& li': {
        paddingLeft: theme.spacing(2),
        marginTop: theme.spacing(1.5)
      }
    },
    [theme.breakpoints.down('sm')]: {
      margin: '56px auto',
      display: 'flex',
      alignItems: 'center',
      maxWidth: '576px',
      '& ul': {
        paddingLeft: theme.spacing(7)
      }
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      margin: '40px auto',
      '& ul': {
        paddingLeft: theme.spacing(2)
      }
    }
  },
  markImage: {
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '144px'
    }
  }
}));

const MembershipList = ({ items }) => {
  const classes = useStyles();

  return items.map(content => (
    <Grid container key={content.label} className={classes.memberShipContent}>
      <Grid item xs={4}>
        <Typography className={classes.memberShipLabel}>
          {content.label}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography className={classes.memberShipSubTitle}>
          {content.title}
        </Typography>
      </Grid>
    </Grid>
  ));
};

const ActiveAndFitDirect = () => {
  const classes = useStyles();

  return (
    <BasePage fullWidth>
      <Grid item xs={12}>
        <ActiveBanner />
      </Grid>

      <Grid className={classes.content} container>
        <Grid item xs={12} lg={8}>
          <Typography className={classes.title}>
            Stay Active at the Gym or at Home
          </Typography>
          <Grid container className={classes.subContainer} spacing={4}>
            {ACTIVE_TILES.map(tile => {
              const Icon = tile.icon;
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  key={tile.title}
                  className={classes.activeTile}
                >
                  <Icon className={classes.icon} />
                  <div>
                    <Typography>{tile.title}</Typography>
                    {tile.subTitle && (
                      <Typography
                        variant="subtitle2"
                        className={classes.subTitle}
                      >
                        {tile.subTitle}
                      </Typography>
                    )}
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4} className={classes.markContainer}>
          <img
            src={activeMarkImage}
            alt="Active Mark"
            className={classes.markImage}
          />
          <ul>
            {MARK_CONTENTS.map(content => (
              <li key={content}>{content}</li>
            ))}
          </ul>
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.fitnessTitle}>
            Choose the Right Fitness Membership for You
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.leftContainer}>
          <div className={classes.fitnessImageWrapper}>
            <img src={directionsRunIcon} alt="Standard Fitness" />
          </div>
          <Typography className={classes.memberShipTitle}>
            Standard Fitness Network
          </Typography>
          <Typography variant="subtitle1" className={classes.subTitle}>
            (13,000+) include Fitnes Centeres, YMCAs, Gender-Specific,
            Instructor-Based, Senior Centers, and sessions at Fitness Studios,
            Yoga, Pilates & more.
          </Typography>
          <MembershipList items={STANDARD_FITNESS_CONTENTS} />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.rightContainer}>
          <div className={classes.fitnessImageWrapper}>
            <img src={selfImprovementIcon} alt="Premium Fitness" />
          </div>
          <Typography className={classes.memberShipTitle}>
            Premium Fitness Network
          </Typography>
          <Typography variant="subtitle1" className={classes.subTitle}>
            (7,000+) include higher priced Fitness Centers, unique experiences
            like Rock Climbing Gyms, Rowing Centers, and sessions at Fitness
            Studios, Yoga, Pilates & more.
          </Typography>
          <MembershipList items={PREMIUM_FITNESS_CONTENTS} />
        </Grid>
      </Grid>

      <ActiveFooter />
    </BasePage>
  );
};

export default ActiveAndFitDirect;
