import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Menu, MenuItem } from '@material-ui/core';
import { analyticsTrackRouteClickEvent } from '../../utils/analytics/analytics-utils';

const useStyles = makeStyles(theme => ({
  menuItemIcon: {
    color: theme.palette.action.active,
    marginRight: '35px',
    margin: '6px 0 6px'
  }
}));

const SubMenu = ({ anchor, onClose, history, options }) => {
  const classes = useStyles();

  const handleClick = path => {
    analyticsTrackRouteClickEvent(path, { eventCategory: 'Home Page Pathing' });
    onClose();
    history.push(path);
  };

  return (
    options && (
      <Menu
        id="simple-menu-1"
        anchorEl={anchor}
        keepMounted
        open={Boolean(anchor)}
        onClose={onClose}
        style={{ marginTop: '40px' }}
        PaperProps={{
          style: {
            width: 280
          }
        }}
      >
        {options.map(({ path, Icon, label }) => (
          <MenuItem key={label} onClick={() => handleClick(path)}>
            <Icon className={classes.menuItemIcon} />
            {label}
          </MenuItem>
        ))}
      </Menu>
    )
  );
};

SubMenu.propTypes = {
  anchor: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  onClose: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  options: PropTypes.array
};

export default SubMenu;
