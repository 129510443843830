import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  ListItem,
  ListItemText,
  Typography,
  Grid,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderColor: 'black',
    border: 'solid 2px',
    marginRight: '16px',
    borderRadius: '4px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      padding: '0px 8px'
    }
  },
  displayMonth: {
    fontSize: '10px',
    [theme.breakpoints.down('md')]: {
      fontSize: 'unset',
      marginRight: '8px'
    }
  },
  title: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.6)'
  },
  value: {
    fontSize: '14px'
  },
  youOweValue: {
    fontSize: '18px',
    textAlign: 'right'
  },
  member: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'right',
    width: '100%',
    margin: '0px 16px',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  contentFirstColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'right',
    width: '100%',
    margin: '0px'
  }
}));

const ClaimDetails = ({ claim, ...rest }) => {
  const classes = useStyles();

  return claim ? (
    <ListItem {...rest}>
      <Grid container alignItems="center">
        <Grid item sm={2}>
          <div className={classes.box}>
            <Typography className={classes.displayMonth}>
              {moment(claim.serviceDateTo).format('MMM').toUpperCase()}
            </Typography>
            <Typography>{moment(claim.serviceDateTo).format('D')}</Typography>
          </div>
        </Grid>

        <Grid item xs={12} sm={10} className={classes.contentFirstColumn}>
          <ListItemText
            primary={claim.providerName}
            secondary={claim.claimNumber}
          />
        </Grid>
      </Grid>

      <div className={classes.member}>
        <Typography className={classes.title}>Member</Typography>
        <Typography className={classes.Value}>{claim.firstName}</Typography>
      </div>

      <Grid container>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.title} style={{ textAlign: 'right' }}>
            Billed
          </Typography>
          <Typography className={classes.value} style={{ textAlign: 'right' }}>
            ${claim.totCharge.toFixed(2)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.title} style={{ textAlign: 'right' }}>
            You may owe
          </Typography>
          <Typography className={classes.youOweValue}>
            ${claim?.totalYouOwe?.toFixed(2)}
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  ) : null;
};

ClaimDetails.propTypes = {
  claim: PropTypes.object.isRequired
};

export default ClaimDetails;
