import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, Container, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import AccountCard from './cards/AccountCard';
import PrivacyCard from './cards/PrivacyCard';
import { analyticsTrackEvent } from '../../utils/analytics/analytics-utils';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  btnGrp: {
    marginTop: '8px',
    '& > button': {
      marginRight: '16px'
    }
  },
  header: {
    margin: '30px 0 30px 0'
  },
  buttonView: {
    display: 'flex'
  },
  buttonName: {
    margin: '0 0 0 15px'
  },
  fullButtonView: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  colorInherit: {
    color: '#fafafa',
    backgroundColor: '#D2419C',
    '&:hover': {
      color: '#fafafa',
      backgroundColor: '#D2419C'
    }
  },
  iconColor: {
    color: 'rgba(0, 0, 0, 0.54)'
  }
}));

const Settings = () => {
  const classes = useStyles();
  const [buttonClicked, setButtonClicked] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const accountClicked = () => {
    analyticsTrackEvent('settings_account_viewed', {eventCategory: 'Account Settings Pathing'});
    setButtonClicked('ACTIVE');
    window.scrollTo(0, 200);
  };

  const privacyClicked = () => {
    analyticsTrackEvent('settings_privacypolicy_viewed', {eventCategory: 'Account Settings Pathing'});
    setButtonClicked('PRIVACY');
    window.scrollTo(0, 560);
  };

  return (
    <Container maxWidth="lg">
      <div className={classes.header}>
        <Typography variant="h2">Settings</Typography>
      </div>
      <Hidden initialWidth="lg" smDown>
        <Grid justify="center" container spacing={2}>
          <Grid item xs={2}>
            <Button
              fullWidth
              classes={{
                fullWidth: classes.fullButtonView,
                colorInherit: classes.colorInherit
              }}
              onClick={() => accountClicked()}
              color={buttonClicked === 'ACTIVE' ? 'inherit' : ''}
            >
              <AccountCircleIcon
                fontSize="medium"
                classes={{ root: classes.iconColor }}
              />
              <span className={classes.buttonName}>Account</span>
            </Button>
            <Button
              fullWidth
              classes={{
                fullWidth: classes.fullButtonView,
                colorInherit: classes.colorInherit
              }}
              onClick={() => privacyClicked()}
              color={buttonClicked === 'PRIVACY' ? 'inherit' : ''}
              data-testid="privacy-button"
            >
              <VerifiedUserIcon
                fontSize="medium"
                classes={{ root: classes.iconColor }}
              />
              <span className={classes.buttonName}>Privacy</span>
            </Button>
          </Grid>
          <Grid item xs={10}>
            <AccountCard />
            <PrivacyCard />
          </Grid>
        </Grid>
      </Hidden>
      <Hidden initialWidth="lg" mdUp>
        <AccountCard />
        <PrivacyCard />
      </Hidden>
    </Container>
  );
};

export default Settings;
