export const authConfig = {
  dev: {
    applicationId: '2a6b6978-9773-4b81-a9da-4bf61ac8a97b',
    authority:
      'https://ascensionidqa.b2clogin.com/ascensionidqa.onmicrosoft.com',
    scopes: [
      'https://ascensionidqa.onmicrosoft.com/member-experience-api/app.access',
      'https://ascensionidqa.onmicrosoft.com/member-experience-api/user_impersonation'
    ],
    loginPolicy: 'B2C_1A_Signup_SignIn_Member_qa'
  },
  qa: {
    applicationId: '2a6b6978-9773-4b81-a9da-4bf61ac8a97b',
    authority:
      'https://ascensionidqa.b2clogin.com/ascensionidqa.onmicrosoft.com',
    scopes: [
      'https://ascensionidqa.onmicrosoft.com/member-experience-api/app.access',
      'https://ascensionidqa.onmicrosoft.com/member-experience-api/user_impersonation'
    ],
    loginPolicy: 'B2C_1A_Signup_SignIn_Member_qa'
  },
  uat: {
    applicationId: 'f6f568c0-e6b4-4629-9f8e-1bb4d76de42d',
    authority:
      'https://ascensionidqa.b2clogin.com/ascensionidqa.onmicrosoft.com',
    scopes: [
      'https://ascensionidqa.onmicrosoft.com/member-experience-api-uat/app.access',
      'https://ascensionidqa.onmicrosoft.com/member-experience-api-uat/user_impersonation'
    ],
    loginPolicy: 'B2C_1A_Signup_SignIn_Member_uat'
  },
  prod: {
    applicationId: 'a022a6f4-4455-4326-862f-cc11dc649ef3',
    authority: 'https://id.ascension.org/id.ascension.org',
    scopes: [
      'https://id.ascension.org/member-experience-api/app.access',
      'https://id.ascension.org/member-experience-api/user_impersonation'
    ],
    loginPolicy: 'B2C_1A_Signup_SignIn_Member'
  }
};

export const manageAccountConfig = {
  qa: {
    url: 'https://ascensionid-userprofile-editor-qa.app.cloud-02.pcf.ascension.org/'
  },
  prod: {
    url: 'https://profile.ascension.org'
  }
};

export const baseApiConfig = {
  dev: {
    url: 'https://dev-api.ascension.org',
    memberKey: 'FCULowINknGSjHCS1rOzzLNV3tLJcft8',
    providerKey: 'oO4Ac1E17E8Ucfj4Bp1enGuANqejSqHW',
    chatTarget: 'ABS_TestChat'
  },
  qa: {
    url: 'https://test-api.ascension.org',
    memberKey: 'FCULowINknGSjHCS1rOzzLNV3tLJcft8',
    providerKey: 'oO4Ac1E17E8Ucfj4Bp1enGuANqejSqHW',
    chatTarget: 'ABS_TestChat'
  },
  uat: {
    url: 'https://uat-api.ascension.org',
    memberKey: 'FCULowINknGSjHCS1rOzzLNV3tLJcft8',
    providerKey: 'oO4Ac1E17E8Ucfj4Bp1enGuANqejSqHW',
    chatTarget: 'ABS_TestChat'
  },
  prod: {
    url: 'https://prod-api.ascension.org',
    memberKey: 'GIrTeGrmYLbw39YGedqzDbevt7oxeANN',
    searchKey: '',
    chatTarget: 'ABS_Chat'
  }
};

// TODO: ADD STRIPE CONFIG / REFACTOR CONFIG STRUCTURE
export const stripeConfig = {
  nonProd:
    'pk_test_51J19egCzdYVqjhwjg2dU99ubxgnerMOKNZ5fnSHkb9uLZ47F2hg7CoXd0RLSkYnw3AhnFhoZAcW8j5hR4JFwOSjo00QZcQuD30',
  prod: 'pk_live_51J19egCzdYVqjhwjd3rAwjsQ2rrcjaxbJsApllQNlvp9MT9TkewVqqGe0s9rmeMPZwV8OEZtiYGDyn4MgADGXTYF00K4Vubnxm'
};

// TODO: ADD AMPLITUDE PROD APIKEY 
export const amplitudeConfig = {
  nonProd:
    '853a0578c07a18ec9bf22ce95c8534bf',
  prod:
    '853a0578c07a18ec9bf22ce95c8534bf'
};

export const chatFeatureFlag = {
  nonProd: {
    isLiveChatEnabled: false
  },
  dev: {
    isLiveChatEnabled: false
  },
  uat: {
    isLiveChatEnabled: false
  },
  qa: {
    isLiveChatEnabled: false
  },
  prod: {
    isLiveChatEnabled: false
  }
};

export const pharmacyBenefitsFeatureFlag = {
  nonProd: {
    isPharmacyBenefitsTabEnabled: true
  },
  dev: {
    isPharmacyBenefitsTabEnabled: true
  },
  uat: {
    isPharmacyBenefitsTabEnabled: true
  },
  qa: {
    isPharmacyBenefitsTabEnabled: true
  },
  prod: {
    isPharmacyBenefitsTabEnabled: true
  }
};

// TODO: ADD LINKS AS PER ENVIRONMENTS, FOR NOW ONLY ONE LINK AVAILABLE
export const priceTransparencyLink = {
  dev: {
    url: 'https://ascension.trizettoconnect.com/tzf/costestimator/uicostestimator/?domainHint=FACETS_APC'
  },
  qa: {
    url: 'https://ascension.trizettoconnect.com/tzf/costestimator/uicostestimator/?domainHint=FACETS_APC'
  },
  uat: {
    url: 'https://ascension.trizettoconnect.com/tzf/costestimator/uicostestimator/?domainHint=FACETS_APC'
  },
  prod: {
    url: 'https://ascension.trizettoconnect.com/tzf/costestimator/uicostestimator/?domainHint=FACETS_APC'
  }
};


export const maxorLink = {
  dev: {
    url: 'https://ascensionidqa.b2clogin.com/ascensionidqa.onmicrosoft.com/B2C_1A_SAML_signup_signin_Maxor_APC/generic/login?EntityId=urn:saml2:npmaxorcom'
  },
  qa: {
    url: 'https://ascensionidqa.b2clogin.com/ascensionidqa.onmicrosoft.com/B2C_1A_SAML_signup_signin_Maxor_APC/generic/login?EntityId=urn:saml2:npmaxorcom'
  },
  uat: {
    url: 'https://ascensionidqa.b2clogin.com/ascensionidqa.onmicrosoft.com/B2C_1A_SAML_signup_signin_Maxor_APC/generic/login?EntityId=urn:saml2:npmaxorcom'
  },
  prod: {
    url: 'https://id.ascension.org/ascensionid.onmicrosoft.com/B2C_1A_SAML_signup_signin_Maxor_APC/generic/login?EntityId=urn:saml2:maxorcom'
  }
};

export const getEnvVars = (host = window.location.hostname) => {
  switch (true) {
    case host.includes('localhost'):
      return {
        apiUrl: baseApiConfig.dev.url,
        memberKey: baseApiConfig.dev.memberKey,
        providerKey: baseApiConfig.dev.providerKey,
        authConfig: authConfig.dev,
        stripePubKey: stripeConfig.nonProd,
        amplitudeApiKey: amplitudeConfig.nonProd,
        chatTarget: baseApiConfig.dev.chatTarget,
        manageAccountTarget: manageAccountConfig.qa.url,
        chatFeatureFlag: chatFeatureFlag.nonProd.isLiveChatEnabled,
        pharmacyBenefitsFeatureFlag: pharmacyBenefitsFeatureFlag.nonProd.isPharmacyBenefitsTabEnabled,
        priceTransparencyLink: priceTransparencyLink.dev.url,
        maxorLink: maxorLink.dev.url
      };
    case host.includes('-dev'):
      return {
        apiUrl: baseApiConfig.dev.url,
        memberKey: baseApiConfig.dev.memberKey,
        providerKey: baseApiConfig.dev.providerKey,
        authConfig: authConfig.dev,
        stripePubKey: stripeConfig.nonProd,
        amplitudeApiKey: amplitudeConfig.nonProd,
        chatTarget: baseApiConfig.dev.chatTarget,
        manageAccountTarget: manageAccountConfig.qa.url,
        chatFeatureFlag: chatFeatureFlag.dev.isLiveChatEnabled,
        pharmacyBenefitsFeatureFlag: pharmacyBenefitsFeatureFlag.dev.isPharmacyBenefitsTabEnabled,
        priceTransparencyLink: priceTransparencyLink.dev.url,
        maxorLink: maxorLink.dev.url
      };
    case host.includes('-qa'):
      return {
        apiUrl: baseApiConfig.qa.url,
        memberKey: baseApiConfig.qa.memberKey,
        providerKey: baseApiConfig.qa.providerKey,
        authConfig: authConfig.qa,
        stripePubKey: stripeConfig.nonProd,
        amplitudeApiKey: amplitudeConfig.nonProd,
        chatTarget: baseApiConfig.qa.chatTarget,
        manageAccountTarget: manageAccountConfig.qa.url,
        chatFeatureFlag: chatFeatureFlag.qa.isLiveChatEnabled,
        pharmacyBenefitsFeatureFlag: pharmacyBenefitsFeatureFlag.qa.isPharmacyBenefitsTabEnabled,
        priceTransparencyLink: priceTransparencyLink.qa.url,
        maxorLink: maxorLink.qa.url
      };
    case host.includes('-uat'):
      return {
        apiUrl: baseApiConfig.uat.url,
        memberKey: baseApiConfig.uat.memberKey,
        providerKey: baseApiConfig.uat.providerKey,
        authConfig: authConfig.uat,
        stripePubKey: stripeConfig.nonProd,
        amplitudeApiKey: amplitudeConfig.nonProd,
        chatTarget: baseApiConfig.uat.chatTarget,
        manageAccountTarget: manageAccountConfig.qa.url,
        chatFeatureFlag: chatFeatureFlag.uat.isLiveChatEnabled,
        pharmacyBenefitsFeatureFlag: pharmacyBenefitsFeatureFlag.uat.isPharmacyBenefitsTabEnabled,
        priceTransparencyLink: priceTransparencyLink.uat.url,
        maxorLink: maxorLink.uat.url
      };
    case host.includes(
      'member.ascensionpersonalizedcare.com' ||
      'honeybee-web.pub.cloud-03.pcf.ascension.org'
    ):
      return {
        apiUrl: baseApiConfig.prod.url,
        memberKey: baseApiConfig.prod.memberKey,
        authConfig: authConfig.prod,
        stripePubKey: stripeConfig.prod,
        amplitudeApiKey: amplitudeConfig.prod,
        chatTarget: baseApiConfig.prod.chatTarget,
        manageAccountTarget: manageAccountConfig.prod.url,
        chatFeatureFlag: chatFeatureFlag.prod.isLiveChatEnabled,
        pharmacyBenefitsFeatureFlag: pharmacyBenefitsFeatureFlag.prod.isPharmacyBenefitsTabEnabled,
        priceTransparencyLink: priceTransparencyLink.prod.url,
        maxorLink: maxorLink.prod.url
      };
    default:
      console.error('Failed to get env config!');
      return null;
  }
};
