import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStripe } from '@stripe/react-stripe-js';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Paper } from '@material-ui/core';

import { fetchInvoices, fetchReceipts } from '../../../redux/payments/actions';
import { createCheckoutSession } from '../../../api/payments/payments';
import BillActionArea from './Bill/BillActionArea';
import BillDetails from './Bill/BillDetails';
import SetupAutopay from './SetupAutopay';
import AsyncTypography from '../../common/AsyncTypography/AsyncTypography';
import PaymentErrorAlert from './Alerts/PaymentErrorAlert';
import { getDateObj } from '../../../utils/date-utils';
import routes from '../../../constants/routes';
import CallDialog from '../../Support/Dialogs/CallDialog/CallDialog';
import ChatDialog from '../../Support/Dialogs/ChatDialog/ChatDialog';
import { analyticsTrackClickEvent } from '../../../utils/analytics/analytics-utils';

const useStyles = makeStyles(() => ({
  paper: {
    marginBottom: '32px'
  },
  header: {
    padding: '16px',
    fontWeight: 700
  }
}));

const Premium = () => {
  const classes = useStyles();
  const stripe = useStripe();
  const dispatch = useDispatch();
  const { billingSummary } = useSelector(state => state.payments);
  const [paymentError, setPaymentError] = useState(null);
  const [selectedItem, setSelectedItem] = useState({});
  const [isCallDialogOpen, setIsCallDialogOpen] = useState(false);
  const [isChatDialogOpen, setIsChatDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchInvoices());
    dispatch(fetchReceipts());
  }, [dispatch]);

  const invoiceDate = getDateObj(billingSummary.dueDate);

  const handleMakePaymentClicked = async () => {
    analyticsTrackClickEvent('payments_makepaymentbutton_clicked', {eventCategory: 'Payments Pathing'});
    setPaymentError(null);

    const { data, error } = await createCheckoutSession(
      billingSummary.invoiceId,
      billingSummary.netDueAmount,
      `${location.origin}${routes.payment.BASE}`,
      `${location.origin}${routes.payment.BASE}`
    );

    if (error) {
      console.info(error.response.data);
      setPaymentError(
        `Error creating checkout session: ${error.response.data}`
      );
      return;
    }

    const result = await stripe.redirectToCheckout({
      sessionId: data.sessionId
    });

    if (result.error) {
      console.info(result.error.message);
      setPaymentError(
        `Error navigating to checkout session: ${result.error.message}`
      );
    }
  };
  

  const handleCloseDialog = () => {
    setSelectedItem({});
    setIsCallDialogOpen(false);
    setIsChatDialogOpen(false);
  };

  return (
    <Box>
    
      <SetupAutopay bill={billingSummary}/>
      <PaymentErrorAlert
        text={paymentError}
        onClose={() => setPaymentError(null)}
      />
      <Paper className={classes.paper}>
        <AsyncTypography className={classes.header} variant="h6">
          {invoiceDate.longMonth} {invoiceDate.year} Premium Bill
        </AsyncTypography>
        <BillActionArea
          bill={billingSummary}
          onPaymentClick={handleMakePaymentClicked}
        />
        <BillDetails bill={billingSummary} />
      </Paper>

      <CallDialog
        open={isCallDialogOpen}
        onClose={handleCloseDialog}
        phoneNumber={selectedItem.phoneNumber}
        header={selectedItem.dialogHeader}
        // memberId={accountData.memberID}
      />
      <ChatDialog
        open={isChatDialogOpen}
        onClose={handleCloseDialog}
        topic={selectedItem.title}
        type={selectedItem.chatType}
      />
    </Box>
  );
};

export default Premium;
