import React from 'react';
import PropTypes from 'prop-types';
import { Route, useHistory } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import routes from '../../../constants/routes';
import { getUserAgreementStatus } from '../../../utils/storage-utils';
import { loginRequest } from '../../../auth/config';
import { getIsVerified } from '../../../auth/auth-utils';
import PlanTypeCheck from '../PlanTypeCheck/PlanTypeCheck';
import { useAnalyticsPageViewsEvent } from '../../../utils/analytics/hooks/useAnalyticsPageViewsEvent';

const SecureRoute = ({
  component: Component,
  requireUserAgreement,
  requireVerification,
  requirePlanCheck,
  path,
  ...rest
}) => {
  const { inProgress, instance } = useMsal();
  const history = useHistory();
  const acceptedUserAgreement = getUserAgreementStatus();
  const account = instance.getActiveAccount();

  const isAuthenticated = useIsAuthenticated();

  const isVerified = getIsVerified(account);


  const pendingResponse =
    inProgress === InteractionStatus.Startup ||
    inProgress === InteractionStatus.HandleRedirect;

  const secureRender = props => {
    if (isAuthenticated) {
      if (requireUserAgreement && !acceptedUserAgreement) {
        return history.push(routes.USER_AGREEMENT);
      }

      if (requireVerification && !isVerified) {
        return history.push(routes.UNVERIFIED_ACCOUNT);
      }

      return requirePlanCheck ? (
        <PlanTypeCheck
          requireVerification={requireVerification}
          isVerified={isVerified}
          path={path}
        >
          <Component {...props} />
        </PlanTypeCheck>
      ) : (
        <Component {...props} />
      );
    } else if (pendingResponse) {
      return null;
    } else {
      //Attempt Login
      const request = { ...loginRequest, account };

      instance
        .acquireTokenSilent(request)
        .then(() => {
          console.info('Auth Success');
        })
        .catch(() => {
          instance.acquireTokenRedirect(request).then(() => {
            console.info('Auth Success');
          });
        });

      return null;
    }
  };

  useAnalyticsPageViewsEvent();

  return <Route path={path} render={secureRender} {...rest} />;
};

SecureRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  requireUserAgreement: PropTypes.bool,
  requireVerification: PropTypes.bool,
  requirePlanCheck: PropTypes.bool
};

SecureRoute.defaultProps = {
  requireVerification: true,
  requireUserAgreement: true,
  requirePlanCheck: true
};

export default SecureRoute;
