import React from 'react';
import PropTypes from 'prop-types';
import { Container, Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  base: {
    paddingTop: '32px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '16px'
    }
  }
}));

const BasePage = ({ children, maxWidth, fullWidth, ...rest }) => {
  const classes = useStyles();
  return (
    <Container
      maxWidth={fullWidth ? false : maxWidth}
      className={classes.base}
      style={{ padding: fullWidth && 0 }}
    >
      <Box {...rest}>{children}</Box>
    </Container>
  );
};

BasePage.propTypes = {
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  fullWidth: PropTypes.bool
};

BasePage.defaultProps = {
  maxWidth: 'lg',
  fullWidth: false
};

export default BasePage;
