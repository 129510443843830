import constants from './constants';

export const initialState = {
  medicalCard: '',
  medicalCardError: null,
  medicalCardLoading: true,
  pharmacyCard: '',
  pharmacyCardError: null,
  pharmacyCardLoading: true
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.SET_MEDICAL_CARD:
      return {
        ...state,
        medicalCard: payload,
        medicalCardError: null
      };

    case constants.SET_MEDICAL_CARD_ERROR:
      return {
        ...state,
        medicalCardError: payload
      };

    case constants.SET_MEDICAL_CARD_LOADING:
      return {
        ...state,
        medicalCardLoading: payload
      };

    case constants.SET_PHARMACY_CARD:
      return {
        ...state,
        pharmacyCard: payload,
        pharmacyCardError: null
      };

    case constants.SET_PHARMACY_CARD_ERROR:
      return {
        ...state,
        pharmacyCardError: payload
      };

    case constants.SET_PHARMACY_CARD_LOADING:
      return {
        ...state,
        pharmacyCardLoading: payload
      };

    default:
      return state;
  }
};
