import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';
import {
  fetchPlanDetails,
  fetchPlanStatus
} from '../../redux/planDetails/actions';
import { fetchAccountData } from '../../redux/settings/actions';
import MedicalPlanBanner from './MedicalPlanBanner/MedicalPlanBanner';
import MedicalPlanTabs from './MedicalPlanTabs/MedicalPlanTabs';
import PlanStatus from './views/PlanStatus/PlanStatus';
import WhatsCovered from './views/WhatsCovered/WhatsCovered';
import CoveredMembers from './views/CoveredMembers/CoveredMembers';

import routes from '../../constants/routes';
import BasePage from '../BasePage';

const useStyles = makeStyles(theme => ({
  content: {
    padding: '0px 128px',
    [theme.breakpoints.down('md')]: {
      padding: '0px 64px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 32px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px 16px'
    }
  }
}));

const MedicalPlan = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();
  const { plan } = useSelector(state => state.planDetails);
  const { accountData } = useSelector(state => state.settings);

  useEffect(() => {
    dispatch(fetchPlanStatus());
    dispatch(fetchPlanDetails());
    dispatch(fetchAccountData());
  }, [dispatch]);

  return (
    <BasePage fullWidth>
      <Grid item xs={12}>
        <MedicalPlanBanner
          planTitle={accountData.planDescription}
          history={history}
        />
      </Grid>
      <Grid container item xs={12} className={classes.content}>
        <Grid item xs={12}>
          <MedicalPlanTabs
            hasFamilyInPlan={plan.hasFamilyInPlan}
            history={history}
          />
        </Grid>
        <Grid item lg={1}></Grid>
        <Grid item lg={10}>
          <Route
            exact
            path={routes.medical.BASE}
            component={() => <PlanStatus plan={plan} isMobile={isMobile} />}
          />
          <Route path={routes.medical.COVERAGE} component={WhatsCovered} />
          <Route
            path={routes.medical.MEMBERS}
            component={() => <CoveredMembers plan={plan} />}
          />
        </Grid>
        <Grid item lg={1}></Grid>
      </Grid>
    </BasePage>
  );
};

export default MedicalPlan;
