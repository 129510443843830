import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, Divider, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  link: {
    display: 'flex',
    '& > svg': {
      marginRight: '16px',
      color: theme.palette.text.secondary
    }
  },
  nestedMenuLabel: {
    padding: '0px 16px',
    marginTop: '16px'
  }
}));

const NavMenu = ({ anchor, onClose, options }) => {
  const classes = useStyles();
  const subSuffixCheck = useSelector(state => state?.settings?.accountData?.subscriberSuffix) == 0;
  const accountDetailsData = useSelector(state => state?.settings?.accountData);
  const isBillingEligible = accountDetailsData?.isEligible && accountDetailsData?.isPlanEligible; 

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchor}
      keepMounted
      open={Boolean(anchor)}
      onClose={onClose}
      style={{ marginTop: '40px' }}
      PaperProps={{
        style: {
          width: 280
        }
      }}
    >

      {(isBillingEligible && subSuffixCheck) ? options.map((opt, index) =>
        !opt.nestedMenu ? (
          <div key={opt.path}>
            <MenuItem>
              <Link
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
                to={opt.external ? { pathname: opt.path } : opt.path}
                className={classes.link}
                target={opt.external && '_blank'}
                onClick={onClose}
              >
                <opt.Icon />
                <Typography style={{ fontWeight: 600 }}>{opt.label}</Typography>
              </Link>
            </MenuItem>
            {index + 1 !== options.length && <Divider />}
          </div>
        ) : (
          <div key={opt.path}>
            <Typography className={classes.nestedMenuLabel}>
              {opt.label}
            </Typography>
            {opt.nestedMenu.map(n => (
              <MenuItem key={n.path}>
                <Link
                  style={{
                    textDecoration: 'none',
                    color: 'rgba(0, 0, 0, 0.87)'
                  }}
                  to={n.path}
                  className={classes.link}
                  onClick={onClose}
                >
                  <n.Icon />
                  <Typography style={{ fontWeight: 600 }}>{n.label}</Typography>
                </Link>
              </MenuItem>
            ))}
            <Divider />
          </div>
        )
      ) : 
      (
        options
        .filter(opt => opt.label !== 'Payments')
        .map((opt, index) =>
        !opt.nestedMenu ? (
          <div key={opt.path}>
            <MenuItem>
              <Link
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
                to={opt.external ? { pathname: opt.path } : opt.path}
                className={classes.link}
                target={opt.external && '_blank'}
                onClick={onClose}
              >
                <opt.Icon />
                <Typography style={{ fontWeight: 600 }}>{opt.label}</Typography>
              </Link>
            </MenuItem>
            {index + 1 !== options.length && <Divider />}
          </div>
        ) : (
          <div key={opt.path}>
            <Typography className={classes.nestedMenuLabel}>
              {opt.label}
            </Typography>
            {opt.nestedMenu.map(n => (
              <MenuItem key={n.path}>
                <Link
                  style={{
                    textDecoration: 'none',
                    color: 'rgba(0, 0, 0, 0.87)'
                  }}
                  to={n.path}
                  className={classes.link}
                  onClick={onClose}
                >
                  <n.Icon />
                  <Typography style={{ fontWeight: 600 }}>{n.label}</Typography>
                </Link>
              </MenuItem>
            ))}
            <Divider />
          </div>
        )
      )
      )
      }
    </Menu>
  );
};

NavMenu.propTypes = {
  anchor: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  onClose: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
};

export default NavMenu;
