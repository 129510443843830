import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper, Divider, useTheme } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PieChart from '../../../common/PieChart/PieChart';
import { monitaryFormat, removeStringCommas } from '../../../../utils/format-utils';
import PlanStatusChart from '../../../common/PlanStatusChart/PlanStatusChart';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '32px 0px 64px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '0px'
    }
  },
  statusDesc: {
    '& > *': {
      margin: '8px 0px'
    }
  },
  chartContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%'
  },
  chartItem: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  indListHeader: {
    marginTop: '32px',
    alignItems: 'flex-end',
    '& > div': { padding: '0px 16px' }
  },
  indListItem: {
    padding: '8px 0px',
    margin: '8px 0px',
    [theme.breakpoints.down('xs')]: {
      margin: '16px 0px'
    },
    '& > div > div': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  indCellRoot: {
    padding: '0px 16px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      margin: '16px 0px'
    }
  },
  indCellLabel: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: '16px'
    }
  }
}));

const IndividualCell = ({ isMobile, label, color, maxValue, value }) => {
  const classes = useStyles();
  const hasMet = value >= maxValue;

  return (
    <div className={classes.indCellRoot}>
      {hasMet ? (
        <>
          <CheckCircleIcon style={{ color, margin: '8px' }} />
          <div className={classes.indCellLabel}>
            {isMobile && <Typography>{label}</Typography>}
            <Typography style={{ color }}>Met</Typography>
          </div>
        </>
      ) : (
        <>
          <PieChart
            size={24}
            color={color}
            maxValue={parseInt(maxValue, 10)}
            value={parseInt(value || '0', 10)}
          />
          <div className={classes.indCellLabel}>
            {isMobile && <Typography>{label}</Typography>}
            <Typography style={{ color }}>
              {monitaryFormat(removeStringCommas(value))}
              {isMobile && (
                <Typography color="textSecondary" component="span">
                  {' '}
                  of {monitaryFormat(removeStringCommas(maxValue))}{' '}
                </Typography>
              )}
            </Typography>
          </div>
        </>
      )}
    </div>
  );
};

IndividualCell.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  maxValue: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

const PlanStatus = ({ plan, isMobile }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid container className={classes.root}>
      <Grid item md={6} className={classes.statusDesc}>
        <Typography variant="h3">
          {plan.hasFamilyInPlan && 'Family'} Plan Status
        </Typography>
        <Typography>{plan.statusPlanStatus}</Typography>
        <Typography>{plan.description}</Typography>
      </Grid>
      <Grid item md={6} className={classes.chartContainer}>
        {plan.deductableMaxAmount > 0 && (
          <PlanStatusChart
            className={classes.chartItem}
            title="Deductible"
            metLabel="Deductible"
            color={theme.palette.primary.main}
            amountMet={plan.deductableAmountMet}
            maxAmount={plan.deductableMaxAmount}
            hasFamilyInPlan={plan.hasFamilyInPlan}
          />
        )}

        {plan.outOfPocketMaxAmount > 0 && (
          <PlanStatusChart
            className={classes.chartItem}
            title="Out-of-Pocket Maximum"
            metLabel="OOP Max"
            color={theme.palette.primary.dark}
            amountMet={plan.outOfPocketAmountMet}
            maxAmount={plan.outOfPocketMaxAmount}
            hasFamilyInPlan={plan.hasFamilyInPlan}
          />
        )}
      </Grid>
      {plan.hasFamilyInPlan ? (
        <Grid item sm={12} style={{ marginTop: '32px' }}>
          <Typography variant="h4">
            Individual Deductibles and Out-of-Pocket Maximums
          </Typography>
          {!isMobile && (
            <Grid
              container
              className={classes.indListHeader}
              data-testid="individual-list-header"
            >
              <Grid item xs={4}>
                <Typography>
                  <b>Member</b>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  <b>Individual Deductible</b>
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  {monitaryFormat(removeStringCommas(plan.individualMaxDeductable), {
                    includeDecimal: false
                  })}{' '}
                  per person
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  <b>Individual OOP Max</b>
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  {monitaryFormat(removeStringCommas(plan.individualMaxOutOfPocket), {
                    includeDecimal: false
                  })}{' '}
                  per person
                </Typography>
              </Grid>
            </Grid>
          )}
          {plan.individuals.map((member, index) => (
            <Paper key={`plan-member-${index}`} className={classes.indListItem}>
              <Grid container>
                <Grid item xs={12} sm={4} style={{ paddingLeft: '16px' }}>
                  <Typography>
                    {member.firstName} {member.lastName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <IndividualCell
                    isMobile={isMobile}
                    label="Individual Deductible"
                    color={theme.palette.primary.main}
                    maxValue={plan.individualMaxDeductable}
                    value={member.deductableAmountMet}
                  />
                </Grid>
                {isMobile && (
                  <Grid item xs={12} style={{ justifyContent: 'flex-end' }}>
                    <Divider style={{ width: '90%' }} />
                  </Grid>
                )}
                <Grid item xs={12} sm={4}>
                  <IndividualCell
                    isMobile={isMobile}
                    label="Individual OOP Max"
                    color={theme.palette.primary.dark}
                    maxValue={plan.individualMaxOutOfPocket}
                    value={member.outOfPocketAmountMet}
                  />
                </Grid>
              </Grid>
            </Paper>
          ))}
        </Grid>
      ) : null}
    </Grid>
  );
};

PlanStatus.propTypes = {
  plan: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired
};

export default PlanStatus;
