import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tabs,
  Tab
} from '@material-ui/core';
import paymentNavItems from './paymentNavItems';
import { useSelector } from 'react-redux';
import { analyticsTrackClickEvent } from '../../../utils/analytics/analytics-utils';

const useStyles = makeStyles(theme => ({
  list: {
    marginRight: '12px'
  },
  tabs: {
    margin: '16px 0px'
  },
  listItem: {
    borderRadius: '3px',
    '&$selected': {
      backgroundColor: theme.palette.secondary.main,
      color: '#fff',
      '& > div > svg': {
        color: '#fff'
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.main
      }
    }
  },
  selected: {}
}));

const PaymentNav = ({ history, isDesktop }) => {
  const classes = useStyles();
  const subSuffixCheck = useSelector(state => state?.settings?.accountData?.subscriberSuffix) == 0;

  return isDesktop ? (
    <List
      className={classes.list}
      component="nav"
      aria-label="premium history settings"
    >
      {subSuffixCheck ? paymentNavItems.map(i => (
            <ListItem
              key={i.text}
              classes={{ root: classes.listItem, selected: classes.selected }}
              selected={
                history.location.pathname === i.path ||
                history.location.pathname === `${i.path}/`
              }
              onClick={() => {
                if(i.text == 'Manage Autopay') analyticsTrackClickEvent('payments_manageautopay_clicked', {eventCategory: 'Payments Pathing'});
                history.push(i.path);
              }}
              button
            >
              <ListItemIcon>{i.icon}</ListItemIcon>
              <ListItemText primary={i.text} />
            </ListItem>
          )) : paymentNavItems
          .filter( paymentNavItemsNoSettings => paymentNavItemsNoSettings.text !== 'Manage Autopay' )
          .map(i => (
            <ListItem
              key={i.text}
              classes={{ root: classes.listItem, selected: classes.selected }}
              selected={
                history.location.pathname === i.path ||
                history.location.pathname === `${i.path}/`
              }
              onClick={() => {
                if(i.text == 'Manage Autopay') analyticsTrackClickEvent('payments_manageautopay_clicked', {eventCategory: 'Payments Pathing'});
                history.push(i.path);
              }}
              button
            >
              <ListItemIcon>{i.icon}</ListItemIcon>
              <ListItemText primary={i.text} />
            </ListItem>
          ))}
    </List>
  ) : (
    <Tabs
      className={classes.tabs}
      variant="fullWidth"
      indicatorColor="secondary"
      textColor="secondary"
      aria-label="nav tabs"
      value={history.location.pathname}
      onChange={(_, value) => history.push(value)}
    >
      {subSuffixCheck ? paymentNavItems.map(i => (
            <Tab
              key={i.text}
              component="button"
              label={i.text}
              value={i.path}
            />
          )) : paymentNavItems
          .filter( paymentNavItemsNoSettings => paymentNavItemsNoSettings.text !== 'Manage Autopay' )
          .map(i => <Tab key={i.text} component='button' label={i.text} value={i.path} />)}
    </Tabs>
  );
};

PaymentNav.propTypes = {
  history: PropTypes.object.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  flags: PropTypes.object
};

export default PaymentNav;
