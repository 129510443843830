import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Typography,
  Popover,
  FormControl,
  OutlinedInput,
  IconButton,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  filter: {
    width: '400px',
    height: '360px',
    padding: '24px 24px 32px 24px',
    [theme.breakpoints.down('xs')]: {
      width: '360px',
      height: '328px'
    }
  },
  inputGrp: {
    width: '352px',
    height: '48px',
    marginTop: '16px',
    marginBottom: '32px',
    [theme.breakpoints.down('xs')]: {
      width: '328px',
      height: '48px'
    }
  },
  popoverButton: {
    margin: '24px',
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const ClaimsFilterPopover = ({ anchorEl, handleClose, handleFilter }) => {
  const classes = useStyles();
  const [claimNumber, setClaimNumber] = useState('');
  const [orderByDate, setOrderByDate] = useState('desc');

  const onFliter = () => {
    handleFilter(claimNumber, orderByDate);
    handleClose();
  };

  const onClose = () => {
    setClaimNumber('');
    setOrderByDate('desc');
    handleClose();
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <div className={classes.filter}>
        <Typography variant="h5">Claim Number</Typography>
        <FormControl className={classes.inputGrp} size="small">
          <OutlinedInput
            id="claim-number-input"
            placeholder="Enter a claim number"
            value={claimNumber}
            onChange={e => setClaimNumber(e.target.value)}
            endAdornment={
              <InputAdornment>
                <IconButton>
                  <CloseIcon onClick={() => setClaimNumber('')} />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Typography variant="h5">Order By</Typography>
        <FormControl component="fieldset">
          <RadioGroup
            name="orderByDate"
            value={orderByDate}
            onChange={e => setOrderByDate(e.target.value)}
          >
            <FormControlLabel
              value="desc"
              checked={orderByDate === 'desc'}
              control={<Radio />}
              label="Newest to Oldest"
            />
            <FormControlLabel
              value="asc"
              checked={orderByDate === 'asc'}
              control={<Radio />}
              label="Oldest to Newest"
            />
          </RadioGroup>
        </FormControl>
        <div className={classes.popoverButton}>
          <Button
            color="primary"
            onClick={onClose}
            style={{
              marginRight: '24px'
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={onFliter}>
            Filter
          </Button>
        </div>
      </div>
    </Popover>
  );
};

ClaimsFilterPopover.propTypes = {
  anchorEl: PropTypes.element,
  handleClose: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired
};

export default ClaimsFilterPopover;
