import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PaymentSourceItem from './PaymentSourceItem';
import DeletePaymentSourceErrorModal from './modals/DeletePaymentSourceErrorModal';
import DeletePaymentSourceModal from './modals/DeletePaymentSourceModal';
import AddIcon from '@material-ui/icons/Add';
import AddCreditCardSection from '../../../AutoPay/common/AddCreditCardSection';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAutopayConfiguration, updateAutopaySetupOn } from '../../../../redux/autopay/actions';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { memberApi } from '../../../../config/axios-config';
import genericServiceReducer from '../../../../redux/common/genericServiceReducer';


const useStyles = makeStyles(() => ({
    sectionWrapper: {
        backgroundColor: '#ffffff',
        borderRadius: 4,
        padding: 30
    },
    sectionWrapperActive: {
        backgroundColor: '#ffffff',
        border: '1px solid #E8EAEC',
        boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.07), 0px 1px 10px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: 4,
        padding: 30
    },
    sectionTitle: {
        fontFamily: 'HCo Whitney SSm',
        fontSize: 24,
        fontWeight: '600',
        margin: '10px 10px 10px 0px'
    },
    cardDetails: {
        '& p': {
            fontWeight: 500,
            fontSize: 18,
            margin: 0,
            marginLeft: 10,
            '& span': {
                fontWeight: 700,
            }
        }
    },
    cardSubText: {
        color: 'rgba(0, 0, 0, 0.4)',
        marginLeft: 10,
        fontSize: 18
    },
    primaryLink: {
        color: 'rgba(30, 105, 210, 1)',
        textTransform: 'none',
        textDecoration: 'underline',
        fontSize: 16
    },
    maskWrapper: {
        width: '100%',
        opacity: '0.5',
        background: '#f3f3f3',
        height: '100%',
        position: 'absolute',
        top: '0',
        zIndex: '1'
    },
    cancelButton: {
        color: 'rgba(30, 105, 210, 1)',
        textTransform: 'none',
        textDecoration: 'underline',
        fontSize: 16,
        marginLeft: 5
    }
}));


const PaymentSourceSection = ({ autopaySetupEnable, paymentSourceData, paymentSourceIsLoading, paymentSourceError, autopayConfig, addNewCardError, setAddNewCardError, addCardActive, setAddCardActive, maxPaymentMethodExceededThreshold, maxPaymentMethodAllowed, setMaxPaymentMethodExceededThreshold, setMaxPaymentMethodAllowed }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [sourceList, setSourceList] = React.useState(paymentSourceData);
    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const [selectedItemIndex, setSelectedItemIndex] = React.useState('');
    const [selectedItem, setSelectedItem] = React.useState('');
    const cardIdFromConfig = autopayConfig?.method?.id;
    const updateAutopayData = useSelector(state => state?.autopay?.updateAutopay?.success);
    const updateAutopayIsLoading = useSelector(state => state?.autopay?.updateAutopay?.loading);
    const updateAutopayError = useSelector(state => state?.autopay?.updateAutopay?.error);
    const [currentItem, setCurrentItem] = React.useState('');

    React.useEffect(() => {
        setSourceList(paymentSourceData);
    }, [paymentSourceData]);

    React.useEffect(() => {
        if (!updateAutopayError && updateAutopayData?.id) {
            dispatch(fetchAutopayConfiguration());
        }
    }, [updateAutopayData]);

    const handleMakeDefault = (cardId, autopaySetupEnable, addCardActive) => {
        if (!autopaySetupEnable || addCardActive) return;
        dispatch(updateAutopaySetupOn(cardId));
    };

    const handleDeletePaymentSource = (selectedIndex, data, isDefault, autopaySetupEnable, addCardActive) => {
        if (!autopaySetupEnable || addCardActive) return;
        if (isDefault) {
            setOpenErrorModal(true);
        } else {
            setOpenDeleteModal(true);
            setSelectedItemIndex(selectedIndex);
            setSelectedItem(data);
        }
    };

    const [deleteCardReducerState, deleteCardReducerDispatch] = React.useReducer(genericServiceReducer, {
        status: 'idle',
        data: null,
        error: null
    });

    const handleConfirmDelete = (stripePaymentMethodId) => {
        deleteCardReducerDispatch({ type: 'started' });
        memberApi
            .delete(
                `/v2/my/payments/methods/${stripePaymentMethodId}`
            )
            .then((res) => {
                deleteCardReducerDispatch({ type: 'success', data: res.data });
                dispatch(fetchAutopayConfiguration());
            })
            .catch(err => deleteCardReducerDispatch({ type: 'error', error: err }));
    };

    const deleteCardIsLoading = deleteCardReducerState?.status === 'pending';
    const deleteCardError = deleteCardReducerState?.error;
    const deleteCardSuccess = deleteCardReducerState?.data;

    const [paymentSourceReducerState, paymentSourceReducerDispatch] = React.useReducer(genericServiceReducer, {
        status: 'idle',
        data: null,
        error: null
    });

    React.useEffect(() => {
        if (deleteCardSuccess?.jsonData?.id) {
            paymentSourceReducerDispatch({ type: 'started' });
            memberApi
                .get('/v2/my/payments/methods?type=CARD')
                .then(res => {
                    paymentSourceReducerDispatch({ type: 'success' });
                    setSourceList(res?.data?.paymentMethods);
                    setMaxPaymentMethodExceededThreshold(res?.data?.maxPaymentMethodExceededThreshold);
                    setMaxPaymentMethodAllowed(res?.data?.maxPaymentMethodAllowed);
                    setCurrentItem('');
                })
                .catch(err => paymentSourceReducerDispatch({ type: 'error', error: err }));
        }
    }, [deleteCardSuccess]);

    const updatedPaymentSourceIsLoading = paymentSourceReducerState?.status === 'pending';
    const updatedPaymentSourceError = paymentSourceReducerState?.error;

    const handleErrorModalClose = () => {
        setOpenErrorModal(false);
    };

    const handleDeleteModalClose = () => {
        setOpenDeleteModal(false);
        setCurrentItem('');
    };

    const handleAddNewCardButton = () => {
        setAddCardActive(true);
    };

    const handleCancel = () => {
        setAddCardActive(false);
    };


    return (
        <Box>
            <Box className={autopaySetupEnable ? classes.sectionWrapperActive : classes.sectionWrapper}>
                <Box display="flex" alignItems="center" justifyContent="flex-start">
                    <Typography className={classes.sectionTitle} variant='h5'>Payment source</Typography>
                </Box>
                <Box mt={3}>
                    {paymentSourceError && <Box mt={2}>
                        <Alert severity="error">
                            We were unable to fetch the information. Please try again.
                            </Alert>
                    </Box>}

                    {paymentSourceIsLoading && <Box mt={2} display="flex" justifyContent="center" alignItems="center">
                        <CircularProgress style={{ color: '#c7c7c7' }} />
                    </Box>}

                    <Box style={{ position: 'relative' }}>
                        {sourceList && sourceList.map((item, index) => item && <PaymentSourceItem
                            paymentSourceData={paymentSourceData}
                            autopaySetupEnable={autopaySetupEnable}
                            updateAutopayError={updateAutopayError}
                            key={index}
                            index={index}
                            data={item}
                            handleMakeDefault={handleMakeDefault}
                            handleDeletePaymentSource={handleDeletePaymentSource}
                            addCardActive={addCardActive}
                            updateAutopayIsLoading={updateAutopayIsLoading}
                            cardIdFromConfig={cardIdFromConfig}
                            deleteCardIsLoading={deleteCardIsLoading}
                            deleteCardError={deleteCardError}
                            paymentSourceIsLoading={updatedPaymentSourceIsLoading}
                            paymentSourceError={updatedPaymentSourceError}
                            currentItem={currentItem}
                            setCurrentItem={setCurrentItem}
                        />)}
                        <Box className={addCardActive && classes.maskWrapper}></Box>
                    </Box>

                    {maxPaymentMethodExceededThreshold && <Box mt={4}>
                        <Alert severity="error">
                            {maxPaymentMethodAllowed ? `You have added the maximum of ${maxPaymentMethodAllowed} cards to your account. You can remove one of your current cards to add a new one.` : 'You have added the maximum of cards to your account. You can remove one of your current cards to add a new one.'}
                        </Alert>
                    </Box>}

                    <Box mt={3}>
                        {(!addCardActive || addNewCardError) && <Button disabled={maxPaymentMethodExceededThreshold} onClick={handleAddNewCardButton} className={classes.primaryLink} size="small" startIcon={<AddIcon />}>add new card</Button>}
                        {addCardActive && <Box style={{ border: '3px solid rgba(92, 91, 91, 1)', padding: 20 }}>
                            <Box display='flex' justifyContent='space-between' alignItems='flex-start'>
                                <Typography variant='h6' style={{ fontSize: 18, fontWeight: 600, marginBottom: 30 }}>Add new credit or debit card information</Typography>
                                <Button className={classes.cancelButton} size="small" onClick={handleCancel}>Cancel</Button>
                            </Box>
                            <Box style={{ width: '74%' }}><AddCreditCardSection setAddNewCardError={setAddNewCardError} setAddCardActive={setAddCardActive} /></Box>
                        </Box>}
                    </Box>

                    {addNewCardError && <Box mt={2}>
                        <Alert severity="error">
                            We were unable to initiate Add new card. Please try again.
                            </Alert>
                    </Box>}

                </Box>
            </Box>
            <DeletePaymentSourceErrorModal handleClose={handleErrorModalClose} open={openErrorModal} setOpen={setOpenErrorModal} />
            {selectedItem && <DeletePaymentSourceModal selectedItemIndex={selectedItemIndex} selectedItem={selectedItem} handleConfirmDelete={handleConfirmDelete} handleClose={handleDeleteModalClose} open={openDeleteModal} setOpen={setOpenDeleteModal} />}
        </Box>
    );
};

PaymentSourceSection.propTypes = {
    autopaySetupEnable: PropTypes.bool,
    paymentSourceData: PropTypes.array,
    paymentSourceIsLoading: PropTypes.bool,
    paymentSourceError: PropTypes.any,
    autopayConfig: PropTypes.any,
    addNewCardError: PropTypes.obj,
    setAddNewCardError: PropTypes.func,
    addCardActive: PropTypes.bool,
    setAddCardActive: PropTypes.func,
    maxPaymentMethodExceededThreshold: PropTypes.bool,
    maxPaymentMethodAllowed: PropTypes.any,
    setMaxPaymentMethodExceededThreshold: PropTypes.func,
    setMaxPaymentMethodAllowed: PropTypes.func
};

export default PaymentSourceSection;
