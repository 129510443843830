import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  Container,
  Typography,
  Hidden,
  Collapse,
  Divider,
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ascensionLogo from '../../assets/ascen_logo_wht.png';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ReactComponent as FacebookIcon } from '../../assets/facebook_logo.svg';
import TwitterIcon from '@material-ui/icons/Twitter';
import { ReactComponent as LinkedInIcon } from '../../assets/linkedin_logo.svg';
import YoutubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import routes from '../../constants/routes';
import { analyticsTrackClickEvent, analyticsTrackEvent } from '../../utils/analytics/analytics-utils';

const useStyles = makeStyles(theme => ({
  footer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: '#fff',
    backgroundColor: '#1E69D2',
    padding: '20px'
  },
  brand: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    color: '#fff',
    float: 'left',
    width: 'auto',
    textDecoration: 'none',
    '& > h4': {
      margin: 0,
      fontSize: '20px'
    }
  },
  cursor_typography: {
    margin: '5px 0 5px 0',
    cursor: 'pointer'
  },
  typography: {
    margin: '10px 0'
  },
  footerHeader: {
    display: 'flex',
    margin: '1rem 0 2rem 0',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'start',
      marginTop: '2rem'
    }
  },
  footerBody: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '1rem',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'end',
      marginTop: '2rem'
    }
  },
  footerBodySectionTitle: {
    fontSize: '.75rem',
    fontWeight: '600',
    [theme.breakpoints.up('lg')]: {
      marginBottom: '10px',
      fontWeight: '700'
    }
  },
  footerBodySection: {
    display: 'flex',
    flexDirection: 'column',
    '&:not(:last-child)': {
      marginRight: '4rem'
    }
  },
  collapseView: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    color: '#fff',
    padding: '1rem'
  },
  divider: {
    height: '1px',
    backgroundColor: '#fff',
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'rgba(255,255,255,0.2)',
      margin: '1rem 0 2rem 0'
    }
  },
  collapseHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  collapseIcon: {
    fontSize: '2rem',
    cursor: 'pointer'
  },
  collapseBody: {
    display: 'flex',
    flexDirection: 'column'
  },
  footerLink: {
    margin: '10px 0',
    color: 'white',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  footerFooter: {
    margin: '.5rem 0',
    [theme.breakpoints.down('sm')]: {
      margin: '.5rem 0 1rem 0',
      textAlign: 'center'
    }
  },
  footerSocialButtons: {
    margin: '4rem 0 1rem 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'start',
      margin: '0'
    }
  },
  footerSocialButton: {
    color: '#fff',
    border: '1px solid #fff',
    borderRadius: '50%',
    height: '40px',
    width: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      height: '27px',
      width: '27px',
      fontSize: '.5rem'
    },
    '&:not(:last-child)': {
      marginRight: '1rem'
    },
    '&:hover': {
      backgroundColor: '#fff',
      color: '#1E69D2'
    }
  },
  footerSocialButtonIcon: {
    maxWidth: '1.5rem',
    maxHeight: '1.5rem',
    display: 'inline-block',
    fontSize: '1.5rem',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    flexShrink: '0',
    userSelect: 'none',
    [theme.breakpoints.up('md')]: {
      maxWidth: '1rem',
      maxHeight: '1rem'
    }
  },
  languageAssistance: {
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '.5rem',
    [theme.breakpoints.up('md')]: {
      marginTop: '0',
      justifyContent: 'end'
    }
  },
  legalLink: {
    fontSize: '12px',
    color: 'white',
    fontWeight: '600',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  disclaimer: {
    marginBottom: '2rem'
  }
}));

const Footer = () => {
  const classes = useStyles();
  const [resourcesCollapse, setResourcesCollapse] = useState(false);
  const [contactusCollapse, setContactusCollapse] = useState(false);

  const MoreAtAscension = () => (
    <>
      <a
        className={classes.footerLink}
        href="https://www.ascensionpersonalizedcare.com/about-us#About%20Us"
        onClick={() => {
          analyticsTrackClickEvent('footer_aboutus_clicked', {eventCategory: 'Footer Pathing'});
        }}
      >
        About Us
      </a>
      <a
        className={classes.footerLink}
        href="https://www.ascensionpersonalizedcare.com/contact-us"
        onClick={() => {
          analyticsTrackClickEvent('footer_contactus_clicked', {eventCategory: 'Footer Pathing'});
        }}
      >
        Contact Us
      </a>
      <a
        className={classes.footerLink}
        href="https://ascension.org"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          analyticsTrackClickEvent('footer_ascensionorg_clicked', {eventCategory: 'Footer Pathing'});
        }}
      >
        Ascension.org
      </a>
      <a
        className={classes.footerLink}
        href="https://www.ascensionpersonalizedcare.com/"
        onClick={() => {
          analyticsTrackClickEvent('footer_home_clicked', {eventCategory: 'Footer Pathing'});
        }}
      >
        Home
      </a>
      <a
        className={classes.footerLink}
        href="https://www.ascensionpersonalizedcare.com/sitemap#Sitemap"
        onClick={() => {
          analyticsTrackClickEvent('footer_sitemap_clicked', {eventCategory: 'Footer Pathing'});
          analyticsTrackEvent('footer_sitemap_viewed', {eventCategory: 'Footer Pathing'});
        }}
      >
        Sitemap
      </a>
    </>
  );

  const ContactInformation = () => (
    <>
      <Typography variant="body2" classes={{ root: classes.typography }}>
        Ascension Personalized Care
      </Typography>
      <Typography variant="body2" classes={{ root: classes.typography }}>
        PO Box 1707
      </Typography>
      <Typography variant="body2" classes={{ root: classes.typography }}>
        Troy, MI 48099
      </Typography>
      <a
        style={{ marginTop: '2rem' }}
        className={classes.footerLink}
        href="tel:+8336001311"
        onClick={() => {
          analyticsTrackEvent('footer_supportphone_clicked', {eventCategory: 'Footer Pathing'});
        }}
      >
        833-600-1311
      </a>
        <a className={classes.footerLink} href="mailto:apcsupport@ascension.org">
        apcsupport@ascension.org
      </a>
    </>
  );

  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <Grid container>
          {/* Logo */}
          <Grid className={classes.footerHeader} item xs={12} lg={6}>
            <Link to="/" className={classes.brand}>
              <img src={ascensionLogo} height="45px" alt="brand" />
            </Link>
          </Grid>
          {/* Mobile, tablet layout */}
          <Hidden initialWidth="md" mdUp>
            <Grid item xs={12}>
              <Divider classes={{ root: classes.divider }} />
              {/* More at Ascension collapse */}
              <div
                className={classes.collapseView}
                onClick={() => setResourcesCollapse(!resourcesCollapse)}
              >
                <Collapse in={resourcesCollapse} collapsedHeight={30}>
                  <div className={classes.collapseHeader}>
                    <Typography
                      component="h1"
                      className={classes.footerBodySectionTitle}
                    >
                      MORE AT ASCENSION
                    </Typography>
                    <ExpandMoreIcon
                      style={{
                        transform: resourcesCollapse ? 'rotate(180deg)' : 'none'
                      }}
                      className={classes.collapseIcon}
                    />
                  </div>
                  <div className={classes.collapseBody}>
                    <MoreAtAscension />
                  </div>
                </Collapse>
              </div>
              <Divider classes={{ root: classes.divider }} />
              {/* Contact Information collapse */}
              <div
                className={classes.collapseView}
                onClick={() => setContactusCollapse(!contactusCollapse)}
              >
                <Collapse in={contactusCollapse} collapsedHeight={30}>
                  <div className={classes.collapseHeader}>
                    <Typography
                      component="h1"
                      className={classes.footerBodySectionTitle}
                    >
                      CONTACT INFORMATION
                    </Typography>
                    <ExpandMoreIcon
                      style={{
                        transform: contactusCollapse ? 'rotate(180deg)' : 'none'
                      }}
                      className={classes.collapseIcon}
                    />
                  </div>
                  <div className={classes.collapseBody}>
                    <ContactInformation />
                  </div>
                </Collapse>
              </div>
              <Divider classes={{ root: classes.divider }} />
            </Grid>
          </Hidden>
          {/* Large screen layout */}
          <Hidden initialWidth="md" smDown>
            <Grid className={classes.footerBody} item md={12} lg={6}>
              <div className={classes.footerBodySection}>
                {/* More at Ascension */}
                <Typography
                  variant="body1"
                  className={classes.footerBodySectionTitle}
                >
                  MORE AT ASCENSION
                </Typography>
                <MoreAtAscension />
              </div>
              {/* Contact Information */}
              <div className={classes.footerBodySection}>
                <Typography
                  variant="body1"
                  className={classes.footerBodySectionTitle}
                >
                  CONTACT INFORMATION
                </Typography>
                <ContactInformation />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Divider classes={{ root: classes.divider }} />
            </Grid>
          </Hidden>
          {/* Social buttons */}
          <Grid item xs={12} md={6} className={classes.footerSocialButtons}>
            {/* Facebook */}
            <a
              href="https://www.facebook.com/ascensionpersonalizedcare"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.footerSocialButton}
              onClick={() => {
                analyticsTrackClickEvent('footer_facebook_clicked', {eventCategory: 'Footer Pathing'});
              }}
            >
              <FacebookIcon className={classes.footerSocialButtonIcon} />
            </a>
            {/* Twitter */}
            <a
              href="https://twitter.com/ascpersonalized"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.footerSocialButton}
              onClick={() => {
                analyticsTrackClickEvent('footer_twitter_clicked', {eventCategory: 'Footer Pathing'});
              }}
            >
              <TwitterIcon className={classes.footerSocialButtonIcon} />
            </a>
            {/* LinkedIn */}
            <a
              href="https://www.linkedin.com/company/ascensionpersonalizedcare"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.footerSocialButton}
              onClick={() => {
                analyticsTrackClickEvent('footer_linkedin_clicked', {eventCategory: 'Footer Pathing'});
              }}
            >
              <LinkedInIcon className={classes.footerSocialButtonIcon} />
            </a>
            {/* YouTube */}
            <a
              href="https://www.youtube.com/channel/UCnXM-Ml3eN8NRcSy3f4_9Pw"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.footerSocialButton}
              onClick={() => {
                analyticsTrackClickEvent('footer_youtube_clicked', {eventCategory: 'Footer Pathing'});
              }}
            >
              <YoutubeIcon className={classes.footerSocialButtonIcon} />
            </a>
            {/* Instagram */}
            <a
              href="https://www.instagram.com/ascensionpersonalizedcare"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.footerSocialButton}
              onClick={() => {
                analyticsTrackClickEvent('footer_instagram_clicked', {eventCategory: 'Footer Pathing'});
              }}
            >
              <InstagramIcon className={classes.footerSocialButtonIcon} />
            </a>
          </Grid>
          {/* Terms, Privacy, Members, Language */}
          <Grid item xs={12} md={6} className={classes.languageAssistance}>
            <a
              className={classes.legalLink}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.ascensionpersonalizedcare.com/-/media/project/aca/aca/pdfs/ascension-personalized-care-language-services.pdf"
              onClick={() => {
                analyticsTrackClickEvent('footer_languageassist_clicked', {eventCategory: 'Footer Pathing'});
              }}
            >
              Language Assistance
            </a>
          </Grid>
          <Grid item xs={12} className={classes.footerFooter}>
            <a
              className={classes.legalLink}
              href="https://www.ascensionpersonalizedcare.com/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                analyticsTrackClickEvent('footer_terms_clicked', {eventCategory: 'Footer Pathing'});
                analyticsTrackEvent('footer_terms_viewed', {eventCategory: 'Footer Pathing'});
              }}
            >
              Terms and Conditions
            </a>{' '}
            &nbsp;|&nbsp;&nbsp;
            <Link className={classes.legalLink} to={routes.PRIVACY_POLICY} onClick={() => {
              analyticsTrackEvent('footer_privacypolicy_viewed', {eventCategory: 'Footer Pathing'});
            }}>
              Privacy Policy
            </Link>{' '}
            &nbsp;|&nbsp;&nbsp;
            <a
              className={classes.legalLink}
              href="https://www.ascensionpersonalizedcare.com/members-rights"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                analyticsTrackClickEvent('footer_membersrights_clicked', {eventCategory: 'Footer Pathing'});
              }}
            >
              Members Rights
            </a>
          </Grid>
          <Grid item xs={12} className={classes.disclaimer}>
            <Typography
              variant="body2"
              color="inherit"
              style={{ fontSize: '12px' }}
            >
              Ascension Personalized Care benefits are underwritten by the US
              Health and Life Insurance Company. Copyright © 2022 Ascension. All
              rights reserved. Neither Ascension, its affiliates, or its
              employees or agents authorize, approve, make provisions for, or
              establish any of the conditions necessary for any procedure judged
              to be morally wrong by the Catholic Church. Nor is Ascension
              directly involved with, contributes to, or receives funds from the
              provision of procedures judged to be morally wrong by the Catholic
              Church.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default withRouter(Footer);
