import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import check_circle from '../../../assets/check_circle.png';
import FormLabel from '@material-ui/core/FormLabel';
import AddCreditCardSection from './AddCreditCardSection';
import AddIcon from '@material-ui/icons/Add';
import { getCardIcon } from './getCardIcon';
import { memberApi } from '../../../config/axios-config';
import genericServiceReducer from '../../../redux/common/genericServiceReducer';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles(() => ({
    sectionWrapper: {
        backgroundColor: '#ffffff',
        border: '1px solid #E8EAEC',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: 4,
        padding: 30
    },
    sectionTitle: {
        fontFamily: 'HCo Whitney SSm',
        fontSize: 22,
        fontWeight: '600',
        marginBottom: 10
    },
    radioHelperText: {
        marginLeft: 30,
        marginTop: -7,
        fontSize: '1rem',
        fontFamily: 'HCo Whitney SSm',
        fontWeight: 500,
        lineHeight: 1.5
    },
    buttonWrapper: {
        marginTop: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right',
        '& button': {
            padding: '16px 41.5px'
        }
    },
    previewWrapper: {
        padding: '20px 10px 20px 30px',
        backgroundColor: '#ffffff',
        border: '1px solid #E8EAEC',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& img': {
            marginRight: 12
        }
    },
    titleBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    infoBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& p': {
            fontSize: 15,
            color: 'rgba(0, 0, 0, 0.87)'
        },
        '& button': {
            color: 'rgba(30, 105, 210, 1)',
            textTransform: 'none',
            textDecoration: 'underline',
            fontSize: 16,
            marginLeft: 5
        },
    },
    previewTitle: {
        fontFamily: 'HCo Whitney SSm',
        fontSize: 16,
        fontWeight: '600'
    },
    subTitle: {
        fontFamily: 'HCo Whitney SSm',
        fontSize: 18,
        fontWeight: '600'
    },
    customLabel: {
        cursor: 'pointer',
        color: 'rgba(0, 0, 0, 0.87)'
    },
    customLabelWrapper: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '& img': {
            marginRight: 7,
            width: 42,
            height: 26
        }
    },
    primaryLink: {
        color: 'rgba(30, 105, 210, 1)',
        textTransform: 'none',
        textDecoration: 'underline',
        fontSize: 16
    },
}));



const PaymentSourceSection = ({ expand, setExpand, setSaveStatus, saveStatus, setActiveCard, setActiveCardLast4 }) => {
    const classes = useStyles();
    const [selectedCard, setSelectedCard] = React.useState('');
    const [addCardActive, setAddCardActive] = React.useState('');
    const [paymentSourceList, setPaymentSourceList] = React.useState([]);
    const [selectedCardInfo, setSelectedCardInfo] = React.useState('');
    const [addNewCardError, setAddNewCardError] = React.useState(false);
    const [maxPaymentMethodExceededThreshold, setMaxPaymentMethodExceededThreshold] = React.useState(false);
    const [maxPaymentMethodAllowed, setMaxPaymentMethodAllowed] = React.useState('');

    const handleEdit = () => {
        setExpand(true);
        setSaveStatus(false);
    };

    const handleRadioChange = (event, last4) => {
        if (addCardActive) setAddCardActive(false);
        setSelectedCard(event.target.value);
        setSelectedCardInfo(last4);
    };

    const handleSave = () => {
        setExpand(false);
        setSaveStatus(true);
        setActiveCard(selectedCard);
        setActiveCardLast4(selectedCardInfo);
    };

    const handleAddNewCardButton = () => {
        setSelectedCard('');
        setAddCardActive(true);
    };

    const [paymentSourceReducerState, paymentSourceReducerDispatch] = React.useReducer(genericServiceReducer, {
        status: 'idle',
        data: null,
        error: null
    });

    const paymentSourceIsLoading = paymentSourceReducerState?.status === 'pending';
    const paymentSourceError = paymentSourceReducerState?.error;

    React.useEffect(() => {
        paymentSourceReducerDispatch({ type: 'started' });
        memberApi
            .get('/v2/my/payments/methods?type=CARD')
            .then(res => {
                paymentSourceReducerDispatch({ type: 'success' });
                setPaymentSourceList(res?.data?.paymentMethods);
                setMaxPaymentMethodExceededThreshold(res?.data?.maxPaymentMethodExceededThreshold);
                setMaxPaymentMethodAllowed(res?.data?.maxPaymentMethodAllowed?.toString());
            })
            .catch(err => paymentSourceReducerDispatch({ type: 'error', error: err }));
    }, []);

    React.useEffect(() => {
        if ((addCardActive === false) && !addNewCardError) {
            paymentSourceReducerDispatch({ type: 'started' });
            memberApi
                .get('/v2/my/payments/methods?type=CARD')
                .then(res => {
                    paymentSourceReducerDispatch({ type: 'success' });
                    setPaymentSourceList(res?.data?.paymentMethods);
                    setMaxPaymentMethodExceededThreshold(res?.data?.maxPaymentMethodExceededThreshold);
                    setMaxPaymentMethodAllowed(res?.data?.maxPaymentMethodAllowed?.toString());
                })
                .catch(err => paymentSourceReducerDispatch({ type: 'error', error: err }));
        }
    }, [addCardActive]);

    if (!expand) {
        return (
            <Box className={classes.previewWrapper}>
                <Box className={classes.titleBox} display="flex" alignItems="center" justifyContent="center">
                    {saveStatus && <img src={check_circle} />}
                    <Typography className={classes.previewTitle} variant='h5'>2. Payment source</Typography>

                </Box>
                {saveStatus && <Box className={classes.infoBox} display="flex" alignItems="center" justifyContent="center">
                    <p>{`Card ending *****${selectedCardInfo}`}</p>
                    <Button size="small" onClick={handleEdit}>Edit</Button>
                </Box>}
            </Box>
        );
    } else {

        return (
            <Box>
                <Box className={classes.sectionWrapper}>
                    <Typography className={classes.sectionTitle} variant='h5'>2. Payment source</Typography>
                    <Typography variant='p' style={{ color: 'rgba(0, 0, 0, 0.56)' }}>
                        Once enrolled in autopay, we’ll automatically debit/charge the bank account or credit card you’ve chosen. You can change this method anytime.
                </Typography>

                    <Box mt={4}>
                        <Box mt={3} mb={2}>
                            <Typography variant='h6' className={classes.subTitle}>
                                Credit or debit card information
                        </Typography>
                            <Typography variant='p' style={{ color: 'rgba(0, 0, 0, 0.56)' }}>
                                Select an existing payment method, or add a new payment method below.
                        </Typography>
                        </Box>

                        {paymentSourceError && <Box mt={2}>
                            <Alert severity="error">
                                We were unable to fetch the information. Please try again.
                            </Alert>
                        </Box>}

                        {paymentSourceIsLoading && <Box mt={2} display="flex">
                            <CircularProgress style={{ color: '#c7c7c7' }} />
                        </Box>}

                        {!paymentSourceError && paymentSourceList && paymentSourceList.map((item, index) => {
                            const { brand, last4 } = item?.jsonData?.card;
                            const { id } = item?.jsonData;
                            return (
                                <Box key={index}>
                                    <FormLabel id='payment-source-existing' className={classes.customLabelWrapper}>
                                        <Radio
                                            checked={selectedCard === id}
                                            onChange={(e) => handleRadioChange(e, last4)}
                                            value={id}
                                            name="payment-source-existing"
                                            color='primary'
                                        />
                                        <img src={getCardIcon(brand)} /><span className={classes.customLabel}>{`card ending *****${last4}`}</span></FormLabel>
                                </Box>
                            );
                        })}

                        {maxPaymentMethodExceededThreshold && <Box mt={2}>
                            <Alert severity="error">
                                {maxPaymentMethodAllowed ? `You have reached the maximum limit of ${maxPaymentMethodAllowed} cards to be added to your account.` : 'You have reached the maximum limit of cards to be added to your account.'}
                            </Alert>
                        </Box>}

                        <Box mt={3}>
                            {(!addCardActive || addNewCardError) && <Button disabled={maxPaymentMethodExceededThreshold} onClick={handleAddNewCardButton} className={classes.primaryLink} size="small" startIcon={<AddIcon />}>add new card</Button>}
                            {addCardActive && <Box mt={3} style={{ width: '97%', marginLeft: 20 }}><AddCreditCardSection setAddNewCardError={setAddNewCardError} setAddCardActive={setAddCardActive} /></Box>}
                        </Box>
                    </Box>

                    {addNewCardError && <Box mt={2}>
                        <Alert severity="error">
                            We were unable to initiate Add new card. Please try again.
                            </Alert>
                    </Box>}

                    {!addCardActive && <Box className={classes.buttonWrapper}>
                        <Button variant="contained" color="primary" disabled={!selectedCard} onClick={handleSave}>
                            Next
                    </Button>
                    </Box>}
                </Box>
            </Box>
        );
    }
};

PaymentSourceSection.propTypes = {
    expand: PropTypes.bool,
    setExpand: PropTypes.func,
    setSaveStatus: PropTypes.func,
    setValidateCard: PropTypes.func,
    validateCard: PropTypes.bool,
    saveStatus: PropTypes.bool,
    setActiveCard: PropTypes.func,
    setActiveCardLast4: PropTypes.func
};

export default PaymentSourceSection;
