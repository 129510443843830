import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  Avatar,
  Hidden,
  Button,
  Box
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAccountData } from '../../../redux/settings/actions';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import { getEnvVars } from '../../../utils/env-utils';
import { analyticsTrackClickEvent } from '../../../utils/analytics/analytics-utils';


const useStyles = makeStyles(theme => ({
  paper: {
    padding: '16px',
    marginBottom: '20px',
    display: 'flex'
  },
  avatar: {
    margin: '10px 16px 0 0',
    width: '62px',
    height: '62px',
    color: theme.palette.primary.dark,
    backgroundColor: '#E8F5FE',
    [theme.breakpoints.down('md')]: {
      width: '48px',
      height: '48px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '40px',
      height: '40px'
    }
  },
  contentView: {
    width: '100%',
    marginTop: '10px'
  },
  dataRowView: {
    display: 'flex',
    margin: '20px 0 10px 0'
  },
  inlineGrp: {
    width: '50%'
  },
  titleContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  buttonView: {
    display: 'flex'
  },
  mobileContentView: {
    width: '100%'
  },
  mobileAvatar: {
    height: '40px',
    width: '40px',
    margin: '10px 16px 0 0'
  },
  mobileTitleContent: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px'
  },
  mobileDataView: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 0 10px 0'
  }
}));

const AccountCard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const initialState = {
    addressLine1: '',
    city: '',
    dob: '',
    email: '',
    firstName: '',
    formattedAddress: '',
    genderDesc: '',
    lastName: '',
    phone: '',
    zipCode: ''
  };

  const [accountData, setAccountData] = useState(initialState);
  const accountDataResponse = useSelector(store => store.settings.accountData);
  const { manageAccountTarget } = getEnvVars(window.location.hostname);

  useEffect(() => {
    dispatch(fetchAccountData());
  }, [dispatch]);

  useEffect(() => {
    setAccountData({ ...initialState, ...accountDataResponse });
  }, [accountDataResponse]);

  return (
    <>
      <Hidden initialWidth="lg" xsDown>
        <Box pb={3}>
          <Paper>
            <Box p={3}>
              <Box display="flex" pb={2}>
                <Box style={{ width: 86 }}>
                  <Avatar className={classes.avatar}>
                    {accountData.firstName.charAt(0)}
                  </Avatar>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  flexGrow="1"
                >
                  <div className={classes.titleContent}>
                    <Typography variant="h5">
                      {`${accountData.firstName} ${accountData.lastName}`}
                    </Typography>
                  </div>

                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<EditIcon />}
                    href={manageAccountTarget}
                    target="_blank"
                    onClick={() => {
                      analyticsTrackClickEvent('settings_account_manage_clicked', {eventCategory: 'Account Settings Pathing'});
                    }}
                  >
                    MANAGE ACCOUNT
                  </Button>
                </Box>
              </Box>
              <Box display="flex">
                <Box style={{ width: 86 }}></Box>
                <Box flexGrow="1">
                  <div className={classes.contentView}>
                    <div className={classes.dataRowView}>
                      <div className={classes.inlineGrp}>
                        <Typography variant="h6">Date of Birth</Typography>
                        <Typography variant="body2">
                          {moment(accountData.birthDate).format('MM/DD/YYYY')}
                        </Typography>
                      </div>
                      <div className={classes.inlineGrp}>
                        <Typography variant="h6">Phone</Typography>
                        <Typography variant="body2">
                          {accountData.phone}
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.dataRowView}>
                      <div className={classes.inlineGrp}>
                        <Typography variant="h6">Email</Typography>
                        <Typography variant="body2">
                          {accountData.email}
                        </Typography>
                      </div>
                      <div className={classes.inlineGrp}>
                        <Typography variant="h6">Address</Typography>
                        <Typography variant="body2">
                          {accountData.addressLine1}
                        </Typography>
                        <Typography variant="body2">
                          {accountData.city} {accountData.state},{' '}
                          {accountData.zipCode}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Hidden>
      <Hidden initialWidth="lg" smUp>
        <Paper className={classes.paper}>
          <div className={classes.mobileContentView}>
            <div className={classes.mobileTitleContent}>
              <Avatar className={classes.avatar}>
                {accountData.firstName.charAt(0)}
              </Avatar>
              <Typography variant="h5">{`${accountData.firstName} ${accountData.lastName}`}</Typography>
            </div>
            <div className={classes.mobileDataView}>
              <Typography variant="h6">Date of Birth</Typography>
              <Typography variant="body2">
                {moment(accountData.birthDate).format('MM/DD/YYYY')}
              </Typography>
            </div>
            <div className={classes.mobileDataView}>
              <Typography variant="h6">Phone</Typography>
              <Typography variant="body2">{accountData.phone}</Typography>
            </div>
            <div className={classes.mobileDataView}>
              <Typography variant="h6">Email</Typography>
              <Typography variant="body2">{accountData.email}</Typography>
            </div>
            <div className={classes.mobileDataView}>
              <Typography variant="h6">Address</Typography>
              <Typography variant="body2">
                {accountData.addressLine1}
              </Typography>
              <Typography variant="body2">
                {accountData.city} {accountData.state}, {accountData.zipCode}
              </Typography>
            </div>
          </div>
        </Paper>
      </Hidden>
    </>
  );
};

export default AccountCard;
