import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import AsyncTypography from '../../../common/AsyncTypography/AsyncTypography';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  rightAlign: {
    textAlign: 'right',
    minWidth: '80px'
  },
  leftOffest: {
    marginLeft: '64px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0
    }
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 0px',
    width: '100%'
  },
  icon: {
    position: 'absolute',
    marginLeft: '-60px',
    color: 'rgba(0, 0, 0, 0.54)',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }
}));

const BillLineItem = ({
  Icon,
  label,
  leadingText,
  trailingText,
  labelColor,
  trailingColor,
  isNegativeValue = false
}) => {
  const classes = useStyles();

  return (
    <div className={`${classes.root} ${classes.leftOffest}`}>
      {Icon && <Icon className={classes.icon} />}
      <div className={classes.details}>
        <Typography style={{ color: labelColor }}>{label}</Typography>
        <AsyncTypography
          variant="caption"
          className={classes.rightAlign}
          skeletonProps={{ className: classes.rightAlign }}
        >
          {isNegativeValue && '- '}
          {leadingText}
          <span style={{ color: trailingColor }}>{trailingText}</span>
        </AsyncTypography>
      </div>
    </div>
  );
};

BillLineItem.propTypes = {
  Icon: PropTypes.object,
  label: PropTypes.string,
  leadingText: PropTypes.string,
  trailingText: PropTypes.string,
  labelColor: PropTypes.string,
  trailingColor: PropTypes.string,
  isNegativeValue: PropTypes.bool
};

export default BillLineItem;
