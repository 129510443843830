import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import assetImage from '../../assets/active_asset_white.png';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 18, 12, 18),
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 3, 7, 3)
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2)
    }
  },
  container: {
    backgroundColor: '#702F8A',
    padding: theme.spacing(5, 3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(5, 2, 3, 2)
    }
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      paddingTop: theme.spacing(2)
    }
  },
  title: {
    color: 'white',
    fontSize: '34px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '29px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
      marginBottom: theme.spacing(2)
    }
  },
  yellow: {
    color: '#f7d76d'
  },
  content: {
    color: 'white',
    fontSize: '14px',
    maxWidth: '500px',
    marginBottom: theme.spacing(4)
  },
  button: {
    background: 'white',
    color: '#702F8A',
    textTransform: 'initial',
    fontSize: '18px',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  text: {
    fontSize: '29px',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '21px'
    }
  },
  image: {
    width: '186px',
    [theme.breakpoints.down('sm')]: {
      width: '120px'
    }
  }
}));

const ActiveFooter = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        className={classes.container}
        container
        justify="space-between"
        data-testid="active-direct-footer"
      >
        <Grid item xs={12} sm={9}>
          <Typography className={classes.title}>
            Commit to Fit <span className={classes.yellow}>From Anywhere</span>
          </Typography>
          <Typography className={classes.content}>
            Get substantial discounts on 16,000+ standard and premium fitness
            memberships and access 4,000+ workout videos on demand.
          </Typography>
          <Button
            variant="contained"
            size="large"
            className={classes.button}
            href="https://www.activeandfitdirect.com/fitness/AF793077AS"
            target="__blank"
            rel="noopener"
          >
            Get Started!
          </Button>
        </Grid>
        <Grid item xs={12} sm={3} className={classes.imageWrapper}>
          <img
            src={assetImage}
            alt="Active Asset Footer"
            className={classes.image}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ActiveFooter;
