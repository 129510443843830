import { Button, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import backgroundImage from '../../assets/doctor_with_patient_banner.png';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    [theme.breakpoints.down('xs')]: {
      background: 'none'
    }
  },
  content: {
    padding: theme.spacing(5, 9, 8, 16),
    backgroundColor: '#FAFAFA',
    maxWidth: '550px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '311px',
      padding: theme.spacing(3, 7, 8, 3)
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      padding: theme.spacing(2, 2, 0, 2)
    }
  },
  title: {
    fontSize: '45px',
    fontFamily: 'Chronicle Text G1',
    [theme.breakpoints.down('sm')]: {
      fontSize: '34px'
    }
  },
  subtitle: {
    color: 'rgba(0,0,0,0.6)',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3)
    }
  },
  button: {
    fontSize: '18px',
    color: 'white',
    background: '#1E69D2',
    textTransform: 'initial',
    width: '176px',
    height: '48px',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  text: {
    color: '#1E69D2',
    fontSize: '29px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px'
    }
  }
}));

const TruHearingBanner = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography className={classes.title}>TruHearing</Typography>
        <Typography className={classes.subtitle}>
          Hearing aid discount program for Ascension Personalized Care Members.
        </Typography>
        <Button
          variant="contained"
          size="large"
          className={classes.button}
          href="https://ascension-hs.truhearing.com/"
          target="__blank"
          rel="noopener"
        >
          Learn More
        </Button>
      </div>
    </div>
  );
};

export default TruHearingBanner;
