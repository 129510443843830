import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { getCardIcon } from '../../../AutoPay/common/getCardIcon';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles(() => ({
    sourceItem: {
        backgroundColor: '#fff',
        border: '1px solid #E2E4E5',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
        padding: 20,
        marginBottom: 20
    },
    sourceItemActive: {
        backgroundColor: '#fff',
        border: '3px solid #00806D',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
        padding: 20,
        marginBottom: 20
    },
    dangerLink: {
        color: 'rgba(198, 23, 29, 1)',
        textTransform: 'none',
        textDecoration: 'underline',
        fontSize: 16
    },
    primaryLink: {
        color: 'rgba(30, 105, 210, 1)',
        textTransform: 'none',
        textDecoration: 'underline',
        fontSize: 16
    },
    cardInfoSection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
            width: 82,
            height: 51
        }
    },
    cardInfoSectionMobile: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
            width: '30%',
            height: 51
        }
    },
    cardDetails: {
        '& p': {
            fontWeight: 500,
            fontSize: 18,
            margin: 0,
            marginLeft: 10,
            '& span': {
                fontWeight: 700,
            }
        }
    },
    cardSubText: {
        color: 'rgba(0, 0, 0, 0.4)',
        marginLeft: 10,
        fontSize: 18
    },
    defaultPaymentActive: {
        marginTop: 20,
        padding: 10,
        backgroundColor: '#00806D',
        color: '#ffffff',
        textTransform: 'uppercase',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        letterSpacing: '1px'
    },
    defaultPaymentActiveIcon: {
        color: '#ffffff',
        marginRight: 10,
        fontSize: 14
    }
}));

const PaymentSourceItem = ({ data, index, handleMakeDefault, handleDeletePaymentSource, autopaySetupEnable, addCardActive, cardIdFromConfig, updateAutopayIsLoading, updateAutopayError, deleteCardIsLoading, deleteCardError, paymentSourceIsLoading, paymentSourceError, currentItem, setCurrentItem }) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const { brand, last4, exp_month, exp_year } = data?.jsonData?.card;
    const cardId = data?.jsonData?.id;
    const isDefault = !!(cardId === cardIdFromConfig);
    const configIsLoading = useSelector(state => state?.autopay?.loading);
    const isLoading = !!((currentItem === index) && (updateAutopayIsLoading || configIsLoading));
    const deleteIsLoading = !!((currentItem === index) && deleteCardIsLoading);

    const onHandleMakeDefault = () => {
        setCurrentItem(index);
        handleMakeDefault(cardId, autopaySetupEnable, addCardActive);
    };

    const onDeleteCard = () => {
        setCurrentItem(index);
        handleDeletePaymentSource(index, data, isDefault, autopaySetupEnable, addCardActive);
    };

    React.useEffect(() => {
        if (isDefault) setCurrentItem('');
    }, [isDefault]);

    const classes = useStyles();
    return (
        <Box className={isDefault ? classes.sourceItemActive : classes.sourceItem}>
            <Box display="flex" justifyContent="space-between" alignItems={isDesktop ? 'flex-start' : 'center'}>
                <Box className={isDesktop ? classes.cardInfoSection : classes.cardInfoSectionMobile}>
                    <img src={getCardIcon(brand)} />
                    <Box className={classes.cardDetails}>
                        <p><span>{brand}</span> ending in {last4}</p>
                        <span className={classes.cardSubText}>Expires: {`${exp_month}/${exp_year}`}</span>
                    </Box>
                </Box>
                <Button
                    onClick={onDeleteCard}
                    className={classes.dangerLink}
                    size="small"
                    disabled={deleteIsLoading || isLoading || paymentSourceIsLoading}
                >
                    <span>Delete</span>
                    {deleteIsLoading && (
                        <CircularProgress
                            size="16px"
                            style={{ marginLeft: '10px' }}
                        />
                    )}
                </Button>
            </Box>
            {isDefault ? <Box className={classes.defaultPaymentActive}>
                <CheckCircleOutlineIcon className={classes.defaultPaymentActiveIcon} /> <span>Default Payment Method</span>
            </Box> :
                <Box mt={2}><Button
                    onClick={onHandleMakeDefault}
                    className={classes.primaryLink} size="small"
                    disabled={isLoading || deleteIsLoading || paymentSourceIsLoading}
                >
                    <span>Make Default</span>
                    {isLoading && (
                        <CircularProgress
                            size="16px"
                            style={{ marginLeft: '10px' }}
                        />
                    )}

                </Button></Box>}

            {!!((currentItem === index)) && updateAutopayError && <Box mt={2}>
                <Alert severity="error">
                    We were unable to update the information. Please try again.
                </Alert>
            </Box>}

            {!!((currentItem === index)) && deleteCardError && <Box mt={2}>
                <Alert severity="error">
                    We were unable to delete the card. Please try again.
                </Alert>
            </Box>}

            {!!((currentItem === index)) && paymentSourceError && <Box mt={2}>
                <Alert severity="error">
                    We were unable to update the information. Please try again.
                </Alert>
            </Box>}
        </Box>
    );
};

PaymentSourceItem.propTypes = {
    autopaySetupEnable: PropTypes.bool,
    handleDeletePaymentSource: PropTypes.func,
    handleMakeDefault: PropTypes.func,
    addCardActive: PropTypes.bool,
    index: PropTypes.number,
    data: PropTypes.any,
    cardIdFromConfig: PropTypes.string,
    updateAutopayIsLoading: PropTypes.bool,
    updateAutopayError: PropTypes.obj,
    deleteCardIsLoading: PropTypes.bool,
    deleteCardError: PropTypes.obj,
    paymentSourceIsLoading: PropTypes.bool,
    paymentSourceError: PropTypes.obj,
    setCurrentItem: PropTypes.func,
    currentItem: PropTypes.any
};

export default PaymentSourceItem;