import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@material-ui/core/Button';
import Typography from '@mui/material/Typography';
import Cancel from '@material-ui/icons/Cancel';
import { Dialog, makeStyles } from '@material-ui/core';
import Autopaypdf from '../../../assets/AutoPayTerms.pdf';
import StoredCredsTerms from '../../../assets/StoredCredTerms.pdf';
import PropTypes from 'prop-types';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const useStyles = makeStyles(theme => ({
  title: {
    width: '90%',
    height: 'auto',
    fontFamily: 'HCo Whitney SSm',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '22px',
    lineHeight: '24px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '21px'
    },

    letterSpacing: '0.5px'
  },
  flexstyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'relative'
  },

  textbox: {
    padding: '24px',
    width: '100%',
    height: '328px',
    overflowY: 'scroll',
    background: ' #FFFFFF',
    margin: '20px 0',
    overflowX: 'hidden',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '4px'
  },

  closeButton: {
    position: 'absolute',
    top: '0',
    right: '0',
    color: 'rgba(0, 0, 0, 0.6)'
  },
  button: {
    padding: '16px',
    background: '#1E69D2',
    color: 'white',
    textTransform: 'upperCase'
  },
  disableButton: {
    padding: '16px',
    background: ' rgba(0, 0, 0, 0.5)',
    color: 'white',
    textTransform: 'upperCase'
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '34px'
  },
  content: {
    fontFamily: 'HCo Whitney SSm',
    fontWeight: '600',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: 'rgba(0, 0, 0, 0.6)'
  },
  contentHeading: {
    fontFamily: 'HCo Whitney SSm',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    margin: 0
  },
  label: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    margin: 0
  },

  atag: {
    textDecoration: 'none',
    color: '#1E69D2',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '24px'
  },
  buttonSetupAutopay: {
    width: '300px',
    marginTop: 26
  },

  contentHeader: {
    display: 'flex',
    fontFamily: 'HCo Whitney SSm',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    margin: 0
  },
  contentBulletPoints: {
    color: 'black'
  }
}));

export default function AutopayAgreementPop({ handleAutopayChange, open, handleClose }) {
  const [disable, setDisable] = React.useState(false);
  const handleChange = e => {
    let isChecked = e.target.checked;
    setDisable(isChecked);
  };

  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='md'
      >
        <DialogTitle>
          <Box className={classes.flexstyle}>
            <label className={classes.title}>
              Stored Credential Consent – General Terms and Automatic Payment
              Agreement
              </label>
            <Cancel
              className={classes.closeButton}
              onClick={() => handleClose()}
            ></Cancel>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box component="div" className={classes.textbox}>
              <Typography>
                <label className={classes.contentHeader}>
                  Stored Credential Consent – General Terms (Effective as of
                  October 18th, 2022)
                </label>
                <label className={classes.contentHeader}></label>
                <p className={classes.content}>
                  This Stored Credential Consent – General Terms (“Consent
                  Agreement”) is necessary for Ascension Personalized Care and
                  its third-party service providers to store your payment card
                  details, which is a requirement if you want to set up
                  automatic payments or have your card information stored for
                  other purchases through our systems. Automatic payments may be
                  useful to you because if you do not pay your premiums on time,
                  you will not have coverage. While Ascension Personalized Care
                  may make automatic payments available, you are responsible for
                  meeting payment dates (please see the Automatic Payment Credit
                  Card Authorization for details). Your payment card information
                  will be stored securely, and payments will be remitted in a
                  secure manner, in accordance with all applicable laws and
                  industry standards. To store your information, your consent is
                  needed below. We recommend that you Print a copy of this
                  Consent Agreement for your records. You agree to this Consent
                  Agreement by clicking “accept” where indicated.
                </p>
                <ul className={classes.content}>
                  <li>
                    <div className={classes.content}>
                      <b>Consent: </b>
                      You acknowledge Ascension Personalized Care or its
                      third-party service providers will store your credit card
                      information, including but not limited to, payment card
                      number, expiration date, and associated contact
                      information, to process future transactions and you are
                      hereby providing consent for such storage and use. When
                      you leave our website or use the services of those service
                      providers, you are subject to the terms of such service
                      providers that are applicable to their end customers.
                    </div>
                  </li>
                  <li>
                    <div className={classes.content}>
                      <b>Future transactions: </b>
                      Future transactions are charges related to your Ascension
                      Personalized Care policy plan premiums.
                    </div>
                  </li>
                  <li>
                    <div className={classes.content}>
                      <b>Amount Charged: </b>
                      Your stored card will be used for member-initiated charges
                      related to Ascension Personalized Care policy plan
                      premiums, or upon agreement to autopay of premiums, you
                      will be charged based on policy terms and rates to the
                      default payment option on file per the AutomaticPayment
                      Agreement.
                    </div>
                  </li>
                  <li>
                    <div className={classes.content}>
                      <b>Notification: </b>
                      You understand that Ascension Personalized Care will
                      notify you via the email address associated with your
                      account or via text message to the phone number associated
                      with your account in the event of any changes to this
                      Consent Agreement. The Consent Agreement will remain in
                      full force and effect until you have informed Ascension of
                      your desire to terminate this Consent Agreement or your
                      account has been deactivated.
                    </div>
                  </li>
                  <li>
                    <div className={classes.content}>
                      <b>Use of the Stored Credential:</b>
                      Your stored payment card number will be used only to
                      process future payments as described in this Consent
                      Agreement. The use of other information that may be
                      contained within the stored credential such as your name
                      and email address will only be used as specified in our
                      Privacy Policy at
                      <a
                        className={classes.atag} target="blank"
                        href="https://www.ascensionpersonalizedcare.com/privacy-policy"
                      >
                        {' '}
                        https://www.ascensionpersonalizedcare.com/privacy-policy.
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className={classes.content}>
                      <b>Cancellation Policies:</b>
                      You may cancel consent or change payment methods or
                      contact information at any time by logging in to our
                      Ascension Personalized Care portal and modifying the
                      applicable information or selections.
                    </div>
                  </li>
                  <li>
                    <div className={classes.content}>
                      <b>Refunds or Disputes:</b>
                      If you believe a charge has been made in error contact us
                      immediately at (833) 600-1311 or by email at
                        <a
                          className={classes.atag}
                          href="mailto:apcsupport@ascension.org"
                        >
                          {' '}
                        apcsupport@ascension.org.{' '}
                        </a>
                    </div>
                  </li>
                </ul>
                <label className={classes.contentHeader}>
                  Automatic Payment Agreement (Effective Date: 10/18/2022)
                </label>
                <br />
                <label className={classes.contentHeader}>
                  Automatic Payment Credit Card Authorization
                </label>
                <p className={classes.content}>
                  You hereby authorize Ascension Personalized Care to initiate
                  Automatic Recurring payments to your default payment card on
                  the scheduled charge dates for the policy plan premium amounts
                  due. The charges will begin with the next scheduled payment
                  date. You understand that payments with automatic charge dates
                  on a Saturday, Sunday or holiday may not be processed until
                  the following business day. You also understand and agree the
                  amounts are determined by the policy plan.
                </p>
                <p className={classes.content}>
                  The Automatic Payment will occur automatically on its
                  regularly scheduled date of the month regardless of any
                  extended due date required by state law. If you wish to change
                  either the date or amount of the automatic withdrawal, you
                  must make changes within the Ascension Personalized Care
                  portal at least 48 hours prior to the automatic withdrawal
                  date.
                </p>
                <p className={classes.content}>
                  Automatic Recurring Payments authorization may be canceled at
                  any time by logging in to your account and editing your
                  settings.
                </p>
                <label className={classes.contentHeader}>
                  Policy Cancellation(s)
                </label>
                <p className={classes.content}>
                  If a cancellation notice for non-payment of premium for
                  policy(ies) attached to the default credit card occurs,
                  Ascension Personalized Care will stop the Automatic Payment
                  and it will be the member’s responsibility to initiate payment
                  for the total payment due including both the amount past due,
                  the current amount due, and any associated fees
                </p>
                <p className={classes.content}>
                  If Ascension Personalized Care receives a request to cancel
                  the policy(ies) attached to the default payment card, please
                  check the status of the outstanding billing statement at that
                  time. Ascension Personalized Care will discontinue future Automatic
                  Payment once we process the request to cancel the policy(ies);
                  however, it is possible that an Automatic Payment may be
                  processed about the same time as the policy(ies)
                  cancellation(s).
                </p>
                <p className={classes.content}>
                  You authorize any refund due to you to be applied to your
                  default payment card; and if necessary, Ascension Personalized
                  Care may electronically debit or credit your default payment
                  card to correct any transactions.
                </p>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>

        <DialogActions sx={{ padding: '0 20px 20px 20px' }}>
          <Box>
            <input
              type="checkbox"
              id="checkBox"
              name="checkBox"
              onChange={e => handleChange(e)}
            />
            <label htmlFor="checkBox" className={classes.label}>
              By clicking accept, I agree to be bound by the
                <a
                className={classes.atag}
                href={StoredCredsTerms}
                rel="noopener noreferrer"
                target="_blank"
              >
                {' '}
                  Stored Credental Consent-General Terms{' '}
              </a>
                and
                <a
                className={classes.atag}
                href={Autopaypdf}
                rel="noopener noreferrer"
                target="_blank"
              >
                {' '}
                  Automatic Payment Agreement.
                </a>
            </label>
          </Box>
          <Box className={classes.buttonBox}>
            <Button
              variant='contained'
              color='primary'
              onClick={() => handleAutopayChange()}
              disabled={!disable}
            >
              Accept
              </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );

}
AutopayAgreementPop.propTypes = {
  handleAutopayChange: PropTypes.func,
  open: PropTypes.bool,
  handleClose: PropTypes.func
};

