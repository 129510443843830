import { removeStringCommas } from '../../utils/format-utils';
import constants from './constants';

export const initialState = {
  plan: {
    name: '',
    status: '',
    statusPlanStatus: '',
    description: '',
    hasFamilyInPlan: false,
    deductableAmountMet: 0,
    deductableMaxAmount: 0,
    outOfPocketAmountMet: 0,
    outOfPocketMaxAmount: 0,
    individualMaxDeductable: 0,
    individualMaxOutOfPocket: 0,
    individuals: []
  },
  rxPlan: {},
  rxCost: {},
  medicalCoverage: [],
  coverage: {},
  error: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.SET_PLAN_STATUS: {
      const planInfo = payload.planInfo || {};

      return {
        ...state,
        plan: {
          ...state.plan,
          headFirstName: planInfo.firstName,
          headLastName: planInfo.lastName,
          name: planInfo.groupName,
          planType: payload.planType,
          statusPlanStatus: planInfo.plans[0].status,
          description:
            planInfo.description || 'Your plan status is currently unavailable.'
        }
      };
    }

    case constants.SET_PLAN_DETAILS: {
      const { status, plan, individualPlan } = payload.plans[0];
      const hasFamilyInPlan = individualPlan.length > 1;

      return {
        ...state,
        plan: {
          ...state.plan,
          hasFamilyInPlan,
          status,
          deductableAmountMet: parseFloat(removeStringCommas(plan.amtMet), 10),
          deductableMaxAmount: parseFloat(removeStringCommas(plan.dedAmt), 10),
          outOfPocketAmountMet: parseFloat(removeStringCommas(plan.outOfPocketMaxAmtMet), 10),
          outOfPocketMaxAmount: parseFloat(removeStringCommas(plan.outOfPocketAmt), 10),
          individualMaxDeductable:
            hasFamilyInPlan && parseFloat(removeStringCommas(individualPlan[0].memDedAmt), 10),
          individualMaxOutOfPocket:
            hasFamilyInPlan &&
            parseFloat(removeStringCommas(individualPlan[0].memOutOfPocketAmt), 10),
          individuals:
          individualPlan &&
            individualPlan.map(ind => ({
              firstName: ind.firstName,
              lastName: ind.lastName,
              deductableAmountMet: parseFloat(removeStringCommas(ind.memAmtMet), 10),
              outOfPocketAmountMet: parseFloat(removeStringCommas(ind.memoutofPocketMaxAmtMet), 10)
            }))
        }
      };
    }

    case constants.SET_PHARMACY_PLAN: {
      return {
        ...state,
        rxPlan: { ...payload }
      };
    }

    case constants.SET_MEDICAL_PLAN_COVERAGE: {
      const medicalCoverage = payload.benefits
        .map(b => ({
          name: b.description || '',
          ...b.services[0],
          benefitSummary:
            (b.services[0].benefitSummary &&
              b.services[0].benefitSummary.replace(/\n/g, '')) ||
            ''
        }))
        .filter(
          coverageItem =>
            coverageItem.benefitSummary &&
            coverageItem.benefitSummary.length > 0 &&
            coverageItem.name &&
            coverageItem.name.length > 0
        );

      return {
        ...state,
        medicalCoverage
      };
    }

    case constants.SET_PHARMACY_PLAN_OUT_OF_POCKET_COST: {
      return {
        ...state,
        rxCost: { ...payload }
      };
    }

    case constants.SET_COVERAGES: {
      const coverage = (payload && payload.find(c => c.type === 'M')) || {};

      return {
        ...state,
        coverage
      };
    }

    case constants.SET_PLAN_ERROR:
      return {
        ...state,
        error: payload
      };
    default:
      return state;
  }
};
