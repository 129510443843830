import React from 'react';
import ReactDOM from 'react-dom';
import App from './App/App';
import initAuth from './auth/initAuth';
import './index.css';
import { initAnalytics } from './utils/analytics/analytics-utils';
import { getEnvVars } from './utils/env-utils';


    const msalInstance = initAuth();

    /** ANALYTICS INTEGRATION :: Amplitude analytics implementation - initializating right after login **/

const { amplitudeApiKey } = getEnvVars();
initAnalytics(amplitudeApiKey, msalInstance);


    ReactDOM.render(<App pca={msalInstance} />, document.getElementById('root'));