export const HEALTH_TILES = {
  'State-of-the-Art Technology': [
    'The latest technology from top hearing aid manufacturers',
    'Hearing solutions for virtually every type of hearing loss',
    'Significantly lower prices on the same models sold at retail locations'
  ],
  'Personalized Care': [
    'Guidance and assistance from a TruHearing hearing consultant',
    'Local, professional care from an accredited provider in your area',
    'A $45 hearing exam plus 1-year of follow-up visits for fitting and adjustments'
  ],
  'Help Along the Way': [
    'A worry-free purchase with a 60-day risk free trial and 3-year warranty',
    '80 free batteries per aid included with non-rechargeable models',
    'Guides to help you adapt to your new hearing aids'
  ]
};

export const SAVINGS_TABLE_HEADERS = [
  {
    field: 'name',
    value: 'Sample Product',
    primary: true
  },
  {
    field: 'retailPrice',
    value: 'Avg. Regtail Price'
  },
  {
    field: 'truHearingPrice',
    value: 'TruHearing Price',
    highlight: true
  },
  {
    field: 'savings',
    value: 'Savings',
    hideOnMobile: true
  }
];

export const SAVINGS_TABLE_DATA = [
  {
    name: 'TruHearing Advanced',
    retailPrice: '$2,445',
    truHearingPrice: '$1,250',
    savings: '$1,195'
  },
  {
    name: 'Starkey® Livio™ 1000 R',
    retailPrice: '$1,795',
    truHearingPrice: '$975',
    savings: '$820'
  },
  {
    name: 'Phonak® Audéo® M30-R',
    retailPrice: '$1,972',
    truHearingPrice: '$1,250',
    savings: '$722'
  },
  {
    name: 'ReSound Quattro™ 5',
    retailPrice: '$2,427',
    truHearingPrice: '$1,370',
    savings: '$1,057'
  },
  {
    name: 'Oticon Opn® S 3',
    retailPrice: '$2,454',
    truHearingPrice: '$1,425',
    savings: '$1,029'
  },
  {
    name: 'Widex® Evoke® 330',
    retailPrice: '$2,965',
    truHearingPrice: '$1,725',
    savings: '$1,240'
  },
  {
    name: 'Signia Styletto Nx® 7',
    retailPrice: '$3,449',
    truHearingPrice: '$2,195',
    savings: '$1,254'
  }
];
