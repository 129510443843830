import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import check_circle from '../../../assets/check_circle.png';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAccountData } from '../../../redux/settings/actions';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const useStyles = makeStyles(() => ({
    sectionWrapper: {
        backgroundColor: '#ffffff',
        border: '1px solid #E8EAEC',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: 4,
        padding: 30
    },
    sectionTitle: {
        fontFamily: 'HCo Whitney SSm',
        fontSize: 22,
        fontWeight: '600',
        marginBottom: 10
    },
    radioHelperText: {
        marginLeft: 30,
        marginTop: -7,
        fontSize: '1rem',
        fontFamily: 'HCo Whitney SSm',
        fontWeight: 500,
        lineHeight: 1.5
    },
    buttonWrapper: {
        marginTop: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right',
        '& button': {
            padding: '16px 41.5px'
        }
    },
    previewWrapper: {
        padding: '20px 10px 20px 30px',
        backgroundColor: '#ffffff',
        border: '1px solid #E8EAEC',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& img': {
            marginRight: 12
        }
    },
    previewWrapperMobile: {
        padding: '20px 10px 20px 30px',
        backgroundColor: '#ffffff',
        border: '1px solid #E8EAEC',
        borderRadius: 4,
        display: 'block',
        alignItems: 'unset',
        justifyContent: 'unset',
        '& img': {
            marginRight: 12
        }
    },
    titleBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    infoBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& p': {
            fontSize: 15,
            color: 'rgba(0, 0, 0, 0.87)'
        },
        '& button': {
            color: 'rgba(30, 105, 210, 1)',
            textTransform: 'none',
            textDecoration: 'underline',
            fontSize: 16,
            marginLeft: 5
        },
    },
    previewTitle: {
        fontFamily: 'HCo Whitney SSm',
        fontSize: 16,
        fontWeight: '600'
    },
    sectionHelperText: {
        maxWidth: 600,
        color: 'rgba(0, 0, 0, 0.56)'
    },
    darkText: {
        color: 'rgba(0, 0, 0, 0.87)'
    },
    link: {
        color: 'rgba(30, 105, 210, 1)',
        cursor: 'pointer'
    },
    customFormControlLabel: {
        alignItems: 'flex-start',
        '& > span': {
            padding: 0,
            marginRight: 15,
            '& > span': {
                marginLeft: 10
            }
        }
    }
}));

const CommunicationSection = ({ expand, setExpand, setSaveStatus, saveStatus, setCommunication }) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const classes = useStyles();
    const [radioValue, setRadioValue] = React.useState('');
    const { accountData } = useSelector(state => state.settings);
    const dispatch = useDispatch();

    const handleEdit = () => {
        setExpand(true);
        setSaveStatus(false);
    };

    const handleRadioChange = (event) => {
        setRadioValue(event.target.value);
    };

    const handleSave = () => {
        setExpand(false);
        setSaveStatus(true);
        setCommunication(radioValue);
    };

    const maskEmail = (email) => {
        const [name, domain] = email.split('@');
        const maskedName = name[0] + '*****';
        const maskedEmail = maskedName + '@' + domain;
        return maskedEmail;
    };

    React.useEffect(() => {
        dispatch(fetchAccountData());
    }, []);

    if (!expand) {
        return (
            <Box className={isDesktop ? classes.previewWrapper: classes.previewWrapperMobile}>
                <Box className={classes.titleBox} display="flex" alignItems="center" justifyContent="center">
                    {saveStatus && <img src={check_circle} />}
                    <Typography className={classes.previewTitle} variant='h5'>3. Email communications</Typography>

                </Box>
                {saveStatus && <Box className={classes.infoBox} display="flex" alignItems="center" justifyContent="center">
                    {radioValue == 'yes' ? <p>Yes, send email communications</p> :
                        <p>No, don’t send email communications</p>}
                    <Button size="small" onClick={handleEdit}>Edit</Button>
                </Box>}
            </Box>
        );
    } else {
        return (
            <Box>
                <Box className={classes.sectionWrapper}>
                    <Typography className={classes.sectionTitle} variant='h5'>3. Email communications</Typography>
                    <p className={classes.sectionHelperText}>
                        {accountData?.email && <span><span>Opt in to receive autopay reminder and confirmation emails at </span><span className={classes.darkText}>{maskEmail(accountData?.email)}</span></span>}
                        {/* To receive communications via text message, go to your <a className={classes.link}>general settings</a>. */}
                    </p>
                    <Box mt={4}>
                        <FormControl>
                            <RadioGroup aria-label="payment amount" name="paymentSource" value={radioValue} onChange={handleRadioChange}>
                                <FormControlLabel className={classes.customFormControlLabel} value="yes" control={<Radio color="primary" />} label="Yes, email me upcoming autopay reminders and payment confirmations" />
                                <br />
                                <FormControlLabel className={classes.customFormControlLabel} value="no" control={<Radio color="primary" />} label="No, do not email me about upcoming autopay reminders and payment confirmations" />
                            </RadioGroup>
                        </FormControl>

                    </Box>

                    <Box className={classes.buttonWrapper}>
                        <Button variant="contained" color="primary" disabled={!radioValue} onClick={handleSave}>
                            Next
                    </Button>
                    </Box>
                </Box>
            </Box>
        );
    }
};

CommunicationSection.propTypes = {
    expand: PropTypes.bool,
    setExpand: PropTypes.func,
    setSaveStatus: PropTypes.func,
    saveStatus: PropTypes.bool,
    setCommunication: PropTypes.func
};

export default CommunicationSection;
