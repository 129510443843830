import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAccountData } from '../../../../redux/settings/actions';
import { updateAutopaySetupOn } from '../../../../redux/autopay/actions';
import Alert from '@material-ui/lab/Alert';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const useStyles = makeStyles(() => ({
    sectionWrapper: {
        backgroundColor: '#ffffff',
        borderRadius: 4,
        padding: 30
    },
    sectionWrapperActive: {
        backgroundColor: '#ffffff',
        border: '1px solid #E8EAEC',
        boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.07), 0px 1px 10px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: 4,
        padding: 30
    },
    sectionTitle: {
        fontFamily: 'HCo Whitney SSm',
        fontSize: 22,
        fontWeight: '600',
        marginBottom: 10
    },
    radioHelperText: {
        marginLeft: 30,
        marginTop: -7,
        fontSize: '1rem',
        fontFamily: 'HCo Whitney SSm',
        fontWeight: 500,
        lineHeight: 1.5
    },
    buttonWrapper: {
        marginTop: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right',
        '& button': {
            padding: '16px 41.5px'
        }
    },
    previewWrapper: {
        padding: '20px 10px 20px 30px',
        backgroundColor: '#ffffff',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& img': {
            marginRight: 12
        }
    },
    previewWrapperActive: {
        padding: '20px 10px 20px 30px',
        boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.07), 0px 1px 10px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#ffffff',
        border: '1px solid #E8EAEC',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& img': {
            marginRight: 12
        }
    },
    titleBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    titleBoxMobile: {
        display: 'block',
        alignItems: 'unset',
        justifyContent: 'unset'
    },
    infoBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 15,
        '& p': {
            fontSize: 15,
            color: 'rgba(0, 0, 0, 0.87)'
        },
        '& button': {
            color: 'rgba(30, 105, 210, 1)',
            textTransform: 'none',
            textDecoration: 'underline',
            fontSize: 16,
            marginLeft: 5
        },
    },
    previewTitle: {
        fontFamily: 'HCo Whitney SSm',
        fontSize: 22,
        fontWeight: '600',
    },
    sectionHelperText: {
        maxWidth: 700,
        color: 'rgba(0, 0, 0, 0.56)'
    },
    darkText: {
        color: 'rgba(0, 0, 0, 0.87)'
    },
    link: {
        color: 'rgba(30, 105, 210, 1)',
        cursor: 'pointer'
    },
    customFormControlLabel: {
        alignItems: 'flex-start',
        '& > span': {
            padding: 0,
            marginRight: 15,
            '& > span': {
                marginLeft: 10
            }
        }
    },
    editButton: {
        color: 'rgba(30, 105, 210, 1)',
        textTransform: 'none',
        textDecoration: 'underline',
        fontSize: 16,
        marginLeft: 5
    },
    cancelButton: {
        color: 'rgba(30, 105, 210, 1)',
        textTransform: 'none',
        textDecoration: 'underline',
        fontSize: 16,
        marginLeft: 5
    }
}));

const CommunicationSection = ({ autopaySetupEnable, autopayConfig }) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const classes = useStyles();
    const [expand, setExpand] = React.useState(false);
    const [, setSaveStatus] = React.useState(true);
    const agreeReceiveNotification = autopayConfig?.agreeReceiveNotification;
    const cardIdFromConfig = autopayConfig?.method?.id;
    const [radioValue, setRadioValue] = React.useState('');
    const dispatch = useDispatch();
    const { accountData } = useSelector(state => state.settings);
    const updateAutopayData = useSelector(state => state?.autopay?.updateAutopay?.success);
    const updateAutopayIsLoading = useSelector(state => state?.autopay?.updateAutopay?.loading);
    const updateAutopayError = useSelector(state => state?.autopay?.updateAutopay?.error);
    const [showError, setShowError] = React.useState(false);


    React.useEffect(() => {
        dispatch(fetchAccountData());
    }, []);

    React.useEffect(() => {
        if (agreeReceiveNotification) setRadioValue('yes');
        else setRadioValue('no');
    }, [agreeReceiveNotification]);

    const handleEdit = (autopaySetupEnable) => {
        if (!autopaySetupEnable) return;
        setExpand(true);
        setSaveStatus(false);
    };

    const handleCancel = () => {
        setExpand(false);
        setSaveStatus(true);
        setShowError(false);
    };

    const handleRadioChange = (event) => {
        setRadioValue(event.target.value);
    };

    const handleSave = () => {
        const emailSelection = radioValue === 'yes' ? true : false;
        dispatch(updateAutopaySetupOn(cardIdFromConfig, emailSelection, true));
    };

    const maskEmail = (email) => {
        const [name, domain] = email.split('@');
        const maskedName = name[0] + '*****';
        const maskedEmail = maskedName + '@' + domain;
        return maskedEmail;
     };

    React.useEffect(() => {
        if (updateAutopayData?.id) {
            setExpand(false);
            setSaveStatus(true);
        }
        if (updateAutopayError) {
            setShowError(true);
        }
    }, [updateAutopayData, updateAutopayError]);

    if (!expand || !autopaySetupEnable) {
        return (
            <Box className={autopaySetupEnable ? classes.previewWrapperActive : classes.previewWrapper}>
                <Box className={isDesktop ? classes.titleBox : classes.titleBoxMobile}>
                    <Typography className={classes.previewTitle} variant='h5'>Email confirmation:</Typography>
                    <Box className={classes.infoBox} display="flex" alignItems="center" justifyContent="center">
                        {radioValue == 'yes' ? <p>Yes, send email communications</p> :
                            <p>No, don’t send email communications</p>}
                    </Box>
                </Box>

                <Button className={classes.editButton} size="small" onClick={() => handleEdit(autopaySetupEnable)}>Edit</Button>
            </Box>
        );
    } else {
        return (
            <Box>
                <Box className={autopaySetupEnable ? classes.sectionWrapperActive : classes.sectionWrapper}>

                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box className={isDesktop ? classes.titleBox : classes.titleBoxMobile}>
                            <Typography className={classes.previewTitle} variant='h5'>Email confirmation:</Typography>
                            <Box className={classes.infoBox} display="flex" alignItems="center" justifyContent="center">
                                {radioValue == 'yes' ? <p>Yes, send email communications</p> :
                                    <p>No, don’t send email communications</p>}
                            </Box>
                        </Box>
                        <Button className={classes.cancelButton} size="small" onClick={handleCancel}>Cancel</Button>
                    </Box>
                    <p className={classes.sectionHelperText}>
                        {accountData?.email && <span><span>Opt in to receive autopay reminder and confirmation emails at </span><span className={classes.darkText}>{maskEmail(accountData?.email)}</span></span>}
                        {/* To receive communications via text message, go to your <a className={classes.link}>general settings</a>. */}
                    </p>

                    <Box mt={4}>
                        <FormControl>
                            <RadioGroup aria-label="Email confirmation check" defaultValue='no' name="emailConfirmationCheck" value={radioValue} onChange={handleRadioChange}>
                                <FormControlLabel className={classes.customFormControlLabel} value="yes" control={<Radio color="primary" />} label="Yes, email me upcoming autopay reminders and payment confirmations" />
                                <br />
                                <FormControlLabel className={classes.customFormControlLabel} value="no" control={<Radio color="primary" />} label="No, do not email me about upcoming autopay reminders and payment confirmations" />
                            </RadioGroup>
                        </FormControl>

                    </Box>

                    {showError && <Box mt={2}>
                        <Alert severity="error">
                            We were unable to update the information. Please try again.
                            </Alert>
                    </Box>}

                    <Box className={classes.buttonWrapper}>
                        <Button variant="contained" color="primary" disabled={!radioValue || updateAutopayIsLoading} onClick={handleSave}>
                            <span>Save</span>
                            {updateAutopayIsLoading && (
                                <CircularProgress
                                    size="16px"
                                    style={{ marginLeft: '10px' }}
                                />
                            )}
                        </Button>
                    </Box>
                </Box>
            </Box>
        );
    }
};

CommunicationSection.propTypes = {
    expand: PropTypes.bool,
    setExpand: PropTypes.func,
    setSaveStatus: PropTypes.func,
    saveStatus: PropTypes.bool,
    autopaySetupEnable: PropTypes.bool,
    autopayConfig: PropTypes.obj
};

export default CommunicationSection;
