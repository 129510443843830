import React from 'react';
import { Typography, makeStyles, Box } from '@material-ui/core';
import AutopaySection from './AutopaySection';
import PaymentAmountSection from './PaymentAmountSection';
import CommunicationSection from './CommunicationSection';
import PaymentSourceSection from './PaymentSourceSection';
import AutopayOffConfirmationModal from './modals/AutopayOffConfirmationModal';
import Autopaypdf from '../../../../assets/AutoPayTerms.pdf';
import StoredCredsTerms from '../../../../assets/StoredCredTerms.pdf';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAutopayConfiguration } from '../../../../redux/autopay/actions';
import genericServiceReducer from '../../../../redux/common/genericServiceReducer';
import { memberApi } from '../../../../config/axios-config';
import AutopayPreSetup from './AutopayPreSetup';
import { fetchAccountData } from '../../../../redux/settings/actions';

const useStyles = makeStyles(() => ({
    pageWrapper: {
        padding: '16px 0px 90px'
    },
    pageContainer: {
        maxWidth: 1000
    },
    pageSubTitle: {
        marginTop: '7px', fontFamily: 'HCo Whitney SSm', fontSize: 28, fontWeight: '450'
    },
    maskWrapper: {
        width: '100%',
        opacity: '0.5',
        background: '#f3f3f3',
        height: '100%',
        position: 'absolute',
        top: '0',
        zIndex: '1'
    },
    footerText: {
        marginTop: 30,
        fontSize: '16px',
        fontWeight: '500'
    },
    link: {
        color: 'rgba(30, 105, 210, 1)',
        cursor: 'pointer',
        textDecoration: 'none'
    }
}));

const ManageAutopay = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [autopaySetupEnable, setAutopaySetupEnable] = React.useState(true);
    const [openAutopayOffModal, setOpenAutopayOffModal] = React.useState(false);
    const [paymentSourceList, setPaymentSourceList] = React.useState([]);
    const [addNewCardError, setAddNewCardError] = React.useState(false);
    const [addCardActive, setAddCardActive] = React.useState(false);
    const [maxPaymentMethodExceededThreshold, setMaxPaymentMethodExceededThreshold] = React.useState(false);
    const [maxPaymentMethodAllowed, setMaxPaymentMethodAllowed] = React.useState('');

    const handleAutopayOffModalClose = () => {
        setOpenAutopayOffModal(false);
    };

    React.useEffect(() => {
        dispatch(fetchAutopayConfiguration());
        dispatch(fetchAccountData());
    }, []);


    const [paymentSourceReducerState, paymentSourceReducerDispatch] = React.useReducer(genericServiceReducer, {
        status: 'idle',
        data: null,
        error: null
    });

    React.useEffect(() => {
        paymentSourceReducerDispatch({ type: 'started' });
        memberApi
            .get('/v2/my/payments/methods?type=CARD')
            .then(res => {
                paymentSourceReducerDispatch({ type: 'success' });
                setPaymentSourceList(res?.data?.paymentMethods);
                setMaxPaymentMethodExceededThreshold(res?.data?.maxPaymentMethodExceededThreshold);
                setMaxPaymentMethodAllowed(res?.data?.maxPaymentMethodAllowed);
            })
            .catch(err => paymentSourceReducerDispatch({ type: 'error', error: err }));
    }, []);

    React.useEffect(() => {
        if ((addCardActive === false) && !addNewCardError) {
            paymentSourceReducerDispatch({ type: 'started' });
            memberApi
                .get('/v2/my/payments/methods?type=CARD')
                .then(res => {
                    paymentSourceReducerDispatch({ type: 'success' });
                    setPaymentSourceList(res?.data?.paymentMethods);
                    setMaxPaymentMethodExceededThreshold(res?.data?.maxPaymentMethodExceededThreshold);
                    setMaxPaymentMethodAllowed(res?.data?.maxPaymentMethodAllowed);
                })
                .catch(err => paymentSourceReducerDispatch({ type: 'error', error: err }));
        }
    }, [addCardActive]);

    const paymentSourceIsLoading = paymentSourceReducerState?.status === 'pending';
    const paymentSourceError = paymentSourceReducerState?.error;
    const isAutoPay = useSelector(state => state?.autopay?.autopayConfig?.isAutoPay);
    const autopayConfig = useSelector(state => state?.autopay?.autopayConfig);
    const isAutoPayLoading = useSelector(state => state?.autopay?.loading);

    if (!isAutoPayLoading && isAutoPay == null) {
        return (
            <Box><AutopayPreSetup isAutoPay={isAutoPay} /></Box>
        );
    }

    return (
        <>
            <Box className={classes.pageWrapper}>
                <Box className={classes.pageContainer}>
                    <Typography variant="h5" className={classes.pageSubTitle}>Manage Autopay</Typography>
                    <Box mt={4}>
                        <AutopaySection setAutopaySetupEnable={setAutopaySetupEnable} autopaySetupEnable={autopaySetupEnable} handleOpenModal={setOpenAutopayOffModal} isAutoPayLoading={isAutoPayLoading} isAutoPay={isAutoPay} paymentSourceIsLoading={paymentSourceIsLoading} />
                    </Box>
                    <Box style={{ position: 'relative' }}>
                        <Box mt={1}>
                            <PaymentAmountSection autopaySetupEnable={autopaySetupEnable} />
                        </Box>

                        <Box mt={1}>
                            <PaymentSourceSection autopaySetupEnable={autopaySetupEnable} paymentSourceData={paymentSourceList} paymentSourceIsLoading={paymentSourceIsLoading} paymentSourceError={paymentSourceError} autopayConfig={autopayConfig} setAddNewCardError={setAddNewCardError} addNewCardError={addNewCardError} addCardActive={addCardActive} setAddCardActive={setAddCardActive} maxPaymentMethodExceededThreshold={maxPaymentMethodExceededThreshold} maxPaymentMethodAllowed={maxPaymentMethodAllowed} setMaxPaymentMethodExceededThreshold={setMaxPaymentMethodExceededThreshold} setMaxPaymentMethodAllowed={setMaxPaymentMethodAllowed} />
                        </Box>

                        <Box mt={1}>
                            <CommunicationSection autopaySetupEnable={autopaySetupEnable} autopayConfig={autopayConfig} />
                        </Box>
                        <Box className={!autopaySetupEnable && classes.maskWrapper}></Box>

                    </Box>
                    <Box>
                        <p className={classes.footerText}>
                            For information about site rules & usage, visit our <a className={classes.link} href={StoredCredsTerms}
                                rel="noopener noreferrer"
                                target="_blank">General Consent</a> and <a className={classes.link} href={Autopaypdf}
                                    rel="noopener noreferrer"
                                    target="_blank">Autopay Agreement</a>.
                        </p>
                    </Box>
                </Box>
                <AutopayOffConfirmationModal handleClose={handleAutopayOffModalClose} open={openAutopayOffModal} setAutopaySetupEnable={setAutopaySetupEnable} autopaySetupEnable={autopaySetupEnable} />
            </Box>
        </>
    );
};


export default ManageAutopay;
