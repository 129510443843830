import React from 'react';
import PropTypes from 'prop-types';
import { Box, Paper, Typography, makeStyles } from '@material-ui/core';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import PeopleIcon from '@material-ui/icons/People';
import heroSmPeople from '../../../../assets/hero_sm_people.png';
import { getDateObj } from '../../../../utils/date-utils';
import { camelCaseString } from '../../../../utils/format-utils';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  icon: {
    color: theme.palette.primary.main,
    marginRight: '16px'
  },
  lineItem: {
    display: 'flex'
  }
}));

const WelcomeOverview = ({ accountData, coverageDate, coveredMembers }) => {
  const classes = useStyles();

  const getDisplayDate = date => {
    const dateObj = getDateObj(date);

    if (!dateObj.shortMonth) return '';

    return `${dateObj.shortMonth} ${dateObj.day}, ${dateObj.year}`;
  };

  const foramtCoveredMembers = (members) => {

    if (!members || members.length === 0) {
      return '';
    }

    const getListFormat = (arrayLength, index) => {

      if (index === 0) return '';

      if (arrayLength - 1 === index) return ', and';

      return ',';
    };

    if (accountData.isEligible === true) {
      return members.map(
        (member, index) => {
          if (!member?.isEligible) return;
          return (
            `${getListFormat(members.length, index)} ${camelCaseString(
              member.firstName
            )} ${camelCaseString(member.lastName)}`);
        }
      );
    }
    return '';
  };

  return (
    <Paper className={classes.root}>
      <Box p={2}>
        <Typography variant="h5" style={{ fontWeight: 600 }}>
          Welcome to Ascension Personalized Care!
        </Typography>
      </Box>
      <Box>
        <img src={heroSmPeople} width="100%" />
      </Box>
      <Box p={2}>
        <div className={classes.lineItem}>
          <LocalHospitalIcon className={classes.icon} />
          <Typography style={{ marginBottom: '24px' }}>
            Your plan is the {accountData.planDescription}
          </Typography>
        </div>
        <div className={classes.lineItem}>
          <CalendarTodayIcon className={classes.icon} />
          <Typography style={{ marginBottom: '24px' }}>
            <b>Coverage begins {getDisplayDate(coverageDate)}</b>
          </Typography>
        </div>
        <div className={classes.lineItem}>
          <PeopleIcon className={classes.icon} />
          <Typography>
            <b>Your plan covers:</b>
            <br />
            {foramtCoveredMembers(coveredMembers)}
          </Typography>
        </div>
      </Box>
    </Paper>
  );
};

WelcomeOverview.propTypes = {
  accountData: PropTypes.object,
  coverageDate: PropTypes.string,
  coveredMembers: PropTypes.array
};

WelcomeOverview.defaultProps = {
  accountData: {},
  coverageDate: '',
  coveredMembers: []
};

export default WelcomeOverview;
