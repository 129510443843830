import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AsyncTypography from '../../../common/AsyncTypography/AsyncTypography';
import { monitaryFormat } from '../../../../utils/format-utils';
import { getDateObj } from '../../../../utils/date-utils';
import {
  billStatusDisplayOptions,
  billStatuses,
  getBillStatus,
  getPaidOnDate
} from '../payment-utils';

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px',
    backgroundColor: '#E8F5FE'
  },
  mainBreakdown: {
    display: 'flex',
    '& > div': {
      marginRight: '64px'
    },
    [theme.breakpoints.down('sm')]: {
      '& > div': {
        marginRight: 0
      },
      justifyContent: 'space-around'
    }
  },
  mainButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > button': {
        margin: '8px 0px'
      }
    }
  },
  button: {
    margin: '0px 8px'
  },
  success: {
    color: theme.palette.success.main
  }
}));

const BillActionArea = ({ bill, onPaymentClick, isModule }) => {
  const classes = useStyles();

  const billStatus = getBillStatus(bill);
  const displayOptions = billStatusDisplayOptions[billStatus] || {};
  const paymentDueDate = getDateObj(bill.dueDate);
  const paidOnDate = getPaidOnDate(bill);

  return (
    <Grid
      container
      className={classes.main}
      style={{ backgroundColor: displayOptions.backgroundColor }}
    >
      <Grid
        className={classes.mainBreakdown}
        item
        sm={!isModule && displayOptions.displayPaymentBtn ? 8 : 12}
        xs={12}
      >
        <div>
          <div>
            <AsyncTypography variant="body1">
              {displayOptions.statusLabel}
            </AsyncTypography>
          </div>

          <AsyncTypography variant="h3" data-testid="status-value">
            {displayOptions.statusValue ? (
              <div style={{ color: displayOptions.textColor }}>
                {displayOptions.Icon && <displayOptions.Icon />}{' '}
                {displayOptions.statusValue}{' '}
              </div>
            ) : (
              monitaryFormat(bill.netDueAmount)
            )}
          </AsyncTypography>

          <Typography
            variant="body2"
            color="textSecondary"
            style={{ marginTop: '4px' }}
          >
            {displayOptions.statusNote}
          </Typography>
        </div>
        {displayOptions.displayDate && (
          <div>
            <Typography variant="body1">{displayOptions.dateLabel}</Typography>
            <AsyncTypography variant="h3">
              {billStatus === billStatuses.unpaid
                ? `${paymentDueDate.shortMonth} ${paymentDueDate.day}`
                : `${paidOnDate.shortMonth} ${paidOnDate.day}`}
            </AsyncTypography>
          </div>
        )}
      </Grid>
      <Grid
        className={classes.mainButtons}
        item
        sm={!isModule && displayOptions.displayPaymentBtn && 4}
        xs={12}
        style={{ marginTop: isModule && '16px' }}
      >
        {displayOptions.displayPaymentBtn && (
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            startIcon={<AttachMoneyIcon />}
            onClick={onPaymentClick}
          >
            make payment
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

BillActionArea.propTypes = {
  bill: PropTypes.object.isRequired,
  onPaymentClick: PropTypes.func.isRequired,
  isModule: PropTypes.bool
};

BillActionArea.defaultProps = {
  isModule: false
};

export default BillActionArea;
