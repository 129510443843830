import React from 'react';
import { Typography, Button, makeStyles, Card } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import BasePage from '../BasePage';
import warningSvg from '../../assets/warning.svg';
import { useMsal } from '@azure/msal-react';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4, 0),
    [theme.breakpoints.down('xs')]: {
      padding: 0
    },
    '& > *': {
      marginBottom: '16px'
    }
  },
  container: {
    margin: '0 auto',
    maxWidth: '650px',
    padding: theme.spacing(6, 7),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(5, 2),
      '& p': {
        fontSize: '14px'
      }
    }
  },
  warning: {
    color: '#FF7F00',
    textAlign: 'center',
    marginBottom: theme.spacing(5)
  },
  subTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  list: {
    paddingLeft: '12px',
    '& > li': {
      fontWeight: 'bold',
      marginBottom: '16px'
    }
  },
  phone: {
    fontWeight: 'bold',
    color: '#1E69D2',
    textDecoration: 'none'
  },
  buttonWrapper: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column-reverse'
    }
  },
  phoneWrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  phoneButton: {
    color: '#1E69D2',
    marginRight: theme.spacing(3),
    '& svg': {
      marginRight: theme.spacing(1.5)
    },
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginTop: theme.spacing(2)
    }
  }
}));

const UnverifiedAccountLanding = () => {
  const classes = useStyles();
  const { instance } = useMsal();

  const account = instance.getActiveAccount();

  const handleLoginClick = () => instance.logout();

  const getDisplayName = acc => {
    if (acc && acc.idTokenClaims && acc.idTokenClaims.firstName) {
      return acc.idTokenClaims.firstName;
    } else {
      return '';
    }
  };

  return (
    <BasePage className={classes.root} fullWidth>
      <Card className={classes.container}>
        <div className={classes.warning}>
          <img src={warningSvg} alt="Warning" />
        </div>
        <Typography variant="h4" gutterBottom>{`Hello ${getDisplayName(
          account
        )},`}</Typography>
        <Typography>
          We are unable to find a Ascension Personalized Care account that
          matches the information entered.
        </Typography>
        <Typography className={classes.subTitle}>
          This could be for multiple reasons:
        </Typography>
        <ol className={classes.list}>
          <li>
            <Typography style={{ marginBottom: '8px' }}>
              <strong>
                If you enrolled in Ascension Personalized Care coverage within
                the last 5 business days, your account may not be ready to
                access yet.
              </strong>
            </Typography>
            <Typography color="textSecondary">
              Please wait 5 business days, then try logging in again.
            </Typography>
          </li>
          <li>
            <Typography style={{ marginBottom: '8px' }}>
              <strong>
                The personal information entered may not exactly match your
                account in Ascension Personalized Care.
              </strong>
            </Typography>

            <Typography color="textSecondary">
              Please try again, using your legal first and last name, the last 4
              digits of your social security number, and your date of birth
              using the Month/Day/Year format.
            </Typography>
          </li>
        </ol>
        <Typography className={classes.phoneWrapper}>
          You can call support at{' '}
          <a href="tel:8336001311" className={classes.phone}>
            833-600-1311
          </a>
        </Typography>
        <div className={classes.buttonWrapper}>
          <Button
            className={classes.phoneButton}
            component={'a'}
            color="primary"
            variant="outlined"
            href="tel:8336001311"
          >
            <PhoneIcon />
            833-600-1311
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleLoginClick}
          >
            Retry Log In
          </Button>
        </div>
      </Card>
    </BasePage>
  );
};

export default UnverifiedAccountLanding;
