import React from 'react';
import PropTypes from 'prop-types';
import { PaymentElement } from '@stripe/react-stripe-js';
import { Box, Button } from '@material-ui/core';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { Alert } from '@material-ui/lab';

const CheckoutForm = ({ setAddCardActive }) => {
    const elements = useElements();
    const stripe = useStripe();
    const [validateCard, setValidateCard] = React.useState(false);
    const [stripeErrorMessage, setStripeErrorMessage] = React.useState('');

    const handlePaymentElementOnChange = (e) => {
        setValidateCard(e?.complete);
    };

    const handleSave = () => {
        stripe.confirmSetup({ elements, redirect: 'if_required' }).then((result) => {

            if (result.error) {  
                console.log(result?.error?.message);
                setStripeErrorMessage(result?.error?.message);
            } else {
                setAddCardActive(false);
            }

        }).catch((e) => {
            console.log(e);
        });
    };

    return (<form>
        <PaymentElement onChange={(e) => handlePaymentElementOnChange(e)} />

        <Box mt={2} display="flex" justifyContent="flex-end">

            <Button style={{ padding: '16px 41.5px' }} variant="contained" color="primary" disabled={!validateCard} onClick={handleSave}>
                Add New Card
            </Button>
            
        </Box>

        {stripeErrorMessage !== '' && 
        
            <Box mt={2} display="flex" justifyContent="center">
                <Alert severity='error'>
                    {stripeErrorMessage}
                </Alert>
            </Box>

        }
        

    </form>);
};

CheckoutForm.propTypes = {
    setAddCardActive: PropTypes.func,
};

export default CheckoutForm;
