import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  IconButton,
  Paper,
  Typography,
  makeStyles
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: '16px',
    marginBottom: '32px',
    border: `1px solid ${theme.palette.error.main}`,
    borderLeft: `3px solid ${theme.palette.error.main}`
  },
  content: {
    width: '100%'
  },
  topLine: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  icon: {
    color: theme.palette.error.main,
    marginRight: '16px'
  },
  actionArea: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const PaymentErrorAlert = ({ text, onClose }) => {
  const classes = useStyles();
  const supportNum = '833-600-1311';

  return (
    text && (
      <Paper className={classes.root} elevation={0}>
        <div>
          <InfoIcon className={classes.icon} />
        </div>
        <div className={classes.content}>
          <div className={classes.topLine}>
            <Typography>There was an error processing your payment.</Typography>
            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </div>

          <Typography style={{ margin: '32px 0px' }} color="textSecondary">
            Please try again later. If this problem persists, call Customer
            Service at {supportNum}.
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <b>Error Details: </b>
            {text}
          </Typography>
          <div className={classes.actionArea}>
            <Button color="secondary" href={`tel:+${supportNum}`}>
              call customer service
            </Button>
          </div>
        </div>
      </Paper>
    )
  );
};

PaymentErrorAlert.propTypes = {
  text: PropTypes.string,
  onClose: PropTypes.func.isRequired
};

export default PaymentErrorAlert;
