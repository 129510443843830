import axios from 'axios';
import { loginRequest, msalInstance } from '../auth/config';
import routes from '../constants/routes';
import { getEnvVars } from '../utils/env-utils';

const { apiUrl, memberKey } = getEnvVars(window.location.hostname);

const memberApi = axios.create({
  baseURL: `${apiUrl}/member`,
  headers: {
    apikey: memberKey
  }
});

const setupInterceptors = history => {
  console.log('init history', history);
  memberApi.interceptors.response.use(
    response => response,
    error => {
      console.log('api error');
      if (error.response.status === 401 || error.response.status === 403) {
        console.log('HIT');
        console.log(history);
        history.push(routes.UNVERIFIED_ACCOUNT);
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    }
  );

  memberApi.interceptors.request.use(
    async config => {
      const activeAccount = msalInstance.getActiveAccount();
      const request = { ...loginRequest, account: { ...activeAccount } };
      const { accessToken } = await msalInstance.acquireTokenSilent(request);
      config.headers['Authorization'] = `Bearer ${accessToken}`;
      config.headers['tenant-id'] = 'APC';
      return config;
    },
    error => {
      Promise.reject(error);
    }
  );
};

export { memberApi, setupInterceptors };
