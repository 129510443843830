import constants from './constants';

export const initialState = {
  accountData: {},
  coveredMembers: [],
  accountDataError: null,
  loading: true
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.SET_ACCOUNT_DATA:
      return {
        ...state,
        accountData: { ...payload },
        accountDataError: null,
        loading: false
      };

    case constants.SET_ACCOUNT_DATA_ERROR:
      return {
        ...state,
        accountData: {},
        accountDataError: payload,
        loading: false
      };

    case constants.SET_COVERED_MEMBERS:
      return {
        ...state,
        coveredMembers: [...payload]
      };

    case constants.SET_COVERED_MEMBERS_ERROR:
      return {
        ...state,
        coveredMembersError: payload
      };

    default:
      return state;
  }
};
