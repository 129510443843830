import { trackPromise } from 'react-promise-tracker';
import { memberApi } from '../../config/axios-config';
import constants from './constants';

// action creators

export const beginClaims = payload => ({
  type: constants.BEGIN_CLAIMS,
  payload
});

export const setClaims = payload => ({
  type: constants.SET_CLAIMS,
  payload
});

export const openClaimDetails = payload => ({
  type: constants.OPEN_CLAIM_DETAILS,
  payload
});

export const closeClaimDetails = () => ({
  type: constants.CLOSE_CLAIM_DETAILS
});

export const setClaimsError = payload => ({
  type: constants.SET_CLAIMS_ERROR,
  payload
});

export const fetchClaims = (requestParams, pageNumber, pageSize) => dispatch => {
  dispatch(beginClaims());
  return trackPromise(
    memberApi
      .post(
        `v2/my/claims?ppage=${pageNumber}&ppageSize=${pageSize}&pskipRows=0`,
        requestParams
      )
      .then(res => {
        dispatch(
          setClaims({
            ...res.data,
            requestParams
          })
        );
      })
      .catch(err => dispatch(setClaimsError(err.message)))
  );
};

export const viewClaimDetails = claimId => dispatch =>
  trackPromise(
    memberApi
      .get(`v2/my/claims/${claimId}`)
      .then(res => {
        dispatch(openClaimDetails(res.data));
      })
      .catch(err => dispatch(setClaimsError(err.message)))
  );
