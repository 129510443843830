import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  Paper,
  Typography
} from '@material-ui/core';
import BasePage from '../BasePage';
import PrivacyPolicy from './PrivacyPolicy';
import { useHistory } from 'react-router-dom';
import routes from '../../constants/routes';
import { setUserAgreementStatus } from '../../utils/storage-utils';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    padding: '32px 48px',
    margin: '32px 0px',
    [theme.breakpoints.down('xs')]: {
      padding: '16px 8px',
      marginTop: '16px'
    }
  },
  documentView: {
    maxHeight: '480px',
    overflowY: 'auto',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    padding: '16px',
    [theme.breakpoints.down('xs')]: {
      maxHeight: '260px'
    }
  },
  checkboxGroup: {
    padding: '16px'
  },
  btnGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    '& > button': {
      marginLeft: '16px'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      '& > button': {
        margin: '8px 0px',
        width: '100%'
      }
    }
  }
}));

const UserAgreement = () => {
  const classes = useStyles();
  const history = useHistory();
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleAcceptClicked = () => {
    if (termsAccepted) {
      setUserAgreementStatus(termsAccepted);
      history.push(routes.DASHBOARD);
    }
  };

  return (
    <BasePage>
      <Typography variant="h1">Privacy Policy & End User Agreement</Typography>
      <Grid container>
        <Grid item xs={0} sm={1}></Grid>
        <Grid item xs={12} sm={10}>
          <Paper className={classes.mainContainer}>
            <main className={classes.documentView}>
              <PrivacyPolicy />
            </main>
            <FormGroup className={classes.checkboxGroup}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={termsAccepted}
                    onClick={() => setTermsAccepted(!termsAccepted)}
                  />
                }
                label="By clicking accept, I agree to be bound by the Terms of Use and Privacy Policy"
              />
            </FormGroup>
            <div className={classes.btnGroup}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleAcceptClicked}
                disabled={!termsAccepted}
              >
                accept
              </Button>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={0} sm={1}></Grid>
      </Grid>
    </BasePage>
  );
};

export default UserAgreement;
