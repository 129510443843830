export const mockInvoice = {
  planName: 'Ascension Personalized Care Balanced Silver Medical Plan for 2021',
  dueDate: new Date(2021, 1, 28),
  premium: 2000,
  taxCredit: 1700,
  ammountOwed: 300,
  nextMonth: {
    ammountOwed: 300,
    dueDate: new Date(2021, 2, 28)
  }
};

export const mockPaymentMethods = [
  {
    id: 1,
    type: 'card',
    data: {
      brand: 'mastercard',
      exp_month: 12,
      exp_year: 2025,
      last4: '4242'
    }
  },
  {
    id: 2,
    type: 'bank',
    data: {
      brand: 'Wells Fargo',
      last4: '1234'
    }
  }
];

export const mockAutopaySettings = {
  isEnabled: true,
  method: {
    type: 'card',
    data: {
      brand: 'mastercard',
      exp_month: 12,
      exp_year: 2025,
      last4: '4242'
    }
  },
  paymentDays: [26],
  ammoutDue: 300,
  ammountPerPayment: 300
};

export const mockPaymentHistory = [
  {
    id: 1,
    date: new Date(2021, 6, 20),
    method: 'Mastercard (•••• 1234)',
    ammount: 75,
    subText: 'Partial Payment #2'
  },
  {
    id: 2,
    date: new Date(2021, 6, 8),
    method: 'Mastercard (•••• 1234)',
    ammount: 225,
    subText: 'Partial Payment #1'
  },
  {
    id: 3,
    date: new Date(2021, 5, 17),
    method: 'Wells Fargo Direct Transfer',
    ammount: 300,
    subText: ''
  },
  {
    id: 4,
    date: new Date(2021, 4, 27),
    method: 'Mastercard (•••• 1234)',
    ammount: 150,
    subText: 'Partial Payment #2'
  },
  {
    id: 5,
    date: new Date(2021, 4, 13),
    method: 'Mastercard (•••• 1234)',
    ammount: 150,
    subText: 'Partial Payment #1'
  },
  {
    id: 6,
    date: new Date(2021, 3, 6),
    method: 'Mastercard (•••• 1234)',
    ammount: 300,
    subText: ''
  },
  {
    id: 7,
    date: new Date(2021, 2, 18),
    method: 'Mastercard (•••• 1234)',
    ammount: 300,
    subText: ''
  },
  {
    id: 8,
    date: new Date(2021, 1, 7),
    method: 'Mastercard (•••• 1234)',
    ammount: 300,
    subText: ''
  },
  {
    id: 9,
    date: new Date(2020, 12, 21),
    method: 'Mastercard (•••• 1234)',
    ammount: 300,
    subText: ''
  },
  {
    id: 10,
    date: new Date(2020, 11, 3),
    method: 'Mastercard (•••• 1234)',
    ammount: 300,
    subText: ''
  },
  {
    id: 11,
    date: new Date(2020, 10, 9),
    method: 'Mastercard (•••• 1234)',
    ammount: 300,
    subText: ''
  }
];

export const mockBillingSummaries = [
  {
    stateValue: 'past_due_1_month',
    stateDisplay: 'Past Due 1 Month',
    delinquencyDate: '2021-12-04',
    dueDate: '2022-02-01',
    groupName: 'USHL ACA INDIVIDUAL EXCHANGE GOLD KANSAS',
    invoiceId: '212430306',
    latestBillCreatedDueDate: '2022-01-10',
    netDueAmount: '3000.00',
    premiumAmount: '2000.00',
    taxCreditAmount: '500.00',
    priorOutstandingAmount: '1500.00',
    paymentHistory: []
  },
  {
    stateValue: 'covered_by_gov_sub',
    stateDisplay: 'Covered By Gov Sub',
    delinquencyDate: '2021-12-04',
    dueDate: '2022-02-01',
    groupName: 'USHL ACA INDIVIDUAL EXCHANGE GOLD KANSAS',
    invoiceId: '212430306',
    latestBillCreatedDueDate: '2022-01-10',
    netDueAmount: '0',
    premiumAmount: '500.00',
    taxCreditAmount: '500.00',
    priorOutstandingAmount: '0',
    paymentHistory: []
  },
  {
    stateValue: 'paid',
    stateDisplay: 'Paid',
    delinquencyDate: '2021-12-04',
    dueDate: '2022-02-01',
    groupName: 'USHL ACA INDIVIDUAL EXCHANGE GOLD KANSAS',
    invoiceId: '212430306',
    latestBillCreatedDueDate: '2022-01-10',
    netDueAmount: '0',
    premiumAmount: '2000.00',
    taxCreditAmount: '1000.00',
    priorOutstandingAmount: '0',
    paymentHistory: [
      {
        paymentDate: '01-12-2022',
        paymentAmount: '1000.00',
        confirmationNumber: 1,
        status: 'PAID'
      }
    ]
  },
  {
    stateValue: 'pending_full',
    stateDisplay: 'Pending Payment In Full',
    delinquencyDate: '2021-12-04',
    dueDate: '2022-02-01',
    groupName: 'USHL ACA INDIVIDUAL EXCHANGE GOLD KANSAS',
    invoiceId: '212430306',
    latestBillCreatedDueDate: '2022-01-10',
    netDueAmount: '1000.00',
    premiumAmount: '2000.00',
    taxCreditAmount: '1000.00',
    priorOutstandingAmount: '0',
    paymentHistory: [
      {
        paymentDate: '01-12-2022',
        paymentAmount: '1000.00',
        confirmationNumber: 1,
        status: 'PENDING'
      }
    ]
  },
  {
    stateValue: 'pending_partial',
    stateDisplay: 'Pending Payment Partial',
    delinquencyDate: '2021-12-04',
    dueDate: '2022-02-01',
    groupName: 'USHL ACA INDIVIDUAL EXCHANGE GOLD KANSAS',
    invoiceId: '212430306',
    latestBillCreatedDueDate: '2022-01-10',
    netDueAmount: '1000.00',
    premiumAmount: '2000.00',
    taxCreditAmount: '1000.00',
    priorOutstandingAmount: '0',
    paymentHistory: [
      {
        paymentDate: '01-12-2022',
        paymentAmount: '500.00',
        confirmationNumber: 1,
        status: 'PENDING'
      }
    ]
  },
  {
    stateValue: 'paid_and_pending',
    stateDisplay: 'Paid and Pending',
    delinquencyDate: '2021-12-04',
    dueDate: '2022-02-01',
    groupName: 'USHL ACA INDIVIDUAL EXCHANGE GOLD KANSAS',
    invoiceId: '212430306',
    latestBillCreatedDueDate: '2022-01-10',
    netDueAmount: '900.00',
    premiumAmount: '2000.00',
    taxCreditAmount: '1000.00',
    priorOutstandingAmount: '0',
    paymentHistory: [
      {
        paymentDate: '01-12-2022',
        paymentAmount: '100.00',
        confirmationNumber: 1,
        status: 'PAID'
      },
      {
        paymentDate: '01-22-2022',
        paymentAmount: '50.00',
        confirmationNumber: 2,
        status: 'PENDING'
      }
    ]
  },
  {
    stateValue: 'partial_paid',
    stateDisplay: 'Partial Payment',
    delinquencyDate: '2021-12-04',
    dueDate: '2022-02-01',
    groupName: 'USHL ACA INDIVIDUAL EXCHANGE GOLD KANSAS',
    invoiceId: '212430306',
    latestBillCreatedDueDate: '2022-01-10',
    netDueAmount: '1000.00',
    premiumAmount: '2000.00',
    priorOutstandingAmount: '0',
    paymentHistory: [
      {
        paymentDate: '01-12-2022',
        paymentAmount: '500.00',
        confirmationNumber: 1,
        status: 'PAID'
      },
      {
        paymentDate: '01-17-2022',
        paymentAmount: '500.00',
        confirmationNumber: 2,
        status: 'PAID'
      }
    ]
  },
  {
    stateValue: 'no_payment_required',
    stateDisplay: 'No Payment Required',
    delinquencyDate: '2021-12-04',
    dueDate: '2022-02-01',
    groupName: 'USHL ACA INDIVIDUAL EXCHANGE GOLD KANSAS',
    invoiceId: '212430306',
    latestBillCreatedDueDate: '2022-01-10',
    netDueAmount: '0.20',
    premiumAmount: '2000.00',
    priorOutstandingAmount: '0',
    paymentHistory: []
  }
];
