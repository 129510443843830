import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import PhoneIcon from '@material-ui/icons/Phone';
import SearchIcon from '@material-ui/icons/Search';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 450,
    margin: '0 auto',
    borderRadius: 4
  },
  content: {
    textAlign: 'center'
  },
  actions: {
    padding: '16px 0px',
    justifyContent: 'space-around'
  }
}));

const CallDialog = ({ open, onClose, phoneNumber, header, memberId }) => {
  const classes = useStyles();

  return (
    <Dialog
      className={classes.root}
      open={open}
      onClose={onClose}
      aria-labelledby="call dialog"
    >
      <DialogContent className={classes.content}>
        <SearchIcon color="secondary" style={{ fontSize: 64 }} />
        <Typography variant="h3" style={{ marginBottom: '16px' }}>
          {header}
        </Typography>
        {memberId && (
          <>
            <Typography style={{ marginBottom: '16px' }}>
              <span style={{ marginRight: '8px' }}>Member ID:</span>
              <span>{memberId}</span>
            </Typography>
            <Typography>
              You will need your Member ID when calling support.
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={onClose} color="secondary">
          NOT NOW
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<PhoneIcon />}
          href={`tel:${phoneNumber}`}
        >
          {phoneNumber}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CallDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  header: PropTypes.string,
  phoneNumber: PropTypes.string,
  memberId: PropTypes.string
};

export default CallDialog;
