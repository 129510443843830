import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography, makeStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { getDateObj } from '../../../../utils/date-utils';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: '16px',
    marginBottom: '32px',
    border: `1px solid ${theme.palette.primary.main}`,
    borderLeft: `3px solid ${theme.palette.primary.main}`
  },
  moduleRoot: {
    display: 'flex',
    padding: '16px'
  },
  icon: {
    color: theme.palette.primary.main,
    marginRight: '16px'
  }
}));

const NextBillReadyAlert = ({ isModule }) => {
  const classes = useStyles();

  const currentDate = getDateObj(new Date());
  const dateBillWillbeReady = getDateObj(
    new Date(currentDate.year, currentDate.monthIndex, 21)
  );

  const shouldDisplayAlert = currentDate.day < dateBillWillbeReady.day;

  return (
    shouldDisplayAlert && (
      <Paper
        className={isModule ? classes.moduleRoot : classes.root}
        elevation={0}
      >
        <InfoIcon className={classes.icon} />
        <div>
          <Typography>
            Your next premium bill will be ready for you by{' '}
            {dateBillWillbeReady.displayDate}.
          </Typography>
          <Typography variant="body2">
            We&apos;ll notify you as soon as it&apos;s available.
          </Typography>
        </div>
      </Paper>
    )
  );
};

NextBillReadyAlert.propTypes = {
  isModule: PropTypes.bool
};

NextBillReadyAlert.defaultProps = {
  isModule: false
};

export default NextBillReadyAlert;
