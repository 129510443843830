import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 18, 12, 18),
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 3, 7, 3)
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 7, 2)
    }
  },
  container: {
    backgroundColor: '#1551B4',
    padding: theme.spacing(5, 3, 5, 8),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 3)
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(5, 2, 3, 2)
    }
  },
  title: {
    color: 'white',
    fontSize: '34px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '29px'
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2)
    }
  },
  content: {
    color: 'white',
    fontSize: '14px',
    maxWidth: '450px',
    marginBottom: theme.spacing(4)
  },
  button: {
    background: 'white',
    color: '#1551B4',
    textTransform: 'initial',
    fontSize: '18px',
    width: '193 px',
    height: '48px',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  text: {
    fontSize: '29px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '24px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '21px',
      marginBottom: theme.spacing(1)
    }
  },
  image: {
    width: '186px',
    [theme.breakpoints.down('sm')]: {
      width: '120px'
    }
  }
}));

const TruHearingFooter = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid className={classes.container} container justify="space-between">
        <Grid item xs={12} sm={9}>
          <Typography className={classes.title}>
            Don’t Miss Another Moment
          </Typography>
          <Typography className={classes.content}>
            TruHearing prices plus a hearing aid allowance can save you even
            more! Not sure if you have an allowance? Call us to find out.
          </Typography>
          <Button
            variant="contained"
            size="large"
            className={classes.button}
            href="https://ascension-hs.truhearing.com/"
            target="__blank"
            rel="noopener"
          >
            Find Out More
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default TruHearingFooter;
