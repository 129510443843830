import React from 'react';
import PropTypes from 'prop-types';
import { Divider, makeStyles, Typography } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import BillLineItem from './BillLineItem';
import { monitaryFormat } from '../../../../utils/format-utils';
import { getDetailsDisplayOptions } from '../payment-utils';

const useStyles = makeStyles(() => ({
  details: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column'
  }
}));

const BillDetails = ({ bill }) => {
  const classes = useStyles();

  const details = getDetailsDisplayOptions(bill);

  return (
    <div className={classes.details}>
      <Typography style={{ marginBottom: '16px' }}>Bill details</Typography>
      <BillLineItem
        Icon={LocalHospitalIcon}
        label="Plan Included in this Bill"
        leadingText={bill.planDescription}
      />
      <Divider />
      <BillLineItem
        Icon={AttachMoneyIcon}
        label="Monthly Premium"
        leadingText="Was originally "
        trailingText={monitaryFormat(bill.currentMonthPremiumAmount)}
      />
      <>
        {bill.currentMonthSubsidyAmount > 0 && (
          <BillLineItem
            label="Government Tax Credit"
            leadingText="You receive a monthly "
            trailingText={`tax credit of ${monitaryFormat(
              bill.currentMonthSubsidyAmount
            )}`}
            highlightColor="#126E36"
          />
        )}
        {bill.priorOutstandingAmount > 0 && (
          <BillLineItem
            label="Outstanding Balance from Previous Invoice"
            leadingText={monitaryFormat(bill.priorOutstandingAmount)}
          />
        )}

        {details.lineItems.map(i => (
          <BillLineItem
            key={i.label}
            label={i.label}
            leadingText={i.leadingText}
            trailingText={i.trailingText}
            highlightColor={i.highlightColor}
          />
        ))}

        <Divider />
        <BillLineItem
          label={details.finalLabel}
          leadingText=""
          trailingText={monitaryFormat(details.finalAmount)}
          trailingColor={details.finalAmountColor}
          labelColor={details.finalLabelColor}
        />
      </>
    </div>
  );
};

BillDetails.propTypes = {
  bill: PropTypes.object.isRequired
};

export default BillDetails;
