import React from 'react';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import SettingsIcon from '@material-ui/icons/Settings';
import routes from '../../../constants/routes';
const paymentNavItems = [
  {
    text: 'Your Premium',
    path: routes.payment.BASE,
    icon: <MonetizationOnIcon />
  },
  {
    text: 'Manage Autopay',
    path: routes.payment.SETTINGS,
    icon: <SettingsIcon />
  }
];

export default paymentNavItems;
