import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import paymentHistoryStatuses from '../../../constants/paymentHistoryStatuses';
import { getDateObj } from '../../../utils/date-utils';
import { monitaryFormat } from '../../../utils/format-utils';

export const billStatuses = {
  unpaid: 'UNPAID',
  coveredByTaxCredit: 'COVERED_BY_TAX_CREDIT',
  paymentPending: 'PAYMENT_PENDING',
  paid: 'PAID'
};

export const billStatusDisplayOptions = {
  [billStatuses.unpaid]: {
    backgroundColor: '#E8F5FE',
    textColor: '#000',
    statusLabel: 'YOU OWE',
    dateLabel: 'PAYMENT DUE',
    statusValue: null,
    Icon: null,
    displayPaymentBtn: true,
    displayDate: true
  },
  [billStatuses.coveredByTaxCredit]: {
    backgroundColor: '#E9FBE7',
    textColor: '#126E36',
    statusLabel: 'STATUS',
    dateLabel: null,
    statusValue: 'Paid, 100% Covered by Tax Credit',
    Icon: CheckCircleIcon,
    displayPaymentBtn: false,
    displayDate: false
  },
  [billStatuses.paymentPending]: {
    backgroundColor: '#E8F5FE',
    textColor: '#0F3C97',
    statusLabel: 'STATUS',
    dateLabel: 'PAID ON',
    statusValue: 'Processing Payment',
    statusNote: 'Note: Processing can take up to 4 business days',
    Icon: null,
    displayPaymentBtn: false,
    displayDate: true
  },
  [billStatuses.paid]: {
    backgroundColor: '#E9FBE7',
    textColor: '#126E36',
    statusLabel: 'STATUS',
    dateLabel: 'PAID ON',
    statusValue: 'Paid',
    Icon: CheckCircleIcon,
    displayPaymentBtn: false,
    displayDate: false
  }
};

export const getBillStatus = bill => {
  if (!bill || (Object.keys(bill) <= 0) || ((bill.paymentStatus && bill.paymentStatus.toUpperCase()) === 'INACTIVE')) return '';

  if ((bill.currentMonthSubsidyAmount > 0) && (bill.currentMonthSubsidyAmount === bill.currentMonthPremiumAmount)) {
    return billStatuses.coveredByTaxCredit;
  }


  if (bill.netDueAmount < 0.5 || (bill.paymentStatus && bill.paymentStatus.toUpperCase()) === billStatuses.paid) {
    return billStatuses.paid;
  }

  if (
    bill.paymentHistory.find(
      i => i.paymentStatus === paymentHistoryStatuses.PENDING
    )
  ) {
    return billStatuses.paymentPending;
  }

  return billStatuses.unpaid;
};

export const getPaidOnDate = bill => {
  if (!bill || !bill.paymentHistory) return null;

  const mostRecentDate = new Date(
    Math.max(...bill.paymentHistory.map(e => new Date(e.paymentCreateDate)))
  );

  return getDateObj(mostRecentDate);
};

export const getDetailsDisplayOptions = bill => {
  if (!bill || !bill.paymentHistory) return { lineItems: [] };

  const status = getBillStatus(bill);

  const initial = {
    finalLabelColor: null,
    finalLabel: 'Amount You Owe',
    finalAmountColor: null,
    finalAmount: bill.netDueAmount,
    lineItems: []
  };

  //Covered by tax credit
  if (status === billStatuses.coveredByTaxCredit) {
    return {
      ...initial,
      finalLabel: 'You Owed',
      finalAmountColor: '#126E36'
    };
  }

  // Paid in full
  if (status === billStatuses.paid) {
    return {
      ...initial,
      finalAmount: 0,
      lineItems:
        bill.netDueAmount != 0
          ? [
              {
                label: 'Invoice Adjustment',
                leadingText: 'Your invoice was reduced by ',
                trailingText: monitaryFormat(bill.netDueAmount),
                highlightColor: '#126E36',
                date: '',
                labelColor: null,
                helpText: ''
              }
            ]
          : []
    };
  }

  if (status === billStatuses.paymentPending) {
    const totalProcessingAmount = bill.paymentHistory
      .filter(i => i.paymentStatus === paymentHistoryStatuses.PENDING)
      .reduce((prev, curr) => prev + curr.paymentAmount / 100, 0);

    return {
      finalLabelColor: '#0F3C97',
      finalLabel: 'You Paid (Processing)',
      finalAmountColor: '#0F3C97',
      finalAmount: totalProcessingAmount,
      lineItems: []
    };
  }
  //default
  return { ...initial };
};
