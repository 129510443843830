import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import PeopleIcon from '@material-ui/icons/People';
import routes from '../../../constants/routes';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  list: {
    marginRight: '12px'
  },
  tabs: {
    margin: '16px 0px'
  },
  tab: {
    color: theme.palette.text.secondary
  },
  listItem: {
    borderRadius: '3px',
    '&$selected': {
      backgroundColor: theme.palette.secondary.main,
      color: '#fff',
      '& > div > svg': {
        color: '#fff'
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.main
      }
    }
  },
  selected: {}
}));

export const tabOptions = [
  {
    text: 'PLAN STATUS',
    path: routes.medical.BASE,
    icon: <DonutLargeIcon />
  },
  {
    text: "WHAT'S COVERED",
    path: routes.medical.COVERAGE,
    icon: <DoneAllIcon />
  },
  {
    text: 'COVERED MEMBERS',
    path: routes.medical.MEMBERS,
    icon: <PeopleIcon />
  }
];

const MedicalPlanTabs = ({ hasFamilyInPlan, history }) => {
  const classes = useStyles();

  return (
    <Tabs
      className={classes.tabs}
      variant="fullWidth"
      indicatorColor="secondary"
      textColor="secondary"
      aria-label="nav tabs"
      value={history.location.pathname}
      onChange={(_, value) => history.push(value)}
    >
      {tabOptions.map(i =>
        i.text === 'COVERED MEMBERS' ? (
          hasFamilyInPlan ? (
            <Tab
              key={i.path}
              icon={i.icon}
              className={classes.tab}
              component="button"
              label={i.text}
              value={i.path}
            />
          ) : null
        ) : (
          <Tab
            key={i.path}
            icon={i.icon}
            className={classes.tab}
            component="button"
            label={i.text}
            value={i.path}
          />
        )
      )}
    </Tabs>
  );
};

MedicalPlanTabs.propTypes = {
  hasFamilyInPlan: PropTypes.bool,
  history: PropTypes.object.isRequired
};

export default MedicalPlanTabs;
