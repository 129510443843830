import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPharmacyPlanOutOfPocketCost } from '../../../../redux/planDetails/actions';
//import { monitaryFormat } from '../../../../utils/format-utils';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '32px 0px 64px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '0px'
    }
  },
  pocketsView: {
    paddingTop: '32px'
  },
  pocketsDataView: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '244px',
    height: '144px',
    borderRadius: '8px',
    marginTop: '16px',
    padding: '16px',
    backgroundColor: '#FFFFFF',
    boxShadow:
      '0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.10)'
  },
  pocketsPriceView: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  }
}));

const PharmacyOutofPocketCosts = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { rxCost } = useSelector(state => state.planDetails);

  useEffect(() => {
    dispatch(fetchPharmacyPlanOutOfPocketCost());
  }, [dispatch]);

const benefitServicePBM = rxCost && rxCost?.benefits?.filter(b=>b.description == 'PBM Exchange Values');

  const descriptions = [
    {
      title:
        'Your prescription cost is based on the type of medication you use.',
      description:
        'If you select a generic medication, you pay a set fee, or copay. If you fill your prescription with a brand drug (preferred or non-preferred), you pay a percentage of the cost, or coinsurance. Specialty drugs also have copays.'
    },
    {
      title:
        'The copay and coinsurance amounts count toward your out-of-pocket maximum.',
      description: `Once you reach your out-of-pocket maximum 
      (${
        benefitServicePBM &&
        (benefitServicePBM[0]?.services[0]?.individualOutOfPocket || '')
      } individual / ${
        benefitServicePBM &&
        (benefitServicePBM[0]?.services[0]?.familyOutOfPocket || '')
      } family), your prescriptions are covered 100% for the remainder of the year.`
    }
  ];

  const pocketData = [];

  Object.keys(rxCost).length !== 0 &&
    rxCost.benefits.forEach((benifit, index) => {
      if (index > 0) {
        pocketData.push({
          type: benifit.description.replace(' Brand', ''),
          price:
            Math.round(benifit.services[0].coPay) === 0
              ? Math.round(benifit.services[0].coInsurance) + '%'
              : '$' + Math.round(benifit.services[0].coPay),
          description:
            Math.round(benifit.services[0].coPay) === 0
              ? 'Coinsurance after deductible'
              : 'Copay'
        });
      }
    });

  return (
    <Grid container className={classes.root}>
      <Typography variant="h3">Ascension Rx Costs</Typography>

      <Grid container item spacing={2} className={classes.pocketsView}>
        {pocketData.map((pd, idx) => (
          <Grid container item xs={12} sm={6} xl={3} justify="center" key={idx}>
            <div className={classes.pocketsDataView}>
              <Typography>{pd.type}</Typography>
              <Typography variant="h3" color="primary">
                {pd.price}
              </Typography>
              <Typography variant="body2">{pd.description}</Typography>
            </div>
          </Grid>
        ))}
      </Grid>

      {descriptions.map((content, idx) => (
        <div key={idx} style={{ marginTop: '32px' }}>
          <Typography variant="h5"> {content.title} </Typography>
          <Typography variant="body2"> {content.description} </Typography>
        </div>
      ))}
    </Grid>
  );
};

export default PharmacyOutofPocketCosts;
