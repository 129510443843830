import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { updateAutopaySetupOff } from '../../../../redux/autopay/actions';
import AutopayAgreementPop from '../AutopayAgreementPopup';


const useStyles = makeStyles(() => ({
    sectionWrapper: {
        backgroundColor: '#ffffff',
        border: '1px solid #E8EAEC',
        boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.07), 0px 1px 10px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: 4,
        padding: 30
    },
    sectionTitle: {
        fontFamily: 'HCo Whitney SSm',
        fontSize: 24,
        fontWeight: '600',
        margin: '10px 10px 10px 0px'
    }
}));

const AutoPaySwitch = withStyles({
    root: {
        width: 83,
        height: 44,
        padding: 8,
        '& > span': {
            color: '#424242',
            '&.Mui-checked': {
                transform: 'translateX(36px)',
                color: '#1976D2'
            },
            '&.Mui-checked + span': {
                opacity: '1 !important',
                backgroundColor: 'rgba(30, 105, 210, 0.38) !important'
            }
        },
        '& > span:last-child': {
            borderRadius: 20,
            fontFamily: 'Hco Whitney SSm, arial',
            fontSize: 14,
            fontWeight: 600,
            '&:before, &:after': {
                content: '""',
                position: 'absolute',
                top: '48%',
                transform: 'translateY(-50%)',
                width: 16,
                height: 16
            },
            '&:before': {
                left: 17,
                content: '"On"',
                color: 'rgba(0, 46, 111, 1)'
            },
            '&:after': {
                content: '"Off"',
                right: 24,
                color: '#FFFFFF'
            },
        },
        '& span > span > span': {
            boxShadow: 'none',
            width: 36,
            height: 36,
            margin: -5,
            padding: 0
        },
    }
})(Switch);

const AutopaySection = ({ setAutopaySetupEnable, autopaySetupEnable, handleOpenModal, isAutoPayLoading, isAutoPay, paymentSourceIsLoading }) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
//    const [outstandingAmountAllowSwitch, setOutstandingAmountAllowSwitch] = React.useState(false);
    const [openAgreementPopup, setOpenAgreementPopup] = React.useState(false);
//    const bill = useSelector(state => state?.payments?.billingSummary);
//    const isOutstandingAmount = bill?.priorOutstandingAmount > 0;


    // React.useEffect(() => {
    //     setOutstandingAmountAllowSwitch(false);

    //     switch (isAutoPay) {

    //         case true:
    //             setOutstandingAmountAllowSwitch(true);
    //             break;
    //         case false || undefined:
    //             if (isOutstandingAmount == true) {
    //                 setOutstandingAmountAllowSwitch(false);
    //             } else {
    //                 setOutstandingAmountAllowSwitch(true);
    //             }
    //             break;
    //     }
    // }, [isAutoPay, isOutstandingAmount]);

    const handleCloseAgreementPopup = () => {
        setAutopaySetupEnable(false);

        setOpenAgreementPopup(false);
    };

    const handleAutopayChange = () => {
        if (autopaySetupEnable === true) {
            if (localStorage.getItem('autopaySetupModalVisibilityCheck') === 'true') {
                setAutopaySetupEnable(false);
                dispatch(updateAutopaySetupOff());
            } else {
                handleOpenModal(true);
            }
        } else {
            setOpenAgreementPopup(true);
        }
    };

    const handleAutopayOnWithAgreement = () => {
        setAutopaySetupEnable(true);
        const autopaySetupNavObj = {
            pathname: '/autopay_setup',
            state: { isCustomerAcceptsAutopayTermsAndConditions: true }
        };
        history.push(autopaySetupNavObj);
    };

    React.useEffect(() => {
        setAutopaySetupEnable(isAutoPay);
    }, [isAutoPay]);


    return (
        <Box>
            <Box className={classes.sectionWrapper}>
                <Box display="flex" alignItems="center" justifyContent="flex-start">
                    <Typography className={classes.sectionTitle} variant='h5'>Autopay is currently:</Typography>
                    <AutoPaySwitch color='primary' disabled={paymentSourceIsLoading || isAutoPayLoading} checked={autopaySetupEnable} onChange={handleAutopayChange} />
                </Box>
            </Box>
            <AutopayAgreementPop
                handleAutopayChange={handleAutopayOnWithAgreement}
                handleClose={handleCloseAgreementPopup}
                open={openAgreementPopup}
            />
        </Box>

    );
};

AutopaySection.propTypes = {
    autopaySetupEnable: PropTypes.bool,
    setAutopaySetupEnable: PropTypes.func,
    handleOpenModal: PropTypes.func,
    isAutoPayLoading: PropTypes.bool,
    isAutoPay: PropTypes.bool,
    paymentSourceIsLoading: PropTypes.bool
};

export default AutopaySection;
