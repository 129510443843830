import * as amplitude from '@amplitude/analytics-browser';

export const initAnalytics = (apiKey, userData) => {
    amplitude.init(apiKey, userData?.idTokenClaims?.primaryEmail, {
        defaultTracking: true
    });
};

export const analyticsTrackEvent = (eventName, eventProperties) => {
    amplitude.track(eventName, eventProperties);
};

export const analyticsTrackRouteClickEvent = (eventName, eventProperties) => {
    amplitude.track(getMappedClickEventPathName(eventName), eventProperties);
};

export const analyticsTrackClickEvent = (eventName, eventProperties) => {
    amplitude.track(eventName, eventProperties);
};

export const analyticsSetUserId = (userId) => {
    amplitude.setUserId(userId);
};

export const analyticsReset = () => {
    amplitude.reset();
};

export const getMappedPathName = (path) => {
    if (!path || (typeof path !== 'string')) return;

    let mappedPathName = path == '/' ? path.toLowerCase() : path.replace(/\//, '')?.toLowerCase().replace(/-/g, '_');
    switch (mappedPathName) {
        case '/':
            return 'home';
        case 'medical':
            return 'medicalbenefits_planstatus';
        case 'medical/coverage':
            return 'medicalbenefits_whatscovered';
        case 'pharmacy':
            return 'rxbenefits_planstatus';
        case 'pharmacy/costs':
            return 'rxbenefits_OOP';
        case 'settings':
            return 'settings_account';
        default:
            return mappedPathName;
    }
};

export const getMappedClickEventPathName = (path) => {
    if (!path || (typeof path !== 'string')) return;

    let mappedPathName = path == '/' ? path.toLowerCase() : path.replace(/\//, '')?.toLowerCase().replace(/-/g, '_');
    switch (mappedPathName) {
        case '/':
            return 'home_dashboard_header_clicked';
        case 'medical':
            return 'home_medicalbenefits_header_clicked';
        case 'pharmacy':
            return 'home_rxbenefits_header_clicked';
        case 'active_direct':
            return 'home_activefit_header_clicked';
        case 'truhearing':
            return 'home_truhearing_header_clicked';
        case 'https:/www.ascensionpersonalizedcare.com/find_a_doctor':
            return 'home_findcare_header_clicked';
        case 'support':
            return 'home_support_header_clicked';
        case 'settings':
            return 'home_settings_header_clicked';
        case 'claims':
            return 'home_claims_header_clicked';
        case 'payment':
            return 'home_payments_header_clicked';
        case 'logout':
            return 'home_logout_clicked';
        default:
            return mappedPathName;
    }
};

export const getMappedEventCategory = (eventName) => {
    switch (eventName) {
        case 'home_viewed':
            return 'Home Page Pathing';
        case 'profile_manage_viewed':
            return 'Global Pathing';
        case 'benefits_overview_viewed':
            return 'Plan Benefits Pathing';
        case 'benefits_whatscovered_viewed':
            return 'Plan Benefits Pathing';
        case 'benefits_extrabenefits_viewed':
            return 'Plan Benefits Pathing';
        case 'idcards_viewed':
            return 'Plan Benefits Pathing';
        case 'support_viewed':
            return 'Support Pathing';
        default:
            return;
    }
};