import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  root: {
    marginLeft:'30px',
    '& * li': {
      margin: '8px 0px'
    }
  }
});

//abandon all hope ye who enter here
const PrivacyPolicy = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <section>
        <h2>PRIVACY POLICY</h2>
        <b>
          THE PORTAL IS INTENDED FOR USE BY ADULTS. CHILDREN UNDER AGE 18 SHOULD
          NOT USE THE PORTAL
        </b>
        <p>
          Ascension Personalized Care and its subsidiaries and affiliates (“
          <b>Ascension Personalized Care</b>” or “We” or “Us” or “Our”), the
          developer of the Ascension Personalized Care Portal (the “
          <b>Portal</b>
          ”), is committed to protecting Your information. We have developed
          this Portal Privacy Policy (“<b>Privacy Policy</b>”) to tell end users
          (“You”) how We collect, use, maintain, and disclose information We
          collect from You when You use the Portal. This Privacy Policy applies
          to the Portal and all products and services offered by Ascension
          Personalized Care related to the Portal.
        </p>
        <p>
          <b>We may change the Privacy Policy</b> from time to time. Our use of
          the information described in this Privacy Policy is subject to the
          Privacy Policy in effect at the time of Our use.
        </p>
        <p>
          <b>
            By using the Portal You agree with this Privacy Policy and the way
            We collect, use, and secure information as described below. IF YOU
            DO NOT AGREE, YOU ARE NOT AUTHORIZED TO USE THE PORTAL.
          </b>
        </p>
        <section>
          <h3>GENERAL</h3>
          <p>
            This Portal is made available to You for purposes of managing health
            insurance benefit information. Any protected health information You
            share with Us through the Portal is protected by and subject to Our
            HIPAA Notice of Privacy Practices, which is available here:
            https://healthcare.ascension.org/NPP. If We share Your protected
            health information with third parties, We will do so in accordance
            with HIPAA and applicable law. This means that We might ask You to
            sign a HIPAA authorization form before We share Your information
            with a third party.
          </p>
        </section>
        <section>
          <h3>Information We Collect</h3>
          <section>
            <h4>
              <i>Information You Provide to Ascension Personalized Care</i>
            </h4>
            <p>
              We collect personal identification information that You provide to
              Us when You:
            </p>
            <ul>
              <li>Enroll in a Personalized Care plan</li>
              <li>Sign up to use the Portal</li>
              <li>Fill out a form on the Portal</li>
              <li>
                Engage in other activities, services, features or resources We
                make available on the Portal that request Your information.
              </li>
            </ul>
            <p>
              Information You provide to Us includes, but is not limited to:
            </p>
            <ul>
              <li>Name</li>
              <li>Date of birth</li>
              <li>Last 4 digits of Your social security number</li>
              <li>Member/Subscriber ID</li>
              <li>
                Contact information, such as:
                <ul>
                  <li>Address</li>
                  <li>Phone number</li>
                  <li>E-mail address</li>
                </ul>
              </li>
              <li>
                Health plan details, such as
                <ul>
                  <li>Coverage, including deductibles</li>
                  <li>Claims details</li>
                  <li>Medical & Pharmacy ID cards</li>
                </ul>
              </li>
              <li>
                Medical information, including
                <ul>
                  <li>Procedures</li>
                  <li>Weight</li>
                  <li>Height</li>
                  <li>Symptoms</li>
                  <li>Health care provider visits</li>
                  <li>Medications</li>
                  <li>Health conditions</li>
                  <li>Treatment information</li>
                </ul>
              </li>
              <li>Other information You provide to Us via the Portal.</li>
            </ul>
          </section>
          <section>
            <h4>Information Collected Automatically</h4>
            <p>
              In addition to Personal Information You provide, We receive and
              store certain types of information automatically (“Usage
              Information”) whenever You use the Portal. Usage Information is
              necessary to allow Your mobile device to operate the Portal and to
              communicate on the Internet. Usage Information can include Your
              Internet address and Internet service provider, details regarding
              the equipment and applications You use, the date and time You
              access the Portal.
            </p>
            <h4>Cookies, Device Identifiers and Other Tracking</h4>
            <p>
              We may use cookies and other tracking devices on the Portal.
              Cookies are small data files that are sent to your browser and
              placed on your computer&apos;s hard drive when you visit a
              website. The information a cookie contains is set by a
              website&apos;s server and can be used when the user visits or
              returns to the website. Cookies are designed to remember things
              that a User has done while on a website in the past, which can
              include logging in to the Portal or clicking on links. The use of
              cookies allows us to measure site activity.
            </p>
            <p>
              You may be able to adjust Your browser settings to block cookies
              or to notify You before a cookie is set. Please refer to Your
              browser Help instructions to learn more about cookies and how to
              manage their use.
            </p>
            <p>
              Some of the services provided through the Portal may require
              cookies to work properly.
            </p>
            <p>
              If you block cookies, certain functionalities of the Portal may
              not be available to You, and We may not be able to provide some
              services to You.
            </p>
            <p>
              Because the Portal only collects the information necessary for its
              performance, you cannot limit the information that you provide to
              us if you want to use the Solution.
            </p>
          </section>
          <section>
            <h4>How We Use This Information</h4>
            <p>
              We may use the information described in this Privacy Policy to:
            </p>
            <ul>
              <li>
                To provide suggested resources to You.
                <ul>
                  <li>
                    <i>
                      We may use Your information to generate suggested
                      resources related to Your available benefits.
                    </i>
                  </li>
                </ul>
              </li>
              <li>
                To provide information about services and providers located near
                you.
                <ul>
                  <li>
                    <i>
                      If You have agreed to provide Us with your location
                      information, We may provide information about health care
                      providers and services that are near to You.
                    </i>
                  </li>
                </ul>
              </li>
              <li>
                To improve user experience.
                <ul>
                  <li>
                    <i>
                      We may use information You and other Users provide to Us
                      to understand how our Users individually and/or as a group
                      use the Portal.
                    </i>
                  </li>
                </ul>
              </li>
              <li>
                To improve the Portal.
                <ul>
                  <li>
                    <i>
                      We may use feedback You provide to improve the Portal.
                    </i>
                  </li>
                </ul>
              </li>
              <li>
                To verify your identity.
                <ul>
                  <li>
                    <i>
                      We may require personal information to confirm You are the
                      user You say You are.
                    </i>
                  </li>
                </ul>
              </li>
              <li>
                For the purposes specified at the time of collection.
                <ul>
                  <li>
                    <i>
                      There may be other purposes that are specified when the
                      information is collected.
                    </i>
                  </li>
                </ul>
              </li>
              <li>
                As otherwise required or permitted by law.
                <ul>
                  <li>
                    <i>
                      There may be times when the law permits or requires Us to
                      use information for other purposes, such as to comply with
                      Our legal obligations.
                    </i>
                  </li>
                </ul>
              </li>
            </ul>
          </section>
          <section>
            <h4>Sharing Information</h4>
            <p>
              We will not sell, trade, or rent the information described in this
              Privacy Policy to anyone, but We may share such information with
              others, such as Our subsidiaries, affiliates, and service
              providers as necessary to operate Our business, and as permitted
              by law.
            </p>
            <p>We may share information about Users of the Portal:</p>
            <ul>
              <li>
                With Our business partners, trusted affiliates and advertisers
                to help provide services that are described above.
              </li>
              <li>
                With third party service providers that help Us operate Our
                business and the Portal or administer activities on Our behalf.
              </li>
              <li>
                With companies that provide services related to the Portal such
                as Portal development, hosting services and the Portal stores.
              </li>
              <li>
                With companies that are disclosed to You at the time You provide
                the Information.
              </li>
            </ul>
            <p>
              We may aggregate Usage Information in a non-identifiable manner
              and use it for Our business purposes unrelated to Your use of the
              Portal. For example, We may aggregate data for product development
              and improvement activities and disclose such aggregated data to
              third parties.
            </p>
            <p>
              We may also share any information as required by law or by a court
              order, or to prevent or investigate a crime. Also, We may share
              any information when We believe, in good faith, that sharing is
              necessary to protect Your safety or the safety of others, to
              protect Our rights, to investigate or prevent fraud, or to respond
              to a government request. We may share information, if necessary,
              to enforce this Privacy Policy or Terms of Use of the Portal. We
              reserve the right to report to appropriate law enforcement or
              government agencies activities that We, in good faith, believe to
              be harmful or unlawful, with or without providing notice to You.
            </p>
          </section>
          <section>
            <h4>Third-Party Links</h4>
            <p>
              The Portal may contain links to websites or other content We do
              not control. These links are for Your convenience only, and We do
              not endorse or make any promises or guarantees about such other
              websites. We encourage You to review the privacy policies terms of
              use posted on these websites carefully. We are not responsible for
              the privacy policies or the content of any other websites.
            </p>
          </section>
          <section>
            <h4>Security</h4>
            <p>
              We recognize that health is a sensitive topic. We protect
              information about You by using reasonable security measures that
              help assure that We commit to collect, process and use personal
              information in accordance with applicable laws, including HIPAA.
              These security measures include physical security measures, like a
              locked data center, administrative security measures, like
              policies and rules about how Our employees use data and who is
              permitted to handle sensitive data, and technological measures,
              like encryption. No patient information, or PHI, collected by the
              Portal will be stored outside of Our systems that store sensitive
              data use strict processes and procedures to mitigate unauthorized
              access and activity, including access security controls. By using
              several different kinds of security measures, We try to protect
              information in Our control. However, no security is perfect, and
              no security system can prevent all security breaches. It is not
              possible to keep data encrypted when it is in use, and it is not
              possible to guarantee that any transfer of information over the
              Internet or electronic data storage is 100% secure. As a result,
              We cannot guarantee the absolute security of Your information.
              While We have implemented security measures, You transmit
              information, including Your health information, to Us at Your own
              risk. Carefully consider the type of information You wish to
              provide to the Portal.
            </p>
          </section>
          <section>
            <h4>Children’s Data</h4>
            <p>
              This Portal is intended to be used only by individuals over the
              age of 18. We do not intentionally collect information from
              children under 13 years old. Parents, please use the “Contact Us”
              information below to give permission.
            </p>
            <p>
              If a parent or guardian becomes aware that a child under 13 years
              old has provided Personal Information to Us without their consent,
              please contact Us through the “Contact Us” below. We will promptly
              permanently delete any information from children under 13 years of
              age.
            </p>
          </section>
          <section>
            <h4>California Do-Not-Track Notice</h4>
            <p>
              Many internet browsers have “Do Not Track” features which, when
              turned on, send a signal (the “DNT signal”) to websites and
              applications a user visits that indicates the user does not want
              to be tracked. Because there is not yet a common understanding of
              how to interpret DNT signals, and there is no common definition of
              “tracking,” we do not currently respond to DNT signals. You can
              learn more about Do-Not-Track at{' '}
              <a href="https://allaboutdnt.com/" target="__Blank">
                https://allaboutdnt.com/
              </a>
              .
            </p>
          </section>
          <section>
            <h4>Your California “Shine the Light” Privacy Rights</h4>
            <p>
              Upon Your request, submitted to steven.long@ascension.org, We
              will advise You of any categories of personal information that We
              disclose to third parties, and (to the extent We know) whether
              those third parties use that information for their direct
              marketing purposes. If We provide Your personal information to a
              third party, We do not allow that third party to use that
              information for marketing their services, unless You have
              consented to that use.
            </p>
          </section>
          <section>
            <h4>Updating Your Information</h4>
            <p>
              If Your information needs to be updated, corrected, or deleted,
              please notify Us using the contact information provided below.
              Your personal information can only be deleted in accordance with
              Our data retention policy, and as permitted by law. Further,
              personal information may remain in databases, access logs, backup
              media, and other records. We cannot update or remove information
              from records of third parties.
            </p>
          </section>
          <section>
            <h4>Changes To This Privacy Policy</h4>
            <p>
              This Privacy Policy may be updated or changed from time to time
              for any reason and at Our sole discretion. It is a good idea to
              review this Privacy Policy frequently for any such changes. Your
              continued use of the Portal means You accept any changes. You
              acknowledge and agree that it is Your responsibility to review
              this Privacy Policy periodically and become aware of
              modifications. We will put a revision date on the Privacy Policy
              so that You can tell when it was most recently changed.
            </p>
          </section>
          <section>
            <h4>International Access; Acceptance of Policy</h4>
            <p>
              You are not permitted to use this Portal outside of the United
              State of America. If You are using the Portal when outside the
              United States of America, Your information will be transferred to,
              stored, and processed in the United States. The privacy laws of
              the United States may not be as protective as those in other
              jurisdictions. By using the Portal You acknowledge that We collect
              and process Your data as described in this Privacy Policy. We
              collect and use information as reasonably necessary to fulfill
              Your requests for suggested support resources, to operate Our
              business, and to comply with law. You acknowledge that these are
              acceptable reasons to collect and process Your data. You also
              consent to the transfer of Your information to the United States
              and the use and disclosure of Your information as described in
              this Privacy Policy. If You want Us to correct, delete, or no
              longer process Your data, please contact Us at the &quot;Contact
              Us&quot; address below. You understand and agree that a request
              for Us to delete or no longer process Your data means that You can
              no longer use the Portal as it is necessary for Us to generate
              suggested support resources on the Portal.
            </p>
          </section>
          <section>
            <h4>Contacting Us</h4>
            <p>
              We would welcome Your feedback regarding the Portal. For example,
              the Portal may present accessibility and usability issues for some
              individuals, or You might have any questions about this Privacy
              Policy or Your interactions with the Portal. If You have questions
              about this Privacy Policy, please contact Us at the following:
            </p>
            <p style={{ margin: 0 }}>
              <b>Ascension Personalized Care</b>
            </p>
            <p style={{ margin: 0 }}>Attn: Terms of Use Contact Form</p>
            <p style={{ margin: 0 }}>Email: steven.long@ascension.org</p>
          </section>
        </section>
      </section>
      <section>
        <h2>TERMS OF USE</h2>
        <b>
          PLEASE READ THESE TERMS OF USE AND PRIVACY POLICY (THE “AGREEMENT”)
          CAREFULLY BEFORE USING THIS APP
        </b>
        <p>
          This Agreement is a legal agreement between You, for Yourself and Your
          heirs and assigns (“You” or “User”), and Ascension Personalized Care
          and its subsidiaries and affiliates (“Ascension Personalized Care”
          “We” or “Us” or “Our”) regarding Your use of the Ascension
          Personalized Care Portal and any other software application for which
          this Agreement is presented as a condition of use (the “Portal”). By
          clicking below on &quot;<b>ACCEPT</b>&quot; You agree to be bound by
          the terms and conditions of this Agreement. If You do not agree to the
          terms and conditions of this Agreement, You are not authorized to use
          the Portal and You must immediately delete it from Your device.
        </p>
        <p>
          Ascension Personalized Care may revise and update this Agreement at
          any time. Your continued usage of the Portal means You have accepted
          those changes. The most current version will be available when You log
          into the Portal and we will include the date of the last revision. If
          Ascension Personalized Care makes material changes to the Agreement,
          then You will be required to acknowledge Your agreement to such
          changes prior to continued use of the Portal.
        </p>
        <section>
          <h4>General; Scope of Services</h4>
          <p>
            We have provided You with access to this Portal to help You better
            understand Your health benefits and to provide You with resources to
            help You coordinate Your health care. This Portal is designed to
            provide benefits information to Users about services that could be
            helpful to members. The information in the Portal may not be
            consistent with Your latest treatment or medication plans. The
            Portal is not guaranteed to be error-free or uninterrupted. We are
            not responsible for any errors or inaccuracies in the information
            held by the Portal. While the Portal seeks to be reliable, You
            should be aware that You have the option of contacting Your health
            plan, Ascension Personalized Care, or Your health care provider.
          </p>
          <p>
            The Portal may be used by Users on behalf of Patients. A “Patient”
            is an individual who receives health care benefits from Us or any of
            Our affiliates or subsidiaries. In some cases, the User is a
            Patient; in some cases, the User is a friend or relative of a
            Patient that is involved in the care of a Patient. The Portal is
            intended to help Users and Patients coordinate their health care
            benefits.
          </p>
          <p>
            Keeping Your personal data safe is important to Us. Any protected
            health information You provide to Us through the Portal is protected
            by and subject to Our HIPAA Notice of Privacy Practices.
          </p>
          <p>
            We subscribe to the Ethical and Religious Directives of the Catholic
            Church and do not provide any medical procedures, advice, benefits,
            or information that is inconsistent with these Directives.
          </p>
        </section>
        <section>
          <h4>Not Medical Advice</h4>
          <p>
            The Portal has been developed using care and in accordance with
            existing best practices for benefits coordination. However, the
            Portal is not intended to be a substitute for professional medical
            advice, diagnosis or treatment, and does not constitute medical or
            other professional advice. The information provided through this
            Portal is designed to support, not replace, the relationship that
            exists between the Patient and the Patient&apos;s physicians.
          </p>
          <p>
            We do not recommend or endorse any specific tests, physicians,
            products, procedures, opinions, or other information that may be
            mentioned on the Portal or by any other electronic means. Reliance
            on any information provided by the Portal is solely at Your own
            risk.
          </p>
          <p>
            Never disregard professional medical advice or delay in seeking it
            because of something You have read or seen on the Portal. It is
            important to remember that each person can react differently to
            medicine. If You have any questions about Your medicines, Your care,
            or Your health conditions, consult Your doctor.
          </p>
        </section>
        <section>
          <h4>License Grant & Intellectual Property</h4>
          <ul>
            <li>
              THE PORTAL IS LICENSED, NOT SOLD. Subject to all other terms and
              conditions of this Agreement, Ascension Personalized Care hereby
              grants to You a nonexclusive, non-transferable, and
              non-sublicensable license to use and access the Portal. The Portal
              is intended for use only in the United States.
            </li>
            <li>
              You agree not to modify, rent, lease, loan, sell, distribute, or
              create derivative works based on the Portal in any manner, and You
              shall not use or exploit the Portal in any unauthorized way
              whatsoever.
            </li>
            <li>
              You must access and use the Portal in accordance with all
              applicable laws and regulations. If You violate this Agreement,
              Your license and right to use this Portal is automatically and
              immediately revoked.
            </li>
            <li>
              This Portal contains proprietary information and material that is
              owned by Ascension Personalized Care and its subsidiaries,
              affiliates, and licensors, and other third parties (individually
              and collectively, the “Licensors”), and is protected by applicable
              intellectual property and other laws, including but not limited to
              copyright. You agree that You will not use such proprietary
              information or materials in any way whatsoever except in
              compliance with this Agreement. You agree not to remove, alter or
              obscure any copyright, trademark, patent or other intellectual
              property or proprietary rights notices from the Portal.
            </li>
          </ul>
        </section>
        <section>
          <h4>Third-Party Content & Third-Party Websites</h4>
          <ul>
            <li>
              The Portal may contain third-party content or provide links to
              third-party websites. You agree We are not responsible for
              third-party content or third-party websites, and do not make any
              endorsements, representations or warranties regarding such
              third-party content or third-party websites. Your use of
              third-party content and third-party websites is at Your own risk
              and subject to the third-party’s terms and conditions, as
              applicable.
            </li>
          </ul>
        </section>
        <section>
          <h4>Terms of Use</h4>
          <ul>
            <li>
              Use of the Portal may be affected by technical and equipment
              malfunctions, congestion on communications networks, availability
              of the Internet, available signal on any electronic device, or a
              combination thereof. You agree that alternative means of
              communications may be more effective in some situations.
            </li>
            <li>
              If You access this Portal on your mobile device, You acknowledge
              that the terms of agreement with Your respective mobile network
              provider (“Mobile Provider”) will continue to apply when using the
              Portal. As a result, You may be charged by the Mobile Provider for
              access to network connection services for the duration of the
              connection while accessing the Portal or any such third-party
              charges as may arise. You accept responsibility for any such
              charges that arise.
            </li>
          </ul>
        </section>
        <section>
          <h4>General Disclaimer</h4>
          <ul>
            <li>
              YOU ACKNOWLEDGE AND AGREE THAT THE PORTAL IS NOT ERROR-FREE; THAT
              THE PORTAL IS INTENDED TO BE USED AS A TOOL TO ASSIST YOU IN THE
              COORDINATION OF CARE FOR A PATIENT, AND IS NOT DESIGNED TO BE USED
              ALONE OR TO REPLACE YOUR JUDGMENT OR A PROFESSIONAL’S JUDGMENT;
              THAT THE INFORMATION DISPLAYED IN THE PORTAL MAY NOT INCLUDE A
              PATIENT’S COMPLETE CLAIMS, BENEFITS, OR MEDICAL RECORD OR HISTORY;
              AND THAT YOU ARE EXCLUSIVELY RESPONSIBLE FOR ANY ACTION YOU TAKE
              AS A RESULT OF ACCESS TO OR USE OF THE PORTAL.
            </li>
          </ul>
        </section>
        <section>
          <h4>Liability of Ascension Personalized Care</h4>
          <ul>
            <li>
              YOU ASSUME ALL RISK OF USING THE PORTAL AND ANY INFORMATION OR
              MATERIALS INCLUDED OR MADE AVAILABLE THROUGH THE PORTAL. THE
              PORTAL IS PROVIDED “AS-IS” AND “AS AVAILABLE,” WITH ALL FAULTS AND
              WITHOUT ANY EXPRESS OR IMPLIED WARRANTY OF ANY KIND. ASCENSION
              PERSONALIZED CARE EXPRESSLY DISCLAIMS ALL WARRANTIES, INCLUDING,
              BUT NOT LIMITED TO, ANY WARRANTIES OF MERCHANTABILITY, TITLE,
              NON-INFRINGEMENT, FITNESS FOR A PARTICULAR PURPOSE, LACK OF
              VIRUSES, ACCURACY OR COMPLETENESS, RESULTS, AND LACK OF
              NEGLIGENCE.
            </li>
            <li>
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
              WILL ASCENSION PERSONALIZED CARE, ITS REPRESENTATIVES, OR AGENTS
              BE LIABLE TO YOU FOR ANY DAMAGES OF ANY KIND, INCLUDING WITHOUT
              LIMITATION ANY DIRECT, SPECIAL, INCIDENTAL, INDIRECT, OR
              CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING, BUT NOT LIMITED TO,
              DAMAGES FOR LOSS OF PROFITS, LOSS OF CONFIDENTIAL OR OTHER
              INFORMATION, BUSINESS INTERRUPTION, PERSONAL INJURY, LOSS OF
              PRIVACY, FAILURE TO MEET ANY DUTY (INCLUDING OF GOOD FAITH OR OF
              REASONABLE CARE), NEGLIGENCE, AND ANY OTHER PECUNIARY OR OTHER
              LOSS WHATSOEVER, IN EACH CASE ARISING OUT OF OR IN ANY WAY RELATED
              TO THIS AGREEMENT OR YOUR USE OF OR INABILITY TO USE THE PORTAL
              EVEN IF ASCENSION PERSONALIZED CARE KNEW OF, OR SHOULD HAVE KNOWN
              OF, THE POSSIBILITY OF SUCH DAMAGES. IF YOU ARE DISSATISFIED WITH
              ANY PORTION OF THE PORTAL OR WITH ANY OF THE TERMS OF THIS
              AGREEMENT, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING
              THE PORTAL. TO THE EXTENT LIABILITY CANNOT BE EXCLUDED OR
              DISCLAIMED, LIABILITY IS LIMITED TO $1.00 USD. THE FOREGOING
              LIMITATIONS, EXCLUSIONS, AND DISCLAIMERS WILL APPLY TO THE MAXIMUM
              EXTENT PERMITTED BY APPLICABLE LAW, EVEN IF ANY REMEDY FAILS ITS
              ESSENTIAL PURPOSE, AND WILL APPLY WHETHER SUCH DAMAGES ARISE OUT
              OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE
              AND REGARDLESS OF WHETHER SUCH DAMAGES ARE FORESEEABLE OR
              ASCENSION PERSONALIZED CARE WAS ADVISED OF THE POSSIBILITY OF SUCH
              DAMAGES.
            </li>
          </ul>
        </section>
        <section>
          <h4>Indemnity</h4>
          <ul>
            <li>
              You agree to defend, indemnify, and hold Ascension Personalized
              Care, its officers, directors, employees, agents, licensors, and
              suppliers, harmless from and against any claims, actions or
              demands, liabilities and settlements, including, without
              limitation, reasonable legal and accounting fees, resulting from,
              or alleged to result from, Your violation of this Agreement.
            </li>
          </ul>
        </section>
        <section>
          <h4>Governing Law & Jurisdiction</h4>
          <ul>
            <li>
              This Agreement is governed by the laws of the State of Missouri
              without respect to its conflict of laws principles. Exclusive
              venue for any claims arising out of or related to the Agreement or
              Your use of the Portal shall be in the state courts located in St.
              Louis County, Missouri, or federal courts in the Eastern District
              of Missouri. By using the Portal You accept the jurisdiction of
              such courts. Any claim that You may bring must be brought within
              six months of the date of the first event giving rise to the
              claim.
            </li>
            <li>
              You irrevocably and unconditionally waive any right You may have
              to a trial by jury in respect of any legal action arising out of
              or relating to this Agreement.
            </li>
            <li>
              If You are located outside the United States of America, You
              acknowledge and agree that Your data will be stored and processed
              in the United States, which may have privacy laws that are less
              protective than the laws of the country in which You are located.
              By using the Portal You consent to the processing of Your data in
              the United States for the purposes described in this Agreement,
              the Privacy Policy, and any documentation regarding the Portal and
              as permitted by law.
            </li>
          </ul>
        </section>
        <section>
          <h4>General</h4>
          <p>You promise that:</p>
          <ul>
            <li>
              You are at least 18 years of age, and You are able to agree to
              contracts and be legally bound by them. If You are not able to
              agree to and be legally bound by contracts, a parent or guardian
              that is able to be bound by contracts is agreeing to this
              Agreement on Your behalf. The person that is able to be bound is
              responsible for ensuring Your compliance with this Agreement.
            </li>
            <li>You will only access the Portal from the United States.</li>
            <li>You will be solely responsible for Your use of the Portal.</li>
            <li>
              You will keep Your Ascension Personalized Care account
              information, including Your user name and password or other
              credentials, secret, and not tell anyone what they are or let
              anyone else use them.
            </li>
            <li>
              You will tell Ascension Personalized Care promptly if You believe
              Your Ascension Personalized Care user name or password or other
              credentials are no longer secret, and You will change Your
              password immediately to protect the privacy of Your information
              and the security of the Portal.
            </li>
            <li>
              You understand and agree that any links on the Portal to other
              websites or to products, services, or merchandise not controlled
              by Ascension Personalized Care are offered by third parties, and
              that Ascension Personalized Care does not control, endorse, or
              guarantee such information, products, services or merchandise. You
              are responsible for reviewing the terms and privacy policy for any
              websites and other applications and deciding whether to agree to
              them, and We encourage You to do so.
            </li>
            <li>
              You agree that the Portal or any component of the Portal will NOT
              be used:
              <ul>
                <li>
                  In an illegal, inflammatory, defamatory, obscene, or hateful
                  manner;
                </li>
                <li>
                  To promote violence, discrimination on the basis of race,
                  religion, gender, nationality, disability, age, or similar
                  characteristics; or to infringe or breach the rights of any
                  third party;
                </li>
                <li>
                  To threaten, abuse, harass, embarrass, alarm, defraud, or
                  annoy another person;
                </li>
                <li>
                  To transmit any computer code, including (without limitation)
                  any virus, worm, Trojan, time-bomb, logger, spyware,
                  ransomware, or other harmful program or code that is intended,
                  designed, or anticipated to cause harm or disruption to
                  computers or networks, or any unsolicited promotional or
                  advertising materials or &quot;spam&quot;; or
                </li>
                <li>
                  In a manner otherwise contrary to the nature and purpose of
                  the Portal to support health benefits coordination.
                </li>
              </ul>
            </li>
            <li>
              Ascension Personalized Care may immediately terminate this
              Agreement, including all licenses granted hereunder, at any time
              with or without cause. We have the right to determine whether
              there has been a violation of this Agreement. If We determine the
              Agreement has been violated, We have the right to determine the
              action that will be taken. The action to be taken may include the
              following, or any other action We deem to be appropriate:
              <ul>
                <li>
                  Immediate termination of Your right to use the Portal. The
                  termination may be temporary or permanent.
                </li>
                <li>
                  Immediate removal of any content posted by You to the Portal.
                </li>
                <li>Issuance of a warning or other communication to You.</li>
                <li>
                  Legal proceedings against You, including seeking of damages
                  (including indemnity) recovering all costs and losses suffered
                  as a result of Your violation of this Agreement.
                </li>
                <li>Other forms of legal proceedings against You.</li>
                <li>Other forms of legal proceedings against You.</li>
              </ul>
              We will not be liable for any damages which You may suffer as a
              result of the foregoing actions. You may terminate this Agreement
              at any time by deleting the Portal from Your device.
            </li>
            <li>
              If any provision of this Agreement is found to be invalid by any
              court having competent jurisdiction, the invalidity of such
              provision shall not affect the validity of the remaining
              provisions of this Agreement, which shall remain in full force and
              effect, and to the extent necessary, a provision that comes as
              close as possible to the intention of Ascension Personalized Care
              shall be substituted for such invalid provision. No waiver of any
              provision of this Agreement shall be deemed a further or
              continuing waiver of such provision, term or condition or any
              other provision, term or condition.
            </li>
            <li>
              You may not assign or otherwise transfer this Agreement or any
              rights or obligations hereunder without Ascension Personalized
              Care’s prior written consent. All contributors to the Portal
              including third-party content, are intended third-party
              beneficiaries of the Agreement.
            </li>
            <li>
              By using the Portal You also agree to the terms of Ascension
              Personalized Care’s Privacy Policy, including any changes we may
              make to the Privacy Policy over time. A copy of the Privacy Policy
              may be found in the hyperlink in the prior sentence, or from the
              main menu in the Portal.
            </li>
            <li>
              Ascension Personalized Care may provide updates to the Portal
              including updates that modify or delete features or functionality,
              in its sole discretion. Ascension Personalized Care has no
              obligation to provide any update or fix any bugs or errors in the
              Portal. Any updates will be deemed to be part of the Portal and
              subject to this Agreement. Ascension Personalized Care may require
              You to accept a revised or replacement version of this Agreement
              in connection with certain updates, in which case You must accept
              such version prior to using such updates.
            </li>
            <li>
              The following provisions survive the expiration or termination of
              this Agreement for any reason whatsoever: General Disclaimer,
              Liability of Ascension Personalized Care, Indemnity, Governing Law
              & Jurisdiction, General, and Complete Agreement.
            </li>
          </ul>
        </section>
        <section>
          <h4>Notice and Takedown Procedured; and Copyright Agent</h4>
          <ul>
            <li>
              If You believe any materials made available by or incorporated
              into the Portal infringe Your copyright, You may request removal
              of those materials (or access thereto) by contacting the copyright
              agent identified below and providing the following information:
              <ol>
                <li>
                  Identification of the copyrighted work that You believe to be
                  infringed, including a description of the work, and the
                  location of an authorized version of the work.
                </li>
                <li>
                  Identification of the alleged infringing material and its
                  location in sufficient detail to allow us to locate the
                  material.
                </li>
                <li>
                  Your name, address, telephone number and (if available) e-mail
                  address.
                </li>
                <li>
                  A statement that You have a good faith belief that the
                  complaint of use of the materials is not authorized by the
                  copyright owner, its agent, or the law.
                </li>
                <li>
                  A statement that the information that You have supplied is
                  accurate, and that “under penalty of perjury,” You are the
                  copyright owner or are authorized to act on the copyright
                  owner’s behalf.
                </li>
                <li>
                  A signature or the electronic equivalent from the copyright
                  holder or authorized representative.
                </li>
              </ol>
            </li>
          </ul>
          <p>The agent for copyright issues relating to the Portal is:</p>
          <div style={{ marginLeft: '48px' }}>
            <p>
              <b>Ascension Personalized Care</b>
            </p>
            <p style={{ margin: 0 }}>4600 Edmundson Road</p>
            <p style={{ margin: 0 }}>St. Louis, MO 63134</p>
            <p style={{ margin: 0 }}>Attn: Legal Counsel</p>
            <p style={{ margin: 0 }}>ip@ascension.org</p>
          </div>
          <p>
            We have adopted and reasonably implemented a policy that provides
            for the termination, in appropriate circumstances, of Users that are
            repeat infringers.
          </p>
        </section>
        <section>
          <h4>Complete Agreement</h4>
          <p>
            This Agreement, including the Privacy Policy, constitutes the entire
            agreement between You and Ascension Personalized Care with respect
            to the use of the Portal.
          </p>
        </section>
        <section>
          <h4>Contacting Us</h4>
          <p>
            We would welcome Your feedback regarding the Portal. For example,
            the Portal may present accessibility and usability issues for some
            individuals, or You might have any questions about these Terms of
            Use or Your interactions with the Portal. If You have questions
            about this Privacy Policy, please contact Us at the following:
          </p>
          <p>
            <b>Ascension Personalized Care</b>
          </p>
          <div style={{ marginLeft: '48px' }}>
            <p style={{ margin: 0 }}>
              <b>Attn:</b> Terms of Use Contact Form
            </p>
            <p style={{ margin: 0 }}>
              <b>Email:</b> steven.long@ascension.org
            </p>
          </div>
        </section>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
