import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Cancel';
import Typography from '@material-ui/core/Typography';
import visaLogo from '../../../../../assets/visa_logo_rounded.svg';
import { Box } from '@material-ui/core';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: 24
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: 'rgba(0, 0, 0, 0.6)',
    },
});

const useStyles = makeStyles(() => ({
    cardDetailsWrapper: {
        backgroundColor: '#F2F2F2',
        borderTop: '1px solid #E0E0E0',
        borderBottom: '1px solid #E0E0E0',
        padding: '20px 13px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '& img': {
            width: 42,
            marginRight: 10
        }
    },
    cardDetails: {
        '& p': {
            fontWeight: 500,
            fontSize: 18,
            margin: 0,
            marginLeft: 10
        }
    },
    cardType: {
        fontWeight: 700
    },
    cardSubText: {
        color: 'rgba(0, 0, 0, 0.4)',
        marginLeft: 10,
        fontSize: 18,
        fontWeight: 500
    }
}));


const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" style={{ fontSize: 20, fontWeight: '600' }}>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});


const DialogContent = withStyles(() => ({
    root: {
        padding: 24,
    },
}))(MuiDialogContent);


const DialogActions = withStyles(() => ({
    root: {
        margin: 0,
        padding: 24,
    },
}))(MuiDialogActions);



const DeletePaymentSourceModal = ({ open, handleClose, setOpen, handleConfirmDelete, selectedItem }) => {
    const classes = useStyles();
    const { brand = '', last4 = '', exp_month = '', exp_year = '' } = selectedItem?.jsonData?.card;
    const cardId = selectedItem?.jsonData?.id;

    const handleDelete = () => {
        handleConfirmDelete(cardId);
        setOpen(false);
    };

    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="Delete-payment-source-title" open={open} maxWidth='sm' fullWidth={false} >
                <DialogTitle id="Delete-payment-source-title" onClose={handleClose}>
                    Are you sure?
        </DialogTitle>
                <DialogContent>
                    <Typography gutterBottom>
                        You are about to permanently delete the following payment method:
                    </Typography>
                    <br />
                    <Box className={classes.cardDetailsWrapper}>
                        <img src={visaLogo} />
                        <Box className={classes.cardDetails}>
                            <p><span className={classes.cardType}>{brand}</span> ending in {last4}
                                <span className={classes.cardSubText}>(exp: {`${exp_month}/${exp_year}`})</span></p>
                        </Box>
                    </Box>
                    <br />
                    <Typography gutterBottom>
                        This will permanently delete payment information from the APC system.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Box mt={2}>
                        <Button autoFocus onClick={handleDelete} color="primary" variant='contained' >
                            delete payment method
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </div>
    );
};

DeletePaymentSourceModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    handleConfirmDelete: PropTypes.func,
    setOpen: PropTypes.func,
    selectedItemIndex: PropTypes.number,
    selectedItem: PropTypes.obj
};

export default DeletePaymentSourceModal;