const routes = {
  DASHBOARD: '/',
  USER_AGREEMENT: '/user_agreement',
  PENDING_ACCOUNT_LANDING: '/pal',
  UNVERIFIED_ACCOUNT: '/unverified',
  LOGIN: '/login',
  ID_CARD: '/id_card',
  ACCOUNT: '/account',
  SUPPORT: '/support',
  SETTINGS: '/settings',
  CLAIMS: '/claims',
  LIVE_CHAT: '/chat',
  PRIVACY_POLICY: '/privacy_policy',
  ACTIVE_DIRECT: '/active_direct',
  FIND_CARE: 'find-care',
  payment: {
    BASE: '/payment',
    HISTORY: '/payment/history',
    SETTINGS: '/payment/settings'
  },
  medical: {
    BASE: '/medical',
    COVERAGE: '/medical/coverage',
    MEMBERS: '/medical/members'
  },
  pharmacy: {
    BASE: '/pharmacy',
    COVERED_DRUG: '/pharmacy/covered_drug',
    COSTS: '/pharmacy/costs',
    BENEFITS: '/pharmacy/benefits'
  },
  TRU_HEARING: '/truhearing',
  AUTOPAY_SETUP: '/autopay_setup',
  AUTOPAY_CONFIRMATION: '/autopay_confirmation'

};

export default routes;
