import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ChatIcon from '@material-ui/icons/Chat';
import CloseIcon from '@material-ui/icons/Close';
import { useMsal } from '@azure/msal-react';

import {
  Box,
  Button,
  IconButton,
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@material-ui/core';
import routes from '../../../../constants/routes';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 450,
    margin: '0 auto',
    borderRadius: 4
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#ffffff'
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    padding: 20,
    textAlign: 'center'
  },
  title: {
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      display: 'inline-block',
      marginLeft: 10
    }
  },
  actions: {
    padding: '16px 32px'
  }
}));

const ChatDialog = ({ open, onClose, topic, type, accountData}) => {
  const classes = useStyles();
  const history = useHistory();
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const handleRouteToChat = () => {
    onClose();
    history.push({
      pathname: routes.LIVE_CHAT,
      search: `?topic=${topic}&chatType=${type}`,
      state: {
        firstName: account?.idTokenClaims.firstName,
        lastName: account?.idTokenClaims.lastName,
        email: account?.idTokenClaims.primaryEmail,
        memberID:accountData?.memberID,
        phone:accountData?.phone,
        chatType: type
      }
    });
  };

  return (
    <Dialog
      className={classes.root}
      open={open}
      onClose={onClose}
      aria-labelledby="chat dialog"
    >
      <Box className={classes.header}>
        <Typography className={classes.title}>
          <ChatIcon />
          <span>CHAT WITH US</span>
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <Box mt={4} textAlign="center">
          <Typography variant="body2">
            By clicking “I Agree”, you acknowledge and agree to our online chat
            feature (“Chat”)
          </Typography>
          <Box>
            <Link
              href="https://healthcare.ascension.org/chat-terms-of-service?intent_source=nav_footer"
              target="__blank"
            >
              Terms of Service
            </Link>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          autoFocus
          onClick={onClose}
          color="primary"
          variant="outlined"
          fullWidth
        >
          CANCEL
        </Button>
        <Box></Box>
        <Button
          autoFocus
          onClick={handleRouteToChat}
          color="primary"
          variant="contained"
          fullWidth
        >
          I Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ChatDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  topic: PropTypes.string,
  type: PropTypes.string,
  accountData:PropTypes.object

};

export default ChatDialog;
