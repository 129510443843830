import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import backgroundImage from '../../../assets/doctor_with_patient_banner.png';
import { ReactComponent as IdCardIcon } from '../../../assets/id-card-icon.svg';
import routes from '../../../constants/routes';
import * as amplitude from '@amplitude/analytics-browser';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover'
  },
  content: {
    padding: '64px 0px 64px 128px',
    background: 'linear-gradient(90deg, #fff 80%, transparent)',
    maxWidth: '760px',
    [theme.breakpoints.down('sm')]: {
      background: '#fff',
      maxWidth: 'unset',
      padding: '32px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '16px'
    }
  },
  bannerSubText: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '32px'
  },
  button: {
    marginTop: '60px',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    height: '48px'
  },
  hospitalIcon: {
    width: '67px',
    height: '60px'
  }
}));

const MedicalPlanBanner = ({ planTitle, history }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h1">Medical Plan</Typography>
        <div className={classes.bannerSubText}>
          <LocalHospitalIcon
            color="primary"
            className={classes.hospitalIcon}
            style={{ marginRight: '20px' }}
          />
          <div style={{ display: 'inline' }}>
            <Typography variant="h5">{planTitle}</Typography>
            <Typography variant="body2" style={{ color: 'rgba(0,0,0,0.6' }}>
              Ascension Personalized Care
            </Typography>
          </div>
        </div>

        <Button
          variant="contained"
          className={classes.button}
          onClick={() => {
            amplitude.track('medicalbenefits_idcard_viewed', {eventCategory: 'Medical Benefits Pathing'});
            history.push(routes.ID_CARD);
          }}
          startIcon={<IdCardIcon style={{ color: '#B40F87' }} />}
        >
          My ID Card
        </Button>
      </div>
    </div>
  );
};

MedicalPlanBanner.propTypes = {
  planTitle: PropTypes.string,
  history: PropTypes.object.isRequired
};

export default MedicalPlanBanner;
