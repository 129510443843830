import constants from './constants';

export const initialState = {
  claims: [],
  isClaimDetailsOpen: false,
  claimDetails: {},
  dependents: [],
  pagination: {},
  member: {},
  requestParams: {},
  error: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.BEGIN_CLAIMS: {
      return {
          ...state,
          loading: true,
          error: null
      };
  }
    case constants.SET_CLAIMS: {
      const haveParamsChanged =
        JSON.stringify(state.requestParams) !==
        JSON.stringify(payload.requestParams);

      const appendedClaims = [...state.claims, ...payload.claims].reduce(
        (prev, curr) => {
          if (prev.find(i => i.claimNumber === curr.claimNumber)) {
            return prev;
          } else {
            return [...prev, curr];
          }
        },
        []
      );

      return {
        ...state,
        claims: haveParamsChanged ? [...payload.claims] : appendedClaims,
        dependents: [...payload.dependents],
        pagination: { ...payload.pagination },
        member: { ...payload.member },
        requestParams: { ...payload.requestParams },
        loading: false
      };
    }

    case constants.OPEN_CLAIM_DETAILS:
      return {
        ...state,
        isClaimDetailsOpen: true,
        claimDetails: { ...payload }
      };

    case constants.CLOSE_CLAIM_DETAILS:
      return {
        ...state,
        isClaimDetailsOpen: false,
        claimDetails: {}
      };

    case constants.SET_CLAIMS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    default:
      return state;
  }
};
