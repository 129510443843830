import { trackPromise } from 'react-promise-tracker';
import { memberApi } from '../../config/axios-config';
import constants from './constants';

// action creators
export const setMedicalCard = payload => ({
  type: constants.SET_MEDICAL_CARD,
  payload
});

export const setMedicalCardError = payload => ({
  type: constants.SET_MEDICAL_CARD_ERROR,
  payload
});

export const setMedicalCardLoading = payload => ({
  type: constants.SET_MEDICAL_CARD_LOADING,
  payload
});

export const setPharmacyCard = payload => ({
  type: constants.SET_PHARMACY_CARD,
  payload
});

export const setPharmacyCardError = payload => ({
  type: constants.SET_PHARMACY_CARD_ERROR,
  payload
});

export const setPharmacyCardLoading = payload => ({
  type: constants.SET_PHARMACY_CARD_LOADING,
  payload
});

export const fetchMedicalCard = () => dispatch => {
  dispatch(setMedicalCardLoading(true));
  trackPromise(
    memberApi
      .get('v2/my/cards/medical?fileFormat=png&base64-encoded-response=true', {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {}
      })
      .then(res => dispatch(setMedicalCard(res.data)))
      .catch(err => dispatch(setMedicalCardError(err.message)))
      .finally(() => dispatch(setMedicalCardLoading(false)))
  );
};

export const fetchPharmacyCard = () => dispatch => {
  dispatch(setPharmacyCardLoading(true));
  trackPromise(
    memberApi
      .get('v2/my/cards/pharmacy?fileFormat=png&base64-encoded-response=true', {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {}
      })
      .then(res => dispatch(setPharmacyCard(res.data)))
      .catch(err => dispatch(setPharmacyCardError(err.message)))
      .finally(() => dispatch(setPharmacyCardLoading(false)))
  );
};
