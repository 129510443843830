import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Menu, MenuItem } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import routes from '../../constants/routes';
import { analyticsTrackRouteClickEvent } from '../../utils/analytics/analytics-utils';

const useStyles = makeStyles(theme => ({
  menuItemIcon: {
    color: theme.palette.action.active,
    marginRight: '16px'
  }
}));

const options = [
  {
    path: routes.SUPPORT,
    Icon: HelpIcon,
    label: 'Support',
    requiredVerification: true
  },
  {
    path: routes.SETTINGS,
    Icon: SettingsIcon,
    label: 'Account Settings',
    requiredVerification: false
  }
];

const AccountMenu = ({ anchor, onClose, history, logout, isVerified }) => {
  const classes = useStyles();
  const handleClick = path => {
    analyticsTrackRouteClickEvent(path, { eventCategory: 'Home Page Pathing' });
    onClose();
    history.push(path);
  };

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchor}
      keepMounted
      open={Boolean(anchor)}
      onClose={onClose}
      style={{ marginTop: '40px' }}
    >
      {options.map(
        ({ path, Icon, label, requiredVerification }) =>
          (requiredVerification || isVerified) && (
            <MenuItem key={label} onClick={() => handleClick(path)}>
              <Icon className={classes.menuItemIcon} />
              {label}
            </MenuItem>
          )
      )}
      <MenuItem onClick={() => {
        analyticsTrackRouteClickEvent('logout', { eventCategory: 'Home Page Pathing' });
        logout();
      }}>
        <ExitToAppIcon className={classes.menuItemIcon} />
        Logout
      </MenuItem>
    </Menu>
  );
};

AccountMenu.propTypes = {
  anchor: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  onClose: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  isVerified: PropTypes.bool.isRequired
};

export default AccountMenu;
