import React from 'react';
import { Grid, Typography, Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PaymentAmountSection from '../common/PaymentAmountSection';
import PaymentSourceSection from '../common/PaymentSourceSection';
import CommunicationSection from '../common/CommunicationSection';
import { useHistory } from 'react-router';
import { memberApi } from '../../../config/axios-config';
import genericServiceReducer from '../../../redux/common/genericServiceReducer';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';


const useStyles = makeStyles(() => ({
    pageWrapper: {
        backgroundColor: 'rgba(0, 0, 0, 0.03)',
        padding: '90px 60px'
    },
    pageContainer: {
        maxWidth: 1000
    },
    pageTitle: {
        marginTop: '7px', fontFamily: 'HCo Whitney SSm', fontSize: 28, fontWeight: '700'
    },
    pageBackButton: {
        textTransform: 'none', fontSize: '22px'
    },
    saveHelperText: {
        color: 'rgba(0, 0, 0, 0.56)',
        fontStyle: 'italic',
        margin: '20px 20px 40px 20px'
    },
    setupAutopayButton: {
        width: 305,
        height: 48
    }
}));



const AutoPaySetup = () => {
    const classes = useStyles();
    const history = useHistory();
    const [boxOneExpand, setBoxOneExpand] = React.useState(true);
    const [boxOneSaveStatus, setBoxOneSaveStatus] = React.useState(false);
    const [boxTwoExpand, setBoxTwoExpand] = React.useState(false);
    const [boxTwoSaveStatus, setBoxTwoSaveStatus] = React.useState(false);
    const [boxThreeExpand, setBoxThreeExpand] = React.useState(false);
    const [boxThreeSaveStatus, setBoxThreeSaveStatus] = React.useState(false);
    const [activeCard, setActiveCard] = React.useState('');
    const [paymentAmountDue, setPaymentAmountDue] = React.useState('');
    const [communication, setCommunication] = React.useState('');
    const [emailAgreement, setEmailAgreement] = React.useState(false);
    const [activeCardLast4, setActiveCardLast4] = React.useState('');

    React.useEffect(() => {
        (boxOneSaveStatus && !boxTwoSaveStatus) ? setBoxTwoExpand(true) : setBoxTwoExpand(false);
    }, [boxOneSaveStatus]);

    React.useEffect(() => {
        (boxTwoSaveStatus && !boxThreeSaveStatus) ? setBoxThreeExpand(true) : setBoxThreeExpand(false);
    }, [boxTwoSaveStatus]);

    React.useEffect(() => {
        if (communication == 'yes') {
            setEmailAgreement(true);
        } else if (communication == 'no') {
            setEmailAgreement(false);
        }
    }, [communication]);

    const [autopaySubmitReducerState, autopaySubmitReducerDispatch] = React.useReducer(genericServiceReducer, {
        status: 'idle',
        data: null,
        error: null
    });

    const autopaySubmitIsLoading = autopaySubmitReducerState?.status === 'pending';
    const autopaySubmitError = autopaySubmitReducerState?.error;

    const handleSubmit = () => {

        const paymentMethodID = activeCard;
        const paymentAmount = paymentAmountDue;
        const last4 = activeCardLast4;
        const isCustomerAcceptsAutopayTermsAndConditions = history?.location?.state?.isCustomerAcceptsAutopayTermsAndConditions;
        const responseData = {
            pathname: '/autopay_confirmation',
            state: {
                'paymentAmount': paymentAmount,
                'emailOpt': communication,
                'last4': last4
            }
        };
        autopaySubmitReducerDispatch({ type: 'started' });

        memberApi
            .post('/v3/my/billing/auto-payment', {

                'stripePaymentMethodId': paymentMethodID,
                'isCustomerAcceptsAutopayTermsAndConditions': isCustomerAcceptsAutopayTermsAndConditions,
                'agreeReceiveNotification': emailAgreement

            })
            .then(res => {
                autopaySubmitReducerDispatch({ type: 'success', data: res?.data });
                history.push(responseData);
            })
            .catch(err => autopaySubmitReducerDispatch({ type: 'error', error: err }));

    };

    return (
        <Box className={classes.pageWrapper}>

            <Box className={classes.pageContainer}>
                <Grid container>
                    <Grid item md={4} sm={12} xs={12}>
                        <Button className={classes.pageBackButton} startIcon={<ChevronLeftIcon />} onClick={() => history.push('/payment')}>
                            Back to Payments
                        </Button>
                    </Grid>
                    <Grid item md={8} sm={12} xs={12}>

                        {autopaySubmitError && <Box mb={5}>
                            <Alert severity="error">
                                Something went wrong. Autopay setup failed. Please try again.
                            </Alert>
                        </Box>}
                        <Typography variant="h5" className={classes.pageTitle}>Setup Autopay</Typography>

                        <Box mt={4}>
                            <PaymentAmountSection setPaymentAmountDue={setPaymentAmountDue} expand={boxOneExpand} setExpand={setBoxOneExpand} setSaveStatus={setBoxOneSaveStatus} saveStatus={boxOneSaveStatus} />
                        </Box>

                        <Box mt={1}>
                            <PaymentSourceSection setActiveCardLast4={setActiveCardLast4} setActiveCard={setActiveCard} expand={boxTwoExpand} setExpand={setBoxTwoExpand} setSaveStatus={setBoxTwoSaveStatus} saveStatus={boxTwoSaveStatus} setBoxThreeExpand={setBoxThreeExpand} />
                        </Box>

                        <Box mt={1}>
                            <CommunicationSection setCommunication={setCommunication} expand={boxThreeExpand} setExpand={setBoxThreeExpand} setSaveStatus={setBoxThreeSaveStatus} saveStatus={boxThreeSaveStatus} />
                        </Box>

                        <Box>
                            <p className={classes.saveHelperText}>
                                Payment is considered on time if initiated by due date, regardless of processing time.
                            </p>
                        </Box>
                        <Box display='flex' justifyContent='flex-end'>
                            <Button variant="contained" color="primary" onClick={handleSubmit} className={classes.setupAutopayButton} disabled={autopaySubmitIsLoading}>


                                {/* <Button variant="contained" color="primary" onClick={handleSubmit} className={classes.setupAutopayButton} disabled={!(boxOneSaveStatus && boxTwoSaveStatus && boxThreeSaveStatus)}> */}
                                <span>FINALIZE AUTOPAY SETUP</span>
                                {autopaySubmitIsLoading && (
                                    <CircularProgress
                                        size="16px"
                                        style={{ marginLeft: '10px' }}
                                    />
                                )}
                            </Button>
                        </Box>

                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default AutoPaySetup;









