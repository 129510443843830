import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Typography,
  List,
  Card,
  Divider,
  ListSubheader,
  makeStyles,
  Box,
  CircularProgress
} from '@material-ui/core';
import ClaimsMessage from '../cards/ClaimsMessage';
import ClaimDetails from '../cards/ClaimDetails';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto'
  },
  listSection: {
    backgroundColor: 'inherit'
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0
  },
  subHeader: {
    backgroundColor: '#F5F5F5',
    color: 'black',
    padding: '8px 24px'
  },
  card: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    height: '52px'
  },
  pagination: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginTop: '14px',
    width: '100%',
    textTransform: 'none'
  }
}));

const ClaimsList = ({

  isPolicyHolder,
  isAdult,
  isThisDependentLoggedIn,
  dependentCode,
  claimsData,
  loading,
  claimsSorted,
  pagination,
  pageSize,
  pageNumber,
  displayClaimNumber,
  setPageNumber,

}) => {
  const classes = useStyles();

  if (loading) {
    return (
      <Box mt={2}>
        <Box mb={2}>Loading...</Box>
        <CircularProgress style={{ color: '#c7c7c7' }} />
      </Box>
    );
  }

  const rawDates = claimsSorted.map(claim => (
    moment(claim.serviceDateTo, 'YYYY-MM-DD').format('YYYY')
  )); 

  const sectionIDs = rawDates.filter((year, index) => (
    rawDates.indexOf(year) == index
  ));

  return ((!isPolicyHolder && !isThisDependentLoggedIn && dependentCode != '') || (isPolicyHolder && isAdult && (dependentCode != '' && dependentCode != 0))) ? (
    <ClaimsMessage
      title="You do not have access"
      message="This member has not given you permission to view their claims
        information."
    />
  ) : claimsData.length === 0 ? (
    <ClaimsMessage
      title="No claims available"
      message="Your claims may not be ready yet or are currently being processed."
    />
  ) : (
    <>
      <Grid item xs={12}>
        <List className={classes.root} subheader={<li />}>
         {sectionIDs.map((sectionID, index) => (
            <li key={index} className={classes.listSection}>
              <ul className={classes.ul}>
                <ListSubheader className={classes.subHeader}>
                  <Typography variant="h5">{sectionID}</Typography>
                </ListSubheader>
                {claimsSorted
                .filter(claim => (
                  moment(claim.serviceDateTo, 'YYYY-MM-DD').format('YYYY') == sectionID))
                .map(claim => (
                  <div key={claim.claimNumber}>
                    <ClaimDetails claim={claim} />
                    <Divider variant="inset" />
                  </div>
                ))}
              </ul>
            </li>
          ))}
        </List>
      </Grid>

      <Grid item xs={12}>
        {pagination.totalRecords > pageSize * pageNumber &&
          displayClaimNumber === '' && (
            <Card className={classes.card} style={{ marginTop: '24px' }}>
              <Button
                className={classes.pagination}
                onClick={() => setPageNumber(pageNumber + 1)}
              >
                Show more claims
              </Button>
            </Card>
          )}
      </Grid>
    </>
  );
};

ClaimsList.propTypes = {
  isEligible: PropTypes.bool.isRequired,
  isPolicyHolder: PropTypes.bool.isRequired,
  isAdult: PropTypes.bool.isRequired,
  isThisDependentLoggedIn: PropTypes.bool.isRequired,
  dependentCode: PropTypes.string,
  claimsData: PropTypes.array,
  claimsSorted: PropTypes.array,
  pagination: PropTypes.object,
  pageSize: PropTypes.number,
  pageNumber: PropTypes.number,
  displayClaimNumber: PropTypes.string,
  setPageNumber: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default ClaimsList;
