import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { fetchClaims } from '../../redux/claims/actions';
import { fetchAccountData } from '../../redux/settings/actions';
import BasePage from '../BasePage.js';
import MemberList from './MemberList/MemberList.js';
import ClaimsListHeader from './ClaimsListHeader/ClaimsListHeader.js';
import ClaimsList from './ClaimsList/ClaimsList.js';

const ClaimsPage = () => {
  const dispatch = useDispatch();
  const pageSize = 10;

  const { isEligible } = useSelector(state => state.settings.accountData);
  const { dependents, pagination, member, claims, loading } = useSelector(
    store => store.claims
  );
  const  isPolicyHolder  = member.isPrimaryLoggedIn;

  const [selectedMember, setSelectedMember] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [dependentCode, setDependentCode] = useState('');
  const [claimNumber, setClaimNumber] = useState('');
  const [displayClaimNumber, setDisplayClaimNumber] = useState('');
  const [requestParams, setRequestParams] = useState({
    orderByDate: 'desc'
  });
  const [isAdult, setIsAdult] = useState(true);
  const [isThisDependentLoggedIn, setIsThisDependentLoggedIn] = useState(false);

  const claimsSorted = claims;

  useEffect(() => {
    dispatch(fetchAccountData());
    dispatch(fetchClaims(requestParams, pageNumber, pageSize));
  }, [pageNumber, requestParams]);

  const handleRequestParams = newState =>
    setRequestParams(prev => ({
      ...prev,
      ...newState
    }));

  const handleDependentCode = (value, firstName, isDependentOverEighteen, isThisDependentLoggedIn) => {
    
    setDependentCode(value);
    setSelectedMember(firstName);
    handleRequestParams({ relationCode: value, claimNumber });
    setIsAdult(isDependentOverEighteen);
    setIsThisDependentLoggedIn(isThisDependentLoggedIn);
    setPageNumber(1);
  };

  const handleFilter = (searchByClaimNumber, orderByDate) => {
    handleRequestParams({ claimNumber: searchByClaimNumber, orderByDate });
    setDisplayClaimNumber(searchByClaimNumber);
    setPageNumber(1);
  };

  const removeClaimNumberFilter = () => {
    setDisplayClaimNumber('');
    setClaimNumber('');
    handleRequestParams({ claimNumber: null });
  };

  return (
    <BasePage>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h1">Claims</Typography>
        </Grid>

        <Grid item xs={12}>
          <MemberList
            handleDependentCode={handleDependentCode}
            dependentCode={dependentCode}
            member={member}
            dependents={dependents}
          />
        </Grid>

        <ClaimsListHeader
          displayClaimNumber={displayClaimNumber}
          claimsData={claims}
          dependentCode={dependentCode}
          selectedMember={selectedMember}
          removeClaimNumberFilter={removeClaimNumberFilter}
          handleFilter={handleFilter}
        />

        <ClaimsList
          isEligible={isEligible}
          isPolicyHolder = {isPolicyHolder}
          isAdult={isAdult}
          dependentCode={dependentCode}
          isThisDependentLoggedIn = {isThisDependentLoggedIn}
          claimsData={claims}
          loading={loading}
          claimsSorted={claimsSorted}
          pagination={pagination}
          pageSize={pageSize}
          pageNumber={pageNumber}
          displayClaimNumber={displayClaimNumber}
          setPageNumber={setPageNumber}
        />

        <Grid item xs={12} style={{ margin: '24px 0px' }}>
          {claims.length > 0 && (
            <Typography color="textSecondary" variant="body2">
              <b>Disclaimer: </b>
              These are not bills. The purpose of these details is to shed light
              on how your medical plan processed this healthcare claim.
            </Typography>
          )}
        </Grid>
      </Grid>
    </BasePage>
  );
};

export default ClaimsPage;
