import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChatIcon from '@material-ui/icons/Chat';
import PhoneIcon from '@material-ui/icons/Phone';
import { getEnvVars } from '../../utils/env-utils';


const { chatFeatureFlag } = getEnvVars();

const useStyles = makeStyles(theme => ({
  supportItem: {
    padding: '24px',
    borderBottom: '1px solid #c7c7c7',
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  phoneBox: {
    marginBottom: 4,
    color: theme.palette.text.secondary,
    fontSize: 14,
    '& a': {
      color: theme.palette.text.secondary,
      fontWeight: 700,
      textDecoration: 'none'
    }
  },
  itemActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  }
}));

const SupportItem = ({
  title,
  phoneNumber,
  isDesktop,
  onOpenCallDialog,
  onOpenChatDialog
}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.supportItem}>
      <Grid item sm={4} style={{ marginBottom: '16px' }}>
        <Typography variant="h3" component="h2">
          {title}
        </Typography>
        {isDesktop && (
          <div className={classes.phoneBox}>
            <label>You can call us at</label>{' '}
            <a href={`tel:+ ${phoneNumber}`}>{phoneNumber}</a>
          </div>
        )}
      </Grid>
      <Grid
        container
        item
        sm={8}
        justify="flex-end"
        alignItems="center"
        spacing={2}
      >
        {!isDesktop && (
          <Grid item>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<PhoneIcon />}
              onClick={onOpenCallDialog}
            >
              {phoneNumber}
            </Button>
          </Grid>
        )}
        {chatFeatureFlag && <Grid item>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<ChatIcon />}
            onClick={onOpenChatDialog}
          >
            LIVE CHAT
          </Button>
        </Grid>}
      </Grid>
    </Grid>
  );
};

SupportItem.propTypes = {
  title: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  chatType: PropTypes.string.isRequired,
  onOpenCallDialog: PropTypes.func.isRequired,
  onOpenChatDialog: PropTypes.func.isRequired
};

export default SupportItem;
