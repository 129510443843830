const genericServiceReducer = (state, action) => {
    switch (action.type) {
        case 'error': {
            return {
                ...state,
                status: 'rejected',
                error: action.error
            };
        }
        case 'success': {
            return {
                ...state,
                status: 'resolved',
                data: action.data,
                error: null
            };
        }
        case 'started': {
            return {
                ...state,
                status: 'pending',
                error: null
            };
        }
        default: {
            console.log(`Invalid action type: ${action.type}`);
        }
    }
};

export default genericServiceReducer;