import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Hidden
} from '@material-ui/core';
import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy';
import backgroundImage from '../../../assets/pharmacy_banner.png';
import backgroundImage_sm from '../../../assets/pharmacy_banner_sm.png';
import { ReactComponent as IdCardIcon } from '../../../assets/id-card-icon.svg';
import routes from '../../../constants/routes';
import * as amplitude from '@amplitude/analytics-browser';
import { getEnvVars } from '../../../utils/env-utils';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
      backgroundImage: `url(${backgroundImage_sm})`
    }
  },
  content: {
    padding: '64px 0px 64px 128px',
    // background: 'linear-gradient(90deg, #fff 80%, transparent)',
    maxWidth: '760px',
    [theme.breakpoints.down('sm')]: {
      padding: '32px'
    },
    [theme.breakpoints.down('xs')]: {
      background: '#fff',
      maxWidth: 'unset',
      padding: '16px'
    }
  },
  bannerSubText: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '32px'
  },
  button: {
    marginTop: '60px',
    marginRight: '16px',
    height: '48px'
  },
  pharmacyIcon: {
    width: '67px',
    height: '60px'
  }
}));

const PharmacyPlanBanner = ({ planTitle }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();
  const { pharmacyBenefitsFeatureFlag } = getEnvVars();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h1">Pharmacy Plan</Typography>
        <div className={classes.bannerSubText}>
          <LocalPharmacyIcon
            color="primary"
            className={classes.pharmacyIcon}
            style={{ marginRight: '20px' }}
          />
          <div style={{ display: 'inline' }}>
            <Typography variant="h5">{planTitle}</Typography>
            <Typography variant="body2" style={{ color: 'rgba(0,0,0,0.6' }}>
            {pharmacyBenefitsFeatureFlag? 'MaxorPlus' : 'Cigna' }
            </Typography>
          </div>
        </div>
        <Hidden xlDown>
          <Button
            variant="contained"
            className={classes.button}
            startIcon={<LocalPharmacyIcon color="secondary" />}
          >
            {isMobile ? 'Pharmacies' : 'Find a Pharmacy'}
          </Button>
        </Hidden>
        <Button
          variant="contained"
          className={classes.button}
          onClick={() => {
            amplitude.track('rxbenefits_idcard_viewed', {eventCategory: 'Pharamcy Benefits Pathing'});
            history.push(routes.ID_CARD);
          }}
          startIcon={<IdCardIcon style={{ color: '#B40F87' }} />}
        >
          My ID Card
        </Button>
      </div>
    </div>
  );
};

PharmacyPlanBanner.propTypes = {
  planTitle: PropTypes.string
};

export default PharmacyPlanBanner;
